.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.submittal-card {
  display: flex;
  flex-direction: column;
}
.submittal-card .submittal-question-title,
.submittal-card .submittal-response-title {
  margin-bottom: 16px;
  padding: 9px 0px;
}
.submittal-card .submittal-question-title .details-title-text,
.submittal-card .submittal-response-title .details-title-text {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
}
.submittal-card .submittal-question-title.official-response .details-title-text,
.submittal-card .submittal-response-title.official-response .details-title-text {
  color: #fb6120;
}
.submittal-card.dark-mode .submittal-response-title.official-response .details-title-text {
  color: #02c7ff;
}
