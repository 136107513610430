.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.closeout-contrib-form {
  margin-top: 24px;
  padding: 24px 40px;
  background-color: #f7f7f8;
}
.closeout-contrib-form .closeout-contrib-form-header {
  align-items: center;
  cursor: pointer;
  display: flex;
  color: #454a5c;
}
.closeout-contrib-form .closeout-contrib-form-header .closeout-contrib-form-toggle {
  margin-left: 10px;
  font-size: 16px;
  color: #00b091;
}
.closeout-contrib-form .closeout-contrib-form-header h4 {
  font-size: 23px;
  line-height: 38px;
  margin: 0px;
}
.closeout-contrib-form .closeout-contrib-form-container {
  margin-top: 24px;
}
.closeout-contrib-form .closeout-contrib-form-container .chars-counter-textarea {
  width: 100%;
}
.closeout-contrib-form .closeout-contrib-form-container .closeout-contrib-form-category {
  display: flex;
  flex-direction: column;
  margin-top: 48px;
}
.closeout-contrib-form .closeout-contrib-form-container .closeout-contrib-form-category:first-child {
  margin-top: 24px;
}
.closeout-contrib-form .closeout-contrib-form-container .closeout-contrib-form-category .closeout-contrib-form-subtitle {
  margin-bottom: 8px;
  padding: 5px 0px;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #454a5c;
}
.closeout-contrib-form .closeout-contrib-form-container .closeout-contrib-form-category .document-upload {
  height: 160px;
  margin-top: 24px;
}
.closeout-contrib-form .closeout-contrib-form-container .closeout-contrib-form-category .closeout-contrib-form-block-docs {
  display: flex;
  flex-direction: column;
}
.closeout-contrib-form .closeout-contrib-form-container .closeout-contrib-form-category .closeout-contrib-form-block-docs .doc-viewer {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}
.closeout-contrib-form .closeout-contrib-form-container .closeout-contrib-form-category .closeout-contrib-form-block-docs .doc-viewer .document-link {
  width: unset;
}
.closeout-contrib-form .closeout-contrib-form-container .closeout-contrib-form-category .closeout-contrib-form-block-docs .doc-viewer .remove-doc-icon {
  margin-left: 8px;
  cursor: pointer;
}
.closeout-contrib-form .closeout-contrib-form-container .closeout-contrib-form-category .labeled-checkbox {
  margin-top: 16px;
}
.closeout-contrib-form .closeout-contrib-form-container .closeout-contrib-form-category .closeout-contrib-form-label {
  margin: 0px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #454a5c;
}
.closeout-contrib-form.dark-mode {
  background-color: #21242f;
}
.closeout-contrib-form.dark-mode .closeout-contrib-form-header {
  color: #ffffff;
}
.closeout-contrib-form.dark-mode .closeout-contrib-form-header .closeout-contrib-form-toggle {
  color: #d4fb00;
}
.closeout-contrib-form.dark-mode .closeout-contrib-form-category .closeout-contrib-form-subtitle {
  color: #ffffff;
}
.closeout-contrib-form.dark-mode .closeout-contrib-form-category .closeout-contrib-form-label {
  color: #ffffff;
}
