.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.project-report-form-modal-view .modal-dialog {
  width: 80% !important;
}
.project-report-form-modal-view .modal-content {
  height: 700px !important;
}
.project-report-form-modal-view .modal-body {
  height: 100%;
}
.project-report-form-modal-view .text-color {
  color: #454a5c;
}
.project-report-form-modal-view .container-header-title {
  display: flex;
  justify-content: space-between;
  margin: 27px 50px 42px 40px;
}
.project-report-form-modal-view .container-header-title .modal-name {
  font-size: 29px;
  font-weight: 600;
  line-height: 46px;
}
.project-report-form-modal-view .search-input-background {
  background-color: #f7f7f8;
}
.project-report-form-modal-view .container-body-remark {
  display: flex;
  flex-direction: column;
  margin: 0px 40px;
}
.project-report-form-modal-view .container-body-remark .label-section {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.project-report-form-modal-view .container-body-remark .label-section .label {
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
  padding: 0px;
  margin-right: 12px;
}
.project-report-form-modal-view .container-body-remark .label-section .value {
  font-size: 16px;
  font-weight: 400;
  margin-right: 60px;
  opacity: 0.7;
}
.project-report-form-modal-view .container-body-remark .remark-section {
  display: flex;
  flex-direction: column;
  margin: 0px 7px 30px 7px;
}
.project-report-form-modal-view .container-body-remark .remark-section .label-remark {
  font-size: 12px;
  font-weight: 400;
  color: #00b091;
}
.project-report-form-modal-view .container-body-remark .remark-section .value-remark {
  font-size: 16px;
  font-weight: 400;
}
.project-report-form-modal-view .container-body-project-report-modal-table {
  margin: 0px 40px 20px 40px;
}
.project-report-form-modal-view .container-body-project-report-modal-table .modal-contract-info-table colgroup > col:nth-child(1) {
  width: 10%;
}
.project-report-form-modal-view .container-body-project-report-modal-table .modal-contract-info-table colgroup > col:nth-child(2) {
  width: 20%;
}
.project-report-form-modal-view .container-body-project-report-modal-table .modal-contract-info-table colgroup > col:nth-child(3) {
  width: 12%;
}
.project-report-form-modal-view .container-body-project-report-modal-table .modal-contract-info-table colgroup > col:nth-child(4) {
  width: 13%;
}
.project-report-form-modal-view .container-body-project-report-modal-table .modal-contract-info-table colgroup > col:nth-child(5) {
  width: 12%;
}
.project-report-form-modal-view .container-body-project-report-modal-table .modal-contract-info-table colgroup > col:nth-child(6) {
  width: 12%;
}
.project-report-form-modal-view .container-body-project-report-modal-table .modal-contract-info-table colgroup > col:nth-child(7) {
  width: 12%;
}
.project-report-form-modal-view .container-body-project-report-modal-table .modal-contract-info-table colgroup > col:nth-child(8) {
  width: 9%;
}
.project-report-form-modal-view .container-body-project-report-modal-table .modal-project-status-table colgroup > col:nth-child(1) {
  width: 7%;
}
.project-report-form-modal-view .container-body-project-report-modal-table .modal-project-status-table colgroup > col:nth-child(2) {
  width: 15%;
}
.project-report-form-modal-view .container-body-project-report-modal-table .modal-project-status-table colgroup > col:nth-child(3) {
  width: 46%;
}
.project-report-form-modal-view .container-body-project-report-modal-table .modal-project-status-table colgroup > col:nth-child(4) {
  width: 11%;
}
.project-report-form-modal-view .container-body-project-report-modal-table .modal-project-status-table colgroup > col:nth-child(5) {
  width: 11%;
}
.project-report-form-modal-view .container-body-project-report-modal-table .modal-project-status-table colgroup > col:nth-child(6) {
  width: 10%;
}
.project-report-form-modal-view .container-body-project-report-modal-table tr th:first-child {
  padding-left: 0;
}
.project-report-form-modal-view .container-body-project-report-modal-table tr td:first-child {
  padding-left: 0;
}
.project-report-form-modal-view .modal-no-items {
  text-align: center;
  font-size: 24px;
  margin-bottom: 50px;
}
.project-report-add-item-form-modal-view .modal-dialog {
  width: 632px !important;
}
.project-report-add-item-form-modal-view .input-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 33px 40px 68px 40px;
}
.project-report-add-item-form-modal-view .input-section .category-name {
  width: 360px;
  margin-top: 1px;
}
.project-report-add-item-form-modal-view .input-section .column_qty {
  width: 168px;
}
.project-report-add-item-form-modal-view .buttons-section {
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
}
.project-report-add-item-form-modal-view .input-section-add-item {
  margin: 10px 40px 15px 40px;
}
.project-report-add-item-form-modal-view .add-item-button {
  margin: 0px 40px 25px 40px;
  width: 100px;
}
.project-report-filter-modal-view .modal-dialog {
  width: 583px !important;
}
.project-report-filter-modal-view .modal-body {
  overflow: visible !important;
}
.project-report-filter-modal-view .date-picker-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 70px;
}
.project-report-filter-modal-view .separate-block {
  width: 70px;
}
.project-report-filter-modal-view .button-confirm {
  width: 153px;
  margin: 0px auto;
  margin-bottom: 40px;
}
.dark-mode .text-color {
  color: #ffffff;
}
.dark-mode .label-remark {
  color: #d4fb00;
}
.dark-mode .search-input-background {
  background-color: #21242f;
}
