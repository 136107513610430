.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.input {
  letter-spacing: -0.3px;
}
.input[type='number'] {
  -moz-appearance: textfield;
  margin: 0;
}
.input[type='number']::-webkit-inner-spin-button,
.input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input::placeholder {
  color: #3c4249;
  opacity: 0.5;
}
.input.invalid {
  border-color: #fb6120 !important;
}
.input.invalid::placeholder {
  color: #fb6120 !important;
  opacity: 1;
}
.input.invalid.dark-mode {
  border-color: #02c7ff !important;
}
.input.invalid.dark-mode::placeholder {
  color: #02c7ff !important;
}
