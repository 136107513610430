.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.view-security-camera-modal .modal-dialog {
  width: 95vw;
  max-height: 95vh;
}
.view-security-camera-modal .modal-body {
  padding: 0px 0px 12px 0px !important;
  max-height: calc(90vh - 100px) !important;
}
.view-security-camera-modal .security-camera .security-camera-metadata {
  display: none;
}
