.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.projects .search-bar {
  margin-left: 24px;
}
.projects .search-bar .status-selector {
  margin-right: 24px;
}
.projects .content-container .pagination-container {
  margin-top: 16px;
  text-align: center;
}
.projects .zero-state {
  background-color: #ffffff;
  display: table;
  height: 527px;
  text-align: center;
  width: 100%;
}
.projects .zero-state .zero-state-content {
  display: table-cell;
  vertical-align: middle;
}
.projects .zero-state .zero-state-content img {
  margin-bottom: 48px;
}
.projects .zero-state .zero-state-content .button {
  margin-top: 45px;
}
.projects .zero-state .zero-state-content .zero-state-title {
  font-size: 34px;
}
.projects .zero-state .zero-state-content .zero-state-subtitle {
  font-size: 24px;
}
.projects .action-buttons-container {
  display: flex;
  align-items: center;
}
.projects .action-buttons-container button {
  margin-left: 12px;
}
.projects .action-buttons-container button:first-child {
  margin-left: 0;
}
.projects.dark-mode .zero-state {
  background-color: #191b24;
  color: #ffffff;
}
.status-selector-menu .MuiMenuItem-root .substatus-option {
  padding-left: 32px;
}
.menu-popover .popover-children.status-selector {
  margin-right: 0px;
}
.menu-popover .popover-children.status-selector .menu-item.substatus-option {
  padding-left: 32px;
}
