.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.add-invitation .add-invitation-permissions-row {
  margin-top: 5px;
}
.add-invitation .header {
  color: #3c4249;
  font-size: 19px;
  font-weight: 300;
}
.add-invitation form {
  margin-top: 10px;
}
.add-invitation .permissions-table {
  margin-top: 10px;
}
.title-2 {
  font-size: 24px;
  opacity: 0.7;
  color: #fff;
  margin-top: 20px;
}
