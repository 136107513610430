.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.action-item-table {
  margin-bottom: 0;
  width: 100%;
  border-width: 0px;
  overflow-x: scroll;
}
.action-item-table th {
  padding-top: 13px;
  padding-bottom: 13px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #9ea0aa;
  min-width: 18%;
}
.action-item-table th:first-child {
  padding-left: 0;
  min-width: 120px !important;
  max-width: 150px !important;
}
.action-item-table th:nth-child(2) {
  min-width: 620px;
  max-width: 630px;
}
.action-item-table th:nth-child(3) {
  min-width: 200px;
  max-width: 250px;
}
.action-item-table th:nth-child(4) {
  min-width: 200px;
  max-width: 250px;
}
.action-item-table th:nth-child(5) {
  min-width: 200px;
  max-width: 250px;
}
.action-item-table th:nth-child(6) {
  padding-left: 0 !important;
  width: 110px !important;
}
.action-item-table th:last-child {
  padding-left: 0px;
  min-width: 130px;
}
.action-item-table tbody tr {
  border-width: 0px;
  width: 100%;
}
.action-item-table tbody tr.selectable {
  cursor: pointer;
}
.action-item-table tbody tr:first-child td {
  border-top: none;
}
.action-item-table tbody tr:not(:first-child) td {
  border-top: 0px solid #d2d5d9;
}
.action-item-table tbody tr.last-row:last-child td {
  border-bottom: 0px solid #d2d5d9;
}
.action-item-table tbody tr.link-tab td > a {
  color: inherit;
  display: block;
  text-decoration: none;
}
.action-item-table tbody tr td {
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #454a5c;
  padding-right: 25px;
  min-width: 18%;
  border-bottom: 1px solid #9ea0aa;
  vertical-align: top;
}
.action-item-table tbody tr td:first-child {
  padding-left: 0px;
  vertical-align: middle;
  min-width: 120px !important;
  max-width: 150px !important;
}
.action-item-table tbody tr td:nth-child(2) {
  min-width: 620px;
  max-width: 630px;
}
.action-item-table tbody tr td:nth-child(3) {
  min-width: 200px;
  max-width: 250px;
}
.action-item-table tbody tr td:nth-child(4) {
  min-width: 200px;
  max-width: 250px;
}
.action-item-table tbody tr td:nth-child(5) {
  min-width: 200px;
  max-width: 250px;
}
.action-item-table tbody tr td:nth-child(6) {
  padding-left: 0 !important;
  width: 110px;
}
.action-item-table tbody tr td:last-child {
  padding-left: 0;
  position: relative;
  flex-direction: column;
  vertical-align: top;
  min-width: 160px;
}
.action-item-table tbody tr td.read {
  color: #9ea0aa;
}
.action-item-table tbody tr td .clickable {
  color: #00b091;
}
.action-item-table tbody tr td .total-row {
  font-weight: bold;
  color: #00b091;
}
.action-item-table tbody tr td .base-status-color.unread {
  background-color: #fb6120;
  margin-left: -16px;
  margin-right: 7px;
}
.action-item-table tbody tr td .action-buttons {
  display: flex;
  align-items: center;
}
.action-item-table tbody tr td .action-buttons > *:not(:last-child) {
  margin-right: 12px;
}
.action-item-table tbody tr td .more-options .menu-image-sector-container .menu-image-sector {
  vertical-align: unset;
}
.action-item-table .td-empty-table {
  text-align: center;
  height: 200px;
  vertical-align: middle;
  vertical-align: auto;
  font-size: 24px;
  letter-spacing: -0.6px;
  color: rgba(69, 74, 92, 0.5);
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 200px !important;
  border: 0px solid black !important;
}
.action-item-table .td-empty-table-second {
  text-align: center;
  height: 200px;
  vertical-align: middle;
  vertical-align: auto;
  font-size: 24px;
  letter-spacing: -0.6px;
  color: rgba(69, 74, 92, 0.5);
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 10px !important;
  border: 0px solid black !important;
}
.action-item-table .search-icon-resize {
  width: 22px;
  height: 22px;
}
.action-item-table .action-items-icons {
  color: #00b091;
}
.action-item-table .dropdown-icon-resolve {
  color: #00b091;
  margin-top: 10px;
}
.action-item-table .dropdown-icon-resolve-small {
  color: #00b091;
  margin-top: 7px;
  transform: scale(0.75);
}
.action-item-table .action-items-icons-small {
  color: #00b091;
  transform: scale(0.75);
}
.action-item-table .search-icon-reposition {
  margin-top: 5px;
}
.action-item-table .action-item-actions-small-container-outer {
  display: flex;
  justify-content: flex-end;
}
.action-item-table .action-item-actions-small-container-inner {
  color: #00b091;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 60px;
}
.action-item-table .action-item-actions-small .MuiIconButton-root {
  color: #00b091;
  transform: scale(0.75);
}
.action-item-table.dark-mode .action-item-table {
  background-color: #21242f !important;
}
.action-item-table.dark-mode .action-item-table .action-items-container {
  background-color: #21242f !important;
}
.action-item-table.dark-mode .action-item-table .action-items-container tbody tr {
  background-color: #21242f !important;
}
.action-item-table.dark-mode .action-item-table .action-items-container tbody tr td {
  background-color: #21242f !important;
}
.action-item-table.dark-mode .dropdown-icon-resolve {
  color: #d4fb00;
}
.action-item-table.dark-mode .main-row {
  background-color: #21242f;
}
.action-item-table.dark-mode .main-row:nth-child(7) {
  width: 320px !important;
}
.action-item-table.dark-mode .action-item-actions-big-container {
  background-color: #21242f !important;
}
.action-item-table.dark-mode .dropdown-icon-resolve-small {
  color: #d4fb00;
}
.action-item-table.dark-mode .action-items-icons {
  color: #d4fb00;
}
.action-item-table.dark-mode .action-items-icons-small {
  color: #d4fb00;
}
.action-item-table.dark-mode .search-icon-resize {
  width: 22px;
  height: 22px;
}
.action-item-table.dark-mode .action-item-actions-small .MuiIconButton-root {
  color: #d4fb00 !important;
  vertical-align: top;
  transform: scale(0.75);
}
.action-item-table.dark-mode th {
  color: white;
  border-color: rgba(255, 255, 255, 0.05);
}
.action-item-table.dark-mode tbody tr td {
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.05);
}
.action-item-table.dark-mode tbody tr td.read {
  color: rgba(255, 255, 255, 0.5);
}
.action-item-table.dark-mode tbody tr td .clickable {
  color: #d4fb00;
}
.action-item-table.dark-mode tbody tr td .total-row {
  color: #d4fb00;
}
.action-item-table.dark-mode tbody tr td .base-status-color.unread {
  background-color: #02c7ff;
}
.action-item-table.dark-mode tbody tr.link-tab td > a {
  color: #ffffff;
}
.action-item-table.dark-mode tbody tr.link-tab td.read > a {
  color: rgba(255, 255, 255, 0.5);
}
.action-item-table.dark-mode .td-empty-table {
  color: rgba(255, 255, 255, 0.5);
}
.action-item-text-area {
  border-bottom: none;
  height: auto !important;
  padding: 2px 0 3px;
}
.action-item-text-area:not(:read-only):hover {
  background-size: 13px;
  background-position: right 0 top 8px;
  background-repeat: no-repeat;
  background-image: url('../../../../../images/editable-pencil.svg');
}
.responsible-dropdown .MuiInputBase-root {
  height: auto;
}
.action-item-datetime-picker .MuiInputBase-root {
  height: auto;
}
.action-item-datetime-picker .date-picker-remove {
  top: 12px;
}
.action-item-datetime-picker .icon-image {
  opacity: 0;
  top: 0;
}
.action-item-datetime-picker:hover .icon-image {
  opacity: 1;
}
.action-item-datetime-picker.readonly-input {
  padding-left: 0;
  padding-right: 0;
}
.responsible-dropdown .MuiInputBase-root {
  height: auto;
}
.status-dropdown .MuiInputBase-root {
  height: auto;
  max-width: 300px;
}
.drag-drop-icon-action-items-table {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.action-item-actions-big {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 5px;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 0;
}
.action-item-actions-big-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 5px;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 0;
}
.action-item-actions-small {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 0;
  max-width: 80px;
  vertical-align: top;
  margin-top: 5px;
}
.update-description-area-description {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  line-height: 18px;
  margin-top: 5px;
  min-width: 400px;
  flex: 1;
  justify-content: flex-end;
}
.nested-row {
  height: 30px;
  border: 0px solid black;
}
.nested-row td {
  border: 0px solid black !important;
}
.main-row {
  border: 0px solid black !important;
}
.img-style {
  margin-left: -4px;
}
.nested-update-description-area-description {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  line-height: 18px;
  margin-top: 5px;
  min-width: 420px;
  flex: 1;
  justify-content: flex-end;
  border: 1px solid green;
  margin-left: 10px;
  padding-left: 43px;
}
.nested-update-creation-date {
  margin-top: 7px;
  text-align: right;
  z-index: 100;
  padding-right: 4px;
}
.update-description-area {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  line-height: 18px;
  margin-top: 5px;
}
.smaller-description-field {
  font-size: 12px !important;
  padding-bottom: 40px;
  /* Reduce the padding at the bottom */
  line-height: 1.2;
  /* Adjust the line height for tighter text */
  width: 500px;
}
.update-creation-date {
  margin-top: 8px;
  margin-right: 5px;
}
.category-title-and-buttons-container {
  display: flex;
  flex-direction: horizontal;
  justify-content: space-between;
}
.category-title-buttons {
  display: flex;
  flex-direction: horizontal;
  gap: 40px;
}
.add-action-item-button-container {
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}
.action-item-actions-big-delete-button {
  width: 25px;
}
