.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.project-directory .project-directory-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
.project-directory .project-directory-group .project-directory-group-header {
  margin-top: 24px;
  padding-left: 40px;
}
.project-directory .project-directory-group .project-directory-group-header .project-directory-group-title {
  margin: 0px;
  font-size: 23px;
  line-height: 38px;
  color: #454a5c;
}
.project-directory .project-directory-group .project-directory-contributors {
  display: flex;
  flex-direction: column;
}
.project-directory .project-directory-group .project-directory-contributors .project-directory-contributor {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}
.project-directory .project-directory-group .project-directory-contributors .project-directory-contributor .project-directory-contributor-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0px 40px;
  background-color: #303441;
}
.project-directory .project-directory-group .project-directory-contributors .project-directory-contributor .project-directory-contributor-header .project-directory-contributor-title {
  margin: 0px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.5);
}
.project-directory .project-directory-group .project-directory-contributors .project-directory-contributor .project-directory-contributor-header .labeled-checkbox {
  margin-left: auto;
}
.project-directory .project-directory-group .project-directory-contributors .project-directory-contributor .project-directory-contributor-header .labeled-checkbox .checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}
.project-directory .project-directory-group .project-directory-contributors .project-directory-contributor .project-directory-contributor-header .menu {
  margin-left: 12px;
}
.project-directory .project-directory-group .project-directory-contributors .project-directory-contributor .project-directory-contributor-table {
  margin-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
}
.project-directory .project-directory-group .project-directory-contributors .project-directory-contributor .project-directory-contributor-table colgroup > col:nth-child(1) {
  width: 25%;
}
.project-directory .project-directory-group .project-directory-contributors .project-directory-contributor .project-directory-contributor-table colgroup > col:nth-child(2) {
  width: 20%;
}
.project-directory .project-directory-group .project-directory-contributors .project-directory-contributor .project-directory-contributor-table colgroup > col:nth-child(3) {
  width: 20%;
}
.project-directory .project-directory-group .project-directory-contributors .project-directory-contributor .project-directory-contributor-table colgroup > col:nth-child(4) {
  width: 15%;
}
.project-directory .project-directory-group .project-directory-contributors .project-directory-contributor .project-directory-contributor-table colgroup > col:nth-child(5) {
  width: 15%;
}
.project-directory .project-directory-group .project-directory-contributors .project-directory-contributor .project-directory-contributor-table colgroup > col:nth-child(6) {
  width: 5%;
}
.project-directory .inactive-user-icon {
  padding: 0px 8px;
  color: #fb6120;
}
.project-directory.dark-mode .project-directory-group .project-directory-group-header .project-directory-group-title {
  color: #ffffff;
}
.project-directory.dark-mode .inactive-user-icon {
  color: #02c7ff;
}
