.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.stamp-details {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  color: #454a5c;
  font-size: 12px;
  font-weight: normal;
  margin-top: 11px;
  max-width: 430px;
}
.stamp-details .stamp-details-block {
  padding: 16px;
}
.stamp-details .stamp-details-header {
  align-items: center;
  display: flex;
  margin: 0;
  padding: 8px 16px;
  border-bottom: 1px solid #7a7e8b;
}
.stamp-details .stamp-details-header .preview-container div {
  border: none;
  height: 32px;
  max-width: 140px;
}
.stamp-details .stamp-details-header .stamp-details-title {
  font-size: 16px;
  width: 100%;
}
.stamp-details .stamp-details-action {
  font-size: 12px;
  line-height: 1.42857143;
}
.stamp-details .stamp-details-action b {
  font-size: 14px;
}
.stamp-details .stamp-details-action.radio-button .orientation-simple {
  align-items: flex-start;
}
.stamp-details .stamp-details-action.radio-button .orientation-simple > span {
  width: calc(100% - 26px);
}
.stamp-details .stamp-details-action.radio-button .orientation-simple > span > span > b {
  margin-left: -5px;
}
.stamp-details .stamp-details-action.radio-button img {
  height: 15px;
  margin-top: 3px;
  width: auto;
}
.stamp-details .stamp-details-action-error {
  border: solid 1px #fb6120;
}
.stamp-details .stamp-details-legal {
  text-align: justify;
  white-space: pre-wrap;
}
.stamp-details .stamp-details-legal > p {
  margin-bottom: 4px;
}
.stamp-details.horizontal-stamp {
  max-width: unset;
  width: 100%;
}
.stamp-details.horizontal-stamp .stamp-details-header {
  padding: 0px 16px;
  border: none;
}
.stamp-details.horizontal-stamp .stamp-body {
  display: flex;
}
.stamp-details.horizontal-stamp .stamp-details-form {
  width: 300px;
  padding: 16px 4px;
}
.stamp-details.horizontal-stamp .stamp-details-legal {
  width: calc(100% - 300px);
}
.stamp-details.dark-mode {
  background-color: #303441;
  color: #ffffff;
}
.stamp-details.dark-mode .stamp-details-header {
  border-color: #bcbdc1;
}
.stamp-details.dark-mode .stamp-details-action-error {
  border: solid 1px #02c7ff;
}
