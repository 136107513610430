.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.rfi-log-table .rate-title,
.rfi-log-table .rate-items {
  width: 212px;
}
.rfi-log-table .rate-title {
  width: 212px;
  right: unset !important;
  text-transform: capitalize;
}
.rfi-log-table table colgroup > col:nth-child(1) {
  width: 6%;
}
.rfi-log-table table colgroup > col:nth-child(2) {
  width: 9%;
}
.rfi-log-table table colgroup > col:nth-child(3) {
  width: 23%;
}
.rfi-log-table table colgroup > col:nth-child(4) {
  width: 9%;
}
.rfi-log-table table colgroup > col:nth-child(5) {
  width: 9%;
}
.rfi-log-table table colgroup > col:nth-child(6) {
  width: 9%;
}
.rfi-log-table table colgroup > col:nth-child(7) {
  width: 9%;
}
.rfi-log-table table colgroup > col:nth-child(8) {
  width: 9%;
}
.rfi-log-table table colgroup > col:nth-child(9) {
  width: 14%;
}
