.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.new-meeting-project-details {
  height: 100%;
  overflow-y: scroll;
}
.new-meeting-project-details .project-new-meeting {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 230px);
}
.new-meeting-project-details .project-new-meeting .table-container {
  margin: 12px 40px;
  padding: 0;
  flex: 1;
  overflow: auto;
}
.new-meeting-project-details .project-new-meeting .table-container .new-meetings-table-header-container {
  position: sticky;
  z-index: 2;
  top: 0;
}
.navigation-bar {
  margin: 12px 20px;
}
