.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.new-project-location .content-container {
  padding: 24px 40px !important;
}
.new-project-location .details-title {
  margin-bottom: 16px;
  padding: 0px;
}
.new-project-location .details-title:not(:first-child) {
  margin-top: 40px;
}
.new-project-location .form-group {
  margin-bottom: 24px;
}
.new-project-location .logo-upload {
  margin-left: 16px;
}
.new-project-location .contractors-clients-selected-container {
  margin-left: 10px;
  background-color: #ffffff;
}
.new-project-location .contractors-clients-selected-container ul {
  list-style: none;
  margin: 0;
  padding: 16px 24px;
  height: 180px;
  overflow: auto;
  overflow: overlay;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
}
.new-project-location .contractors-clients-selected-container ul li {
  display: flex;
  justify-content: space-between;
}
.new-project-location .contractors-clients-selected-container ul li:not(:first-child) {
  margin-top: 12px;
}
.new-project-location .contractors-clients-selected-container ul li img {
  cursor: pointer;
  height: 16px;
  width: 16px;
}
.new-project-location.dark-mode .contractors-clients-selected-container {
  background-color: #303441;
}
