.report-filter {
  align-items: center;
  display: flex;
  margin-bottom: 16px;
}
.report-filter .check-select-all {
  margin-left: 8px;
  margin-right: 20px;
}
.report-filter .date-picker,
.report-filter .extra-filter {
  display: inline-block;
  margin-right: 15px;
  width: 200px;
}
