.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.documents-table {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  margin: 24px 40px 0px 40px;
  padding: 24px 0px;
}
.documents-table .navigation-bar {
  padding: 0px 40px;
  margin: 0px;
}
.documents-table .documents-table-container {
  padding: 0px 16px;
}
.documents-table .documents-table-container .doc-viewer {
  display: flex;
  align-items: center;
}
.documents-table .documents-table-container .td-empty-table {
  height: auto;
}
.documents-table .document-upload {
  margin-top: 24px;
  width: 470px;
  align-self: center;
}
.documents-table:not(.with-subcategories) .navigation-bar .navigation-item {
  border: none;
}
.documents-table.with-subcategories .navigation-bar {
  margin: auto;
  padding: 0px;
  text-align: center;
  width: 660px;
}
.documents-table.with-subcategories .navigation-bar .navigation-item:first-child {
  left: 80px;
  position: absolute;
}
.documents-table.with-subcategories .navigation-bar .navigation-item:not(:last-child) {
  margin-right: 24px;
  line-height: 32px;
}
.documents-table.with-subcategories .navigation-bar .navigation-item:not(:first-child) {
  font-size: 18px;
}
.documents-table.with-subcategories .navigation-bar .navigation-item:not(:first-child) .navigation-item-total {
  margin-left: 5px;
}
.documents-table.with-subcategories .documents-table-container {
  margin-top: 16px;
}
.documents-table.can-remove-doc th:first-child {
  padding-left: 90px !important;
}
.documents-table.can-remove-doc td:first-child {
  padding-left: 40px !important;
}
.documents-table.can-remove-doc td:first-child .remove-doc-icon {
  margin-right: 36px;
  cursor: pointer;
}
.documents-table.dark-mode {
  background: #303441;
}
