.closeout-manual-log table colgroup > col {
  width: 25%;
}
.closeout-manual-log .closeout-manual-create {
  display: flex;
  justify-content: flex-end;
  margin: 0px 40px;
}
.closeout-manual-log .closeout-manual-table {
  margin: 0px 16px;
}
.closeout-manual-log .processing {
  height: 20px;
}
