.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.meeting-modal {
  color: #454a5c;
}
.meeting-modal .modal-dialog {
  width: 80%;
}
.meeting-modal .modal-header .modal-header-title {
  position: relative;
  display: block !important;
  height: unset !important;
  width: 100%;
  margin-bottom: 16px;
}
.meeting-modal .modal-header .modal-header-title .button {
  position: absolute;
  right: 40px;
  top: 0;
}
.meeting-modal .modal-header .modal-header-title .attendees {
  background: #ffffff;
  filter: drop-shadow(8px 6px 25px rgba(10, 13, 24, 0.08));
  font-weight: normal;
  line-height: normal;
  padding: 16px;
  position: absolute;
  right: 40px;
  width: 340px;
  z-index: 1;
}
.meeting-modal .modal-header .modal-header-title .attendees .attendees-header {
  display: flex;
  align-items: center;
}
.meeting-modal .modal-header .modal-header-title .attendees .attendees-header .attendees-title {
  font-size: 21px;
  font-weight: 600;
}
.meeting-modal .modal-header .modal-header-title .attendees .attendees-header .attendees-sub-title {
  font-size: 13px;
  opacity: 0.7;
  margin-left: 16px;
}
.meeting-modal .modal-header .modal-header-title .attendees .attendees-header .attendees-close-icon {
  margin-left: auto;
}
.meeting-modal .modal-header .modal-header-title .attendees .attendees-header .attendees-close-icon img {
  width: 24px;
}
.meeting-modal .modal-header .modal-header-title .attendees .attendees-selection {
  max-height: 427px;
  overflow: auto;
  overflow: overlay;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
}
.meeting-modal .modal-header .modal-header-title .attendees .labeled-checkbox {
  margin-top: 10px;
  padding-right: 20px;
  width: 100%;
}
.meeting-modal .modal-header .modal-header-title .attendees .labeled-checkbox:first-child {
  margin-top: 0;
}
.meeting-modal .modal-header .modal-header-title .attendees .labeled-checkbox .checkbox-container {
  float: right;
}
.meeting-modal .modal-header .modal-header-title .attendees .labeled-checkbox .checkbox-label {
  float: left;
  padding: 0;
  width: 90%;
}
.meeting-modal .modal-header .modal-header-title .date-picker {
  width: 140px;
}
.meeting-modal .modal-header .modal-header-title .review-date {
  font-size: 16px;
  font-weight: bold;
}
.meeting-modal h5 {
  font-size: 15px;
  font-weight: bold;
  margin-left: 25px;
  margin-top: 25px;
}
.meeting-modal .company-name {
  opacity: 0.7;
}
.meeting-modal .review-attendees {
  margin-left: 40px;
}
.meeting-modal .review-attendees .review-and-send {
  font-size: 21px;
}
.meeting-modal .review-attendees .review-attended {
  font-size: 15px;
  font-weight: bold;
  margin-top: 16px;
}
.meeting-modal .review-attendees .review-attendee {
  margin-top: 8px;
}
.meeting-modal .buttons-container {
  margin-bottom: 24px;
}
.meeting-modal.dark-mode {
  color: #ffffff;
}
.meeting-modal.dark-mode .modal-header .modal-header-title .attendees {
  background-color: #3b4050;
}
.meeting-modal.dark-mode .modal-header .modal-header-title .attendees .title {
  color: #ffffff;
}
