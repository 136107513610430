.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.meetings-instance-form {
  height: calc(100vh - 275px);
}
.meetings-instance-form .button.button-transparent {
  background-color: transparent;
}
.meetings-instance-form .sidebar-form-sidebar {
  height: auto;
}
.meetings-instance-form .sidebar-form-title {
  margin-bottom: 25px;
}
.meetings-instance-form .sidebar-form-body {
  height: auto;
  overflow-x: hidden;
}
.meetings-instance-form .form-block {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 40px !important;
}
.meetings-instance-form .form-block .form-label {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 10px;
  margin-right: 8px;
  color: #454a5c;
}
.meetings-instance-form .form-block small {
  color: #454a5c;
  font-size: 12px;
  opacity: 0.75;
  margin-bottom: 10px;
}
.meetings-instance-form .form-block .select-label .filter-header-value {
  font-size: 14px;
  font-weight: 600;
  color: #454a5c;
}
.meetings-instance-form .form-block .select-label .header-label-icon {
  width: 15px !important;
  height: 9px !important;
  margin-left: 6px;
  margin-top: -2px;
}
.meetings-instance-form .form-block .select-label .menu-item {
  white-space: nowrap;
}
.meetings-instance-form .form-block .select-label .menu-item.selected {
  color: #7a7e8b !important;
  font-weight: 600;
}
.meetings-instance-form .form-block .select-label .menu-item.selected + .menu-item-separator {
  border-bottom: 1px solid #eaeaec !important;
}
.meetings-instance-form .form-block .chars-counter-textarea .chars-counter {
  text-align: left;
}
.meetings-instance-form .form-block .add-button-label {
  display: flex;
  align-items: center;
}
.meetings-instance-form .form-block .add-button-label .add-icon {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}
.meetings-instance-form .form-block .inline-block .chars-counter-textarea .text-area {
  min-height: 41px;
  padding-top: 8px;
  font-size: 16px;
  line-height: 24px;
}
.meetings-instance-form .form-block .load-prev-report {
  margin-top: 0;
}
.meetings-instance-form .form-block .date-picker {
  max-width: 170px;
}
.meetings-instance-form .form-block.small-margin {
  margin-top: 8px;
  margin-bottom: 10px !important;
}
.meetings-instance-form .form-block.location-block {
  margin-bottom: 56px !important;
}
.meetings-instance-form .form-block.location-block .form-label {
  margin-bottom: 16px;
}
.meetings-instance-form .form-block.location-block .map-container {
  height: 290px;
}
.meetings-instance-form .form-block.description-block {
  margin-bottom: 0 !important;
}
.meetings-instance-form .form-block.description-block .description-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.meetings-instance-form .form-block.description-block .description-label label {
  flex: 1;
}
.meetings-instance-form .form-block.description-block .description-label button {
  margin-top: 0;
  margin-bottom: 10px;
}
.meetings-instance-form .form-block.observation-block {
  margin-top: 4px;
  margin-bottom: 10px !important;
}
.meetings-instance-form .form-block.observation-block .input-trade-count,
.meetings-instance-form .form-block.observation-block .input-contract,
.meetings-instance-form .form-block.observation-block .input-schedule,
.meetings-instance-form .form-block.observation-block .input-design,
.meetings-instance-form .form-block.observation-block .input-quality,
.meetings-instance-form .form-block.observation-block .input-safety {
  width: 165px;
  flex-shrink: 0;
}
.meetings-instance-form .form-block .document-upload {
  max-width: 250px;
  margin-bottom: 12px;
}
.meetings-instance-form .form-block .document-upload-previews {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.meetings-instance-form .form-block .document-upload-previews .document-link {
  width: 30%;
  margin-right: 5%;
  margin-top: 10px;
}
.meetings-instance-form .form-block .document-upload-previews .document-link:nth-child(3n) {
  margin-right: 0;
}
.meetings-instance-form .form-block .document-upload-previews .document-link .document-link-label {
  flex: 1;
}
.meetings-instance-form .form-block .previus-container button {
  margin-top: 0px;
}
.meetings-instance-form .form-block .disclaimer-remark .chars-counter-textarea .text-area {
  max-height: 45vh !important;
}
.meetings-instance-form .form-block-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
}
.meetings-instance-form .form-block-container > .form-block {
  margin-right: 60px;
}
.meetings-instance-form .form-block-container > .form-block:last-child {
  margin-right: 0;
}
.meetings-instance-form .form-block-container.trade-block {
  align-items: center;
  justify-content: space-between;
}
.meetings-instance-form .form-block-container.trade-block > :first-child {
  flex: 1;
}
.meetings-instance-form .form-block-container.trade-block button {
  margin-left: 16px;
}
.meetings-instance-form .form-control-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
}
.meetings-instance-form .open-items-meetings-instance th:first-child,
.meetings-instance-form .open-items-meetings-instance td:first-child {
  padding-left: 0;
}
.meetings-instance-form .open-items-meetings-instance th:nth-child(2),
.meetings-instance-form .open-items-meetings-instance td:nth-child(2) {
  width: 250px;
}
.next-meeting-text {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #454a5c;
}
.next-meeting-datetimes-text {
  font-size: 16px;
  line-height: 24px;
  color: #454a5c;
}
.attachment-form-title {
  font-size: 23px;
  line-height: 38px;
  color: #454a5c;
  margin-top: 50px;
}
.attachment-form-subtitle {
  font-size: 18px;
  line-height: 24px;
  color: #454a5c;
  margin-top: 20px;
}
.attachment-form-text {
  color: #454a5c;
}
.tooltip-help-icon {
  margin-top: 15px !important;
  color: #00b091 !important;
}
.dark-mode .tooltip-help-icon {
  color: #d4fb00 !important;
}
.dark-mode .attachment-form-title {
  color: white;
}
.dark-mode .attachment-form-text {
  color: white;
}
.dark-mode .attachment-form-subtitle {
  color: white;
}
.dark-mode .next-meeting-text {
  color: #ffffff;
}
.dark-mode .next-meeting-datetimes-text {
  color: #ffffff;
}
.dark-mode .meetings-instance-form .form-block .form-label {
  color: #ffffff;
}
.dark-mode .meetings-instance-form .form-block small {
  color: #ffffff;
}
.dark-mode .meetings-instance-form .form-block .select-label .filter-header-value {
  color: #ffffff;
}
.dark-mode .meetings-instance-form .form-block .select-label .menu-item.selected {
  color: #ffffff !important;
}
.dark-mode .meetings-instance-form .form-block .select-label .menu-item.selected + .menu-item-separator {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05) !important;
}
.address {
  flex-basis: 43% !important;
}
.location {
  flex-basis: 30% !important;
}
.call-info {
  flex-basis: 20%;
}
.call-link {
  flex-basis: 19.5%;
}
.weblink {
  flex-basis: 43%;
}
.edit-button-container {
  margin-left: 40px !important;
}
.attendance-table-container {
  margin-top: 35px;
  position: relative;
}
.sidebar-form-container {
  position: relative;
}
.top-buttons-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 40px;
  padding-left: 40px;
  top: 0px;
  right: 0;
}
.attachments-checkboxes-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}
.attachments-checkboxes-container .form-control-block.input-condition:first-child {
  margin-right: 200px !important;
}
.attachments-checkboxes-container .form-control-block.input-condition:last-child {
  margin-left: 87px !important;
}
.attachments-schedules-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 122px;
}
.attachments-checkboxes-container-small {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 213px;
}
.attachments-uploaders-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  gap: 200px;
}
.private-meeting-instance {
  display: flex;
  flex-direction: row;
  gap: 120px;
}
.private-meeting-instance:first-child {
  width: 100px !important;
}
