.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.time-picker {
  display: flex;
  min-width: 140px;
}
.time-picker input {
  cursor: pointer !important;
  background: url('./calendar.svg') right 20px center no-repeat;
  background-size: 20px;
}
.time-picker input.clock {
  background: url('./clock.svg') right 20px center no-repeat;
  background-position-x: calc(100% - 10px);
}
.time-picker .rdtPicker {
  width: 170px;
  border: none;
  filter: drop-shadow(8px 6px 25px rgba(10, 13, 24, 0.08));
}
.time-picker .rdtPicker td {
  color: #454a5c;
}
.time-picker .rdtPicker td.rdtDay.rdtActive {
  background-color: transparent;
  position: relative;
  text-shadow: none;
}
.time-picker .rdtPicker td.rdtDay.rdtActive:before {
  background-color: #fb6120;
  border-radius: 50%;
  content: '';
  height: 28px;
  width: 28px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: -1;
}
.time-picker .rdtPicker td.rdtDay.rdtToday:before {
  border-bottom-color: #fb6120;
}
.time-picker .rdtPicker td .rdtBtn:hover {
  background-color: #f7f7f8;
}
.time-picker th {
  border: none;
}
.time-picker thead {
  color: #9fa4a9;
  font-size: 11px;
}
.time-picker.clock input {
  background: url('./clock.svg') right 20px center no-repeat;
}
.time-picker.dark-mode input {
  background: url('./calendarDarkMode.svg') right 20px center no-repeat;
}
.time-picker.dark-mode input.clock {
  background: url('./clockDarkMode.svg') right 20px center no-repeat;
  background-position-x: calc(100% - 10px);
}
.time-picker.dark-mode .rdtPicker {
  background-color: #303441;
  filter: drop-shadow(8px 6px 25px rgba(10, 13, 24, 0.5));
}
.time-picker.dark-mode .rdtPicker td {
  color: #ffffff;
}
.time-picker.dark-mode .rdtPicker td.rdtDay.rdtActive:before {
  background-color: #02c7ff;
}
.time-picker.dark-mode .rdtPicker td.rdtDay.rdtToday:before {
  border-bottom-color: #02c7ff;
}
.time-picker.dark-mode .rdtPicker td .rdtBtn:hover {
  background-color: #363a46;
}
