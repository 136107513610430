.attendance-form {
  margin-bottom: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.attendance-form .attendance-parent-table thead tr th :first-child {
  padding-left: 0 !important;
}
.attendance-form .attendance-parent-table tbody tr td {
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  width: 200px !important;
  vertical-align: bottom !important;
}
.attendance-form .attendance-parent-table tbody tr td:first-child {
  max-width: 40px !important;
  width: 40px !important;
  padding-left: 0;
  margin-top: 10px;
  padding-left: 24px;
}
.attendance-form .attendance-filters {
  margin-bottom: 16px;
}
.attendance-form .attendance-filters .container {
  width: 100%;
  padding: 0;
}
.attendance-form .attendance-filters .container .row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.attendance-form .attendance-filters .permission-label {
  position: relative;
  padding-top: 6px;
}
.attendance-form .attendance-filters .permission-label .small-bold {
  position: absolute;
  top: -6px;
}
.attendance-form .attendance-filters .search-bar {
  margin-bottom: 0;
  width: 100%;
}
.attendance-form .attendance-filters .search-bar .search-bar-form {
  width: 100%;
}
.attendance-form .attendance-filters .search-bar .search-bar-form .search-input,
.attendance-form .attendance-filters .search-bar .search-bar-form .input-group,
.attendance-form .attendance-filters .search-bar .search-bar-form .form-group {
  width: 100%;
}
