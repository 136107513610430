.video-thumb {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.video-thumb.default-thumb {
  background-image: url('../../../images/image.svg');
  opacity: 0.5;
  background-size: 60px;
  background-position: center;
  background-repeat: no-repeat;
}
.video-thumb > * {
  opacity: 0;
}
.video-thumb .react-thumbnail-generator {
  width: 100%;
  height: 100%;
}
.video-thumb .react-thumbnail-generator img {
  width: 100%;
  height: 100%;
}
