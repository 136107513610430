.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.attendance-instance-table {
  margin-top: 10px;
  margin-bottom: 0;
  width: 100%;
}
.attendance-instance-table thead tr th {
  padding-top: 13px;
  padding-bottom: 13px;
  border-bottom: 0px solid #eaeaec;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
.attendance-instance-table thead tr th:first-child {
  width: 30px;
}
.attendance-instance-table thead tr th:nth-child(2) {
  width: 250px !important;
}
.attendance-instance-table thead tr th:nth-child(3) {
  width: 500px !important;
}
.attendance-instance-table thead tr th:nth-child(4) {
  text-align: center !important;
  width: 120px;
}
.attendance-instance-table thead tr th:nth-child(5) {
  text-align: center !important;
  width: 120px;
}
.attendance-instance-table thead tr th:nth-child(6) {
  text-align: center !important;
  width: 120px;
}
.attendance-instance-table thead tr th:nth-child(7) {
  text-align: center !important;
  width: 120px;
}
.attendance-instance-table tbody tr.selectable {
  cursor: pointer;
}
.attendance-instance-table tbody tr:first-child td {
  border-top: none !important;
}
.attendance-instance-table tbody tr td {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eaeaec;
  vertical-align: middle;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #454a5c;
  border-width: 0 !important;
}
.attendance-instance-table tbody tr td:first-child {
  width: 30px;
  padding-left: 20px;
  padding-right: 20px;
}
.attendance-instance-table tbody tr td:nth-child(2) {
  width: 250px !important;
}
.attendance-instance-table tbody tr td:nth-child(3) {
  width: 500px !important;
}
.attendance-instance-table tbody tr td:nth-child(4) {
  width: 120px;
}
.attendance-instance-table tbody tr td:nth-child(5) {
  width: 120px;
}
.attendance-instance-table tbody tr td:nth-child(6) {
  width: 120px;
}
.attendance-instance-table tbody tr td:nth-child(7) {
  width: 120px;
}
.attendance-instance-table .parent {
  background-color: #eaeaec !important;
}
.attendance-instance-table .td-empty-table {
  text-align: center;
  height: 200px;
  vertical-align: middle;
  font-size: 24px;
  letter-spacing: -0.6px;
  color: rgba(69, 74, 92, 0.5);
  width: 100%;
}
.attendance-instance-table.stretch-table tbody tr td {
  padding-top: 10px;
  padding-bottom: 10px;
}
.attendance-instance-table.dark-mode thead > tr > th {
  color: white;
  border-color: rgba(255, 255, 255, 0.05);
}
.attendance-instance-table.dark-mode tbody tr td {
  border-color: rgba(255, 255, 255, 0.05);
  color: white;
}
.attendance-instance-table.dark-mode tbody tr td .total-row {
  color: #d4fb00;
}
.attendance-instance-table.dark-mode .td-empty-table {
  color: rgba(255, 255, 255, 0.5);
}
.attendance-instance-table.dark-mode .parent {
  background-color: rgba(69, 74, 92, 0.5) !important;
}
.rotable-icon {
  transform: rotate(180deg) !important;
}
