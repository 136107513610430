.meeting-info-form {
  padding: 0px 40px;
}
.meeting-info-form h4 {
  font-size: 14px;
  font-weight: bold;
  margin-top: 30px;
  text-transform: uppercase;
}
.meeting-info-form .control-label {
  line-height: 48px;
  opacity: 0.5;
  text-align: left;
}
.meeting-info-form .radio-button {
  line-height: 55px;
}
.meeting-info-form .radio-button .orientation-simple:not(:first-child) {
  margin-left: 16px !important;
}
.meeting-info-form .radio-button span {
  margin-left: 0px;
}
.meeting-info-form .meeting-info-time-to {
  margin: 0 10px;
}
.meeting-info-form .time-picker {
  display: inline-block;
  width: 100px;
}
.meeting-info-form .time-picker input {
  background: none;
}
.meeting-info-form .buttons-container {
  text-align: right;
}
.meeting-info-form .buttons-container .button:first-child {
  margin-right: 10px;
}
