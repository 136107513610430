.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.project-open-items-report-modal .modal-dialog {
  width: 90% !important;
}
.project-open-items-report-modal .modal-dialog .modal-body {
  padding: 0px 16px 40px 16px !important;
}
.project-open-items-report-modal .modal-dialog .modal-body .reports {
  padding-top: 0px;
}
.project-open-items-report-modal .modal-dialog .modal-body .reports .details-title {
  padding: 16px 24px;
}
.project-open-items-report-modal .modal-dialog .modal-body .reports .details-title .details-title-text {
  font-weight: 600;
  font-size: 29px;
  line-height: 46px;
  letter-spacing: 0.7px;
}
.project-open-items-report-modal .modal-dialog .modal-body .reports .report-filter {
  padding: 0px 24px;
}
