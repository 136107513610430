.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.budget-modal .modal-dialog {
  top: 45%;
  transform: translateY(-50%) !important;
  width: 80vw;
  height: 60vh;
}
.budget-modal .modal-dialog .modal-content {
  border-radius: 0px;
  box-shadow: none;
  height: 100%;
}
.budget-modal .modal-dialog .modal-content .modal-header {
  position: absolute;
  top: 0;
  right: 8px;
  z-index: 2;
  border-bottom: 0;
}
.budget-modal .modal-dialog .modal-content .modal-header .close {
  color: #454a5c;
  opacity: 0.8;
}
.budget-modal .modal-dialog .modal-content .modal-body {
  padding: 30px 20px 20px;
  height: 100%;
}
.budget-modal .modal-dialog .modal-content .modal-body .project-dashboard-budget {
  height: 100%;
}
.dark-mode .budget-modal .modal-dialog .modal-content {
  background-color: #303441;
}
.dark-mode .budget-modal .modal-dialog .modal-content .modal-header .close {
  color: #ffffff;
}
