.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.details-info-item > div:first-child > span {
  margin-left: 8px;
}
.details-info-item > div:nth-child(2) {
  margin-top: 5px;
}
