.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.multiple-dropdown {
  position: relative;
}
.multiple-dropdown .dropdown-control {
  display: flex;
  align-content: center;
  justify-content: center;
  min-height: 40px;
  padding: 3px 8px 4px 8px;
  font-size: 16px;
  line-height: 32px;
  color: #454a5c;
  border-bottom: 1px solid #7a7e8b;
  cursor: pointer;
}
.multiple-dropdown .dropdown-menu-selector-arrow {
  margin-left: auto;
}
.multiple-dropdown .dropdown-control-placeholder {
  opacity: 0.7;
}
.multiple-dropdown .label-preview-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: -4px;
}
.multiple-dropdown .label-preview-container .label-preview-item {
  display: flex;
  align-items: center;
  min-height: 32px;
  margin-top: 4px;
  padding: 0px 8px;
  border-radius: 100px;
  background: #eaeaec;
}
.multiple-dropdown .label-preview-container .label-preview-item:not(:last-child) {
  margin-right: 4px;
}
.multiple-dropdown .label-preview-container .label-preview-item .label-preview-item-remove {
  padding: 8px;
  margin-right: -4px;
}
.multiple-dropdown .options {
  border-top: none;
  position: absolute;
  width: 100%;
  z-index: 2;
  background-color: #ffffff;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}
.multiple-dropdown .options .no-options {
  padding: 8px 10px;
}
.multiple-dropdown .options .options-buttons {
  width: 100%;
  max-height: 300px;
  overflow: auto;
  overflow: overlay;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
}
.multiple-dropdown .options .button-option {
  display: block;
  width: 100%;
  padding: 12px 12px 12px 20px;
  font-size: 16px;
  line-height: 24px;
  background: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  text-align: left;
  color: #454a5c;
}
.multiple-dropdown .options .button-option:hover {
  background-color: #f7f7f8;
}
.multiple-dropdown .options .button-option.selected {
  color: #00b091;
}
.multiple-dropdown .options .button-option.selected + .menu-item-separator {
  border-color: #00b091;
}
.multiple-dropdown .options .menu-item-separator {
  margin: 0px 16px;
  margin-top: -1px;
  border-bottom: 1px solid #eaeaec;
}
.multiple-dropdown .options .menu-item-separator:last-child {
  display: none;
}
.multiple-dropdown.invalid .dropdown-control {
  border-color: #fb6120;
  color: #fb6120;
}
.multiple-dropdown.dark-mode .dropdown-control {
  color: #ffffff;
  border-color: #bcbdc1;
}
.multiple-dropdown.dark-mode .label-preview-container .label-preview-item {
  background-color: rgba(255, 255, 255, 0.05);
}
.multiple-dropdown.dark-mode .options {
  background-color: #303441;
}
.multiple-dropdown.dark-mode .options .button-option {
  color: #ffffff;
}
.multiple-dropdown.dark-mode .options .button-option:hover {
  background-color: #363a46;
}
.multiple-dropdown.dark-mode .options .button-option.selected {
  color: #d4fb00;
}
.multiple-dropdown.dark-mode .options .button-option.selected + .menu-item-separator {
  border-color: #d4fb00;
}
.multiple-dropdown.dark-mode .options .menu-item-separator {
  border-color: rgba(255, 255, 255, 0.05);
}
.multiple-dropdown.dark-mode.invalid .dropdown-control {
  border-color: #02c7ff;
  color: #02c7ff;
}
.basic-modal .multiple-dropdown.dark-mode .options {
  background-color: #3b4050;
}
.basic-modal .multiple-dropdown.dark-mode .options .button-option:hover {
  background-color: #43495a;
}
