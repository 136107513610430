.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.daily-report-form-modal .modal-dialog {
  width: 80%;
  max-width: 975px;
}
@media (max-width: 1400px) {
  .daily-report-form-modal .modal-dialog {
    width: 90%;
  }
}
.daily-report-form-modal .daily-report-form-body {
  padding: 0px 40px;
  overflow: auto;
  overflow: overlay;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
}
.daily-report-form-modal.initial-form .modal-dialog {
  max-width: 700px;
}
.daily-report-form-modal.initial-form .daily-report-form-body {
  padding-bottom: 210px;
}
.daily-report-form-modal.submitted-message .modal-dialog {
  max-width: 700px;
}
.daily-report-form-modal .modal-header-bar-label {
  display: flex;
  align-items: center;
}
.daily-report-form-modal .modal-header-bar-label .date-picker {
  margin-right: 24px;
  max-width: 125px;
}
.daily-report-form-modal .modal-form-subtitle {
  margin-top: 40px;
  margin-bottom: 24px;
}
.daily-report-form-modal .error-message {
  margin: 0px 40px;
}
.daily-report-form-modal .reports-modal-actions {
  margin-bottom: 24px;
}
.daily-report-form-modal .trade-item-floor-items {
  padding-left: 0px;
}
.daily-report-form-modal .trade-item-floor-items .trade-item-floor-item {
  cursor: pointer;
}
.daily-report-form-modal .form-block.multicontrols-block .daily-report-sub-technician-user {
  width: 240px;
}
.daily-report-form-modal .daily-report-add-delivery {
  width: 240px;
}
