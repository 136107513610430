.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.stamp {
  background: #ffffff;
  font-size: 12px;
  font-weight: normal;
  margin-top: 11px;
  max-width: 430px;
}
.stamp .stamp-block {
  margin: 0 24px;
  padding: 24px 0;
}
.stamp .stamp-read-only {
  opacity: 0.5;
}
.stamp .stamp-header {
  align-items: center;
  background: #f7f7f8;
  display: flex;
  margin: 0;
  padding: 12px 24px;
}
.stamp .stamp-label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
}
.stamp .stamp-disclaimer {
  font-size: 14px;
  margin-bottom: 16px;
}
.stamp .stamp-action {
  margin-top: 16px;
}
.stamp .stamp-action .labeled-checkbox {
  align-items: flex-start;
}
.stamp .stamp-action .labeled-checkbox .checkbox-container {
  margin-top: 4px;
}
.stamp .stamp-action .labeled-checkbox .checkbox-label {
  font-size: unset;
}
.stamp .stamp-action .stamp-action-label .stamp-action-attr {
  align-items: flex-start;
  display: flex;
}
.stamp .stamp-action .stamp-action-label .stamp-action-title {
  font-size: 14px;
}
.stamp .stamp-action .stamp-action-label .stamp-action-edit {
  cursor: pointer;
  margin-left: 8px;
  width: 14px;
  margin-top: 4px;
  vertical-align: top;
}
.stamp .stamp-action .stamp-action-label .stamp-action-edit.stamp-action-edit-read-only {
  cursor: auto;
}
.stamp .stamp-action .stamp-action-label .stamp-action-input {
  display: inline-block;
  margin-top: 8px;
  width: 100%;
}
.stamp .stamp-action .stamp-action-label .stamp-action-input.readonly-input {
  height: auto;
}
.stamp .stamp-action .stamp-action-label .stamp-action-input:first-child {
  margin-top: 0;
}
.stamp .stamp-action .stamp-action-label .stamp-action-title-input {
  font-size: 14px;
}
.stamp .stamp-action .stamp-action-label .stamp-action-title-input.readonly-input > div {
  font-size: 14px;
}
.stamp .stamp-action .stamp-action-label .stamp-action-subtitle-input {
  font-size: 12px;
}
.stamp .stamp-action .stamp-action-label .stamp-action-subtitle-input.readonly-input > div {
  font-size: 12px;
}
.stamp .stamp-action .stamp-action-label .stamp-action-buttons {
  margin-top: 16px;
  display: flex;
  justify-content: center;
}
.stamp .stamp-legal {
  padding-top: 0px;
  text-align: justify;
}
.stamp .stamp-legal > p {
  margin-bottom: 4px;
}
.stamp .stamp-label-invalid {
  color: #fb6120;
}
.stamp.dark-mode {
  background-color: #303441;
}
.stamp.dark-mode .stamp-header {
  background-color: #21242f;
}
.stamp.dark-mode .stamp-label-invalid {
  color: #02c7ff;
}
