.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.single-image-upload {
  margin-right: 32px;
  margin-bottom: 32px;
  width: 250px;
}
.single-image-upload .image-block {
  width: 250px;
  height: 250px;
  margin-bottom: 4px;
}
.single-image-upload .image-block .preview {
  width: 100%;
  height: 100%;
  position: relative;
}
.single-image-upload .image-block .preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.single-image-upload .image-block .preview .close-button {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fcfcfc;
  cursor: pointer;
}
.single-image-upload .image-block .preview .close-button img {
  width: 14px;
  height: 14px;
  object-fit: contain;
}
.dark-mode .single-image-upload .image-block .drop-zone span {
  color: #bcbdc1;
}
.dark-mode .single-image-upload .image-block .preview .close-button {
  background-color: #3b4050;
}
