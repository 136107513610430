.selector {
  width: 100%;
  position: relative;
}
.selector .selector-remove {
  position: absolute;
  cursor: pointer;
  width: 8px;
  height: 8px;
  top: calc(50% - 4px);
  right: 26px;
}
.selector .selector-remove.hide-underline {
  right: 18px;
}
