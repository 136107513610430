.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.collapsible-section {
  margin: 12px 0;
}
.collapsible-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
}
.collapsible-header .collapsible-title {
  display: flex;
  align-items: center;
}
.collapsible-header .collapsible-title.active {
  cursor: pointer;
}
.collapsible-header .collapsible-title.active .collapsible-label {
  cursor: pointer;
}
.collapsible-header .collapsible-title .collapsible-label {
  color: #454a5c;
  font-size: 14px;
  margin-bottom: 1px;
  font-weight: 600;
}
.collapsible-header .collapsible-title .collapsible-icon.default,
.collapsible-header .collapsible-title .collapsible-icon.end {
  margin-left: 10px;
}
.collapsible-header .collapsible-title .collapsible-icon.start {
  margin-right: 10px;
}
.collapsible-body {
  padding: 8px 0;
}
.dark-mode .collapsible-header .collapsible-title .collapsible-label {
  color: #ffffff;
}
