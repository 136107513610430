.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.rate-title {
  float: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.rate-container {
  position: relative;
  display: inline-block;
}
.rate-container .rate-title {
  position: absolute;
  top: 0;
  left: 0;
  right: 14px;
  font-size: 16px;
  line-height: 24px;
}
.rate-container .rate-items {
  display: flex;
  margin-top: 32px;
}
.rate-container .rate-items .rate-item {
  width: 40px;
  height: 6px;
  background-color: #454a5c;
  opacity: 0.1;
}
.rate-container .rate-items .rate-item:first-child {
  border-radius: 100px 0px 0px 100px;
}
.rate-container .rate-items .rate-item:last-child {
  border-radius: 0px 100px 100px 0px;
}
.rate-container .rate-items .rate-item:only-child {
  border-radius: 100px 100px 100px 100px;
}
.rate-container .rate-items .rate-item:not(:last-child) {
  margin-right: 3px;
}
.rate-container .rate-items .rate-item.selected {
  background-color: #00b091;
  opacity: 1;
}
.rate-container.dark-mode .rate-items .rate-item {
  background-color: #ffffff;
  opacity: 0.2;
}
.rate-container.dark-mode .rate-items .rate-item.selected {
  background-color: #d4fb00;
  opacity: 1;
}
