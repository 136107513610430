.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.project-photos-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 470px;
  overflow: auto;
}
.project-photos-form .form-description label {
  margin-left: 0;
}
.project-photos-form .form-description textarea {
  padding-left: 0;
  padding-right: 0;
  margin: 0;
  margin-top: 8px;
  min-height: 32px;
}
.project-photos-form .chips-selector-container {
  display: flex;
  flex-wrap: wrap;
}
.chip-selector-modal .simple-table th,
.chip-selector-modal .simple-table td {
  padding-top: 8px;
  padding-bottom: 8px;
}
.chip-selector-modal .simple-table th:last-child,
.chip-selector-modal .simple-table td:last-child {
  width: 48px;
}
.chip-selector-modal .simple-table th:last-child button span,
.chip-selector-modal .simple-table td:last-child button span {
  display: none;
}
.chip-selector-modal .simple-table tr:last-child td {
  border-bottom: none;
}
.chip-selector-modal .footer-modal {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: #ffffff;
}
.project-photos-tags-modal .modal-dialog,
.project-photos-trades-modal .modal-dialog,
.project-photos-locations-modal .modal-dialog,
.project-photos-move-to-modal .modal-dialog {
  width: 500px;
}
.project-photos-users-modal .user-permissions-form .user-permissions-table {
  overflow: visible;
}
.project-photos-users-modal .search-bar-form .form-control {
  background-color: #f7f7f8;
}
.project-photos-users-modal .modal-footer {
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  border-top: none;
}
.project-photos-comments {
  height: 100%;
  width: 100%;
}
.project-photos-comments .list-section {
  border-top: 1px solid #9ea0aa;
}
.project-photos-comments .input-section {
  background-color: #ffffff;
  border-top: 1px solid #9ea0aa;
  position: sticky;
  position: -webkit-sticky;
  bottom: 0px;
  left: 0px;
  width: 100%;
}
.project-photos-comments .input-section button {
  width: 100%;
}
.project-photos-comments .comment-item {
  position: relative;
}
.project-photos-comments .comment-item .reply-icon {
  position: absolute;
  top: 16px;
  left: 32px;
}
.project-photos-comments .comment-item.reply {
  background-color: rgba(234, 234, 236, 0.5);
}
.project-photos-comments .comment-item.replying {
  background-color: rgba(251, 97, 32, 0.1);
}
.dark-mode .project-photos-users-modal .search-bar-form .form-control {
  background-color: #252834;
}
.dark-mode .project-photos-users-modal .modal-footer {
  background-color: #303441;
}
.dark-mode .chip-selector-modal .footer-modal {
  background-color: #303441;
}
.dark-mode .project-photos-comments .list-section {
  border-color: #3a3e4a;
}
.dark-mode .project-photos-comments .input-section {
  background-color: #21242f;
  border-color: #3a3e4a;
}
.dark-mode .project-photos-comments .comment-item.reply {
  background-color: rgba(58, 62, 74, 0.5);
}
.dark-mode .project-photos-comments .comment-item.replying {
  background-color: rgba(2, 199, 255, 0.1);
}
