.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.users-modal .modal-dialog {
  width: 600px;
}
.users-modal .modal-dialog .modal-content {
  background-color: #f7f7f8;
  width: 600px;
}
.users-modal .modal-dialog .modal-content .modal-container {
  position: relative;
}
.users-modal .modal-dialog .modal-content .modal-container .modal-body {
  position: static;
  padding: 0 40px;
  padding-bottom: 70px;
  height: calc(70vh - 70px);
}
.users-modal .modal-dialog .users-list {
  width: 100%;
  margin-top: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.users-modal .modal-dialog .buttons-container {
  position: absolute;
  z-index: 0;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f7f7f8;
  width: 100%;
}
.users-modal .modal-dialog .buttons-container .button-cancel {
  width: 153px;
  height: 40px;
  margin: 20px 10px !important;
}
.users-modal .modal-dialog .buttons-container .button-submit {
  width: 153px;
  height: 40px;
  margin: 20px 10px !important;
}
.users-modal:not(.shared) .modal-content .modal-container .modal-body {
  min-height: 170px;
  height: auto;
  overflow: visible;
}
.dark-mode .users-modal .modal-dialog .buttons-container {
  background-color: #303441;
}
