.punch-list-filters {
  padding: 15px 0;
  width: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.punch-list-filters .filters-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  margin-bottom: 10px;
}
.punch-list-filters .filters-header .close-icon img {
  cursor: pointer;
  width: 20px;
}
.punch-list-filters .filters {
  flex: 1;
  overflow-x: hidden;
  width: 100%;
  padding: 10px 25px;
}
.punch-list-filters .filters .field {
  margin-bottom: 40px;
}
.punch-list-filters .filters .field h3 {
  margin-bottom: 16px;
  font-weight: 200;
  font-size: 23px;
}
.punch-list-filters .filters .line-field {
  display: flex;
  align-items: center;
}
.punch-list-filters .filters .line-field > div {
  margin-left: 40px;
}
.punch-list-filters .filters .line-field > div:first-child {
  margin-left: 0;
}
.punch-list-filters .filters .line-field .due-date,
.punch-list-filters .filters .line-field .due-date .rdt {
  flex: 1;
  max-width: none;
}
.punch-list-filters .filters .line-field .due-date .rdtPicker,
.punch-list-filters .filters .line-field .due-date .rdt .rdtPicker {
  bottom: 110%;
}
.punch-list-filters .filters .line-field .due-date.due-to-date .rdtPicker,
.punch-list-filters .filters .line-field .due-date .rdt.due-to-date .rdtPicker {
  right: 0;
}
.punch-list-filters .filters .labeled-checkbox {
  flex-direction: row-reverse;
}
.punch-list-filters .filters .labeled-checkbox > *:first-child {
  margin-left: 12px;
  margin-right: 0;
}
.punch-list-filters .footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  margin-top: 10px;
}
.punch-list-filters .footer .button {
  margin: 0 8px;
}
