.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.client.register-user {
  padding: 0 !important;
}
.client.register-user .title-1 {
  font-size: 48px;
  font-weight: 600;
  color: #ffffff;
}
.client.register-user .buttons {
  margin-top: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
@media (max-width: 991px) {
  .client.register-user .buttons {
    margin: 30px auto 40px;
  }
  .client.register-user .right-panel {
    display: none;
  }
  .client.register-user .left-panel {
    border: none;
  }
  .client.register-user .mobile-container-button-invite {
    display: flex;
    justify-content: center;
  }
}
