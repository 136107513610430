.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.project-dashboard-shared-folders {
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
}
.project-dashboard-shared-folders .shared-folders-title {
  padding: 16px 32px 24px 32px;
  font-size: 29px;
  line-height: 46px;
  font-weight: 600;
  color: #454a5c;
}
.project-dashboard-shared-folders .shared-folders-list {
  display: flex;
  flex-direction: column;
  padding: 0px 32px;
  overflow: auto;
  overflow: overlay;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
}
.project-dashboard-shared-folders .shared-folders-list .shared-folder {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 50px auto;
  column-gap: 8px;
  margin-bottom: 8px;
  padding: 16px 8px;
  background-color: #f0f1f2;
  cursor: pointer;
}
.project-dashboard-shared-folders .shared-folders-list .shared-folder .shared-folder-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: 8px;
  background-color: #f7f7f8;
}
.project-dashboard-shared-folders .shared-folders-list .shared-folder .shared-folder-details {
  display: flex;
  flex-direction: column;
}
.project-dashboard-shared-folders .shared-folders-list .shared-folder .shared-folder-details .shared-folder-name {
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
  color: #454a5c;
}
.project-dashboard-shared-folders .shared-folders-list .shared-folder .shared-folder-details .shared-folder-description {
  font-size: 14px;
  line-height: 24px;
  color: #7a7e8b;
}
.project-dashboard-shared-folders .shared-folders-list .shared-folder.disabled-folder {
  opacity: 0.5;
  cursor: default;
}
.project-dashboard-shared-folders .shared-folders-list .shared-folder.disabled-folder .shared-folder-details .shared-folder-name,
.project-dashboard-shared-folders .shared-folders-list .shared-folder.disabled-folder .shared-folder-details .shared-folder-description {
  background: #f7f7f8;
  height: 8px;
  margin: 8px 0px;
}
.project-dashboard-shared-folders .shared-folders-list .shared-folder.disabled-folder .shared-folder-details .shared-folder-name.shared-folder-name,
.project-dashboard-shared-folders .shared-folders-list .shared-folder.disabled-folder .shared-folder-details .shared-folder-description.shared-folder-name {
  width: 100%;
}
.project-dashboard-shared-folders .shared-folders-list .shared-folder.disabled-folder .shared-folder-details .shared-folder-name.shared-folder-description,
.project-dashboard-shared-folders .shared-folders-list .shared-folder.disabled-folder .shared-folder-details .shared-folder-description.shared-folder-description {
  width: 40px;
}
.project-dashboard-shared-folders.disabled .shared-folders-list {
  overflow: hidden;
}
.project-dashboard-shared-folders.dark-mode .shared-folders-title {
  color: #ffffff;
}
.project-dashboard-shared-folders.dark-mode .shared-folders-list .shared-folder {
  background-color: #191b24;
}
.project-dashboard-shared-folders.dark-mode .shared-folders-list .shared-folder .shared-folder-icon {
  background-color: #303441;
}
.project-dashboard-shared-folders.dark-mode .shared-folders-list .shared-folder .shared-folder-details .shared-folder-name {
  color: #ffffff;
}
.project-dashboard-shared-folders.dark-mode .shared-folders-list .shared-folder .shared-folder-details .shared-folder-description {
  color: #bcbdc1;
}
.project-dashboard-shared-folders.dark-mode .shared-folders-list .shared-folder.disabled-folder .shared-folder-details .shared-folder-name,
.project-dashboard-shared-folders.dark-mode .shared-folders-list .shared-folder.disabled-folder .shared-folder-details .shared-folder-description {
  background: #21242f;
}
@media (max-height: 1000px), (max-width: 1600px) {
  .project-dashboard-shared-folders .shared-folders-title {
    padding: 8px 16px;
    font-size: 26px;
    line-height: 38px;
  }
  .project-dashboard-shared-folders .shared-folders-list {
    padding: 0px 16px;
  }
  .project-dashboard-shared-folders .shared-folders-list .shared-folder {
    padding: 8px;
  }
}
@media (max-height: 800px), (max-width: 1300px) {
  .project-dashboard-shared-folders .shared-folders-title {
    padding: 8px 16px;
    font-size: 18px;
    line-height: 24px;
  }
  .project-dashboard-shared-folders .shared-folders-list .shared-folder {
    grid-template-columns: 32px auto;
  }
  .project-dashboard-shared-folders .shared-folders-list .shared-folder .shared-folder-icon {
    height: 32px;
  }
  .project-dashboard-shared-folders .shared-folders-list .shared-folder .shared-folder-details .shared-folder-name {
    font-size: 14px;
    line-height: 24px;
  }
  .project-dashboard-shared-folders .shared-folders-list .shared-folder .shared-folder-details .shared-folder-description {
    font-size: 12px;
    line-height: 16px;
  }
}
