.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.html-viewer-modal {
  min-width: 1000px !important;
}
.html-viewer-modal .modal-dialog {
  min-width: 1000px !important;
}
.html-viewer-modal .modal-dialog .modal-content {
  height: 100%;
}
.html-viewer-modal .modal-dialog .modal-content .modal-body {
  height: 93%;
  min-width: 1000px !important;
}
.html-viewer-modal .modal-dialog .modal-content .modal-body .html-viewer-iframe {
  border: 1px solid #edf1f7;
}
.html-viewer-modal .modal-dialog .modal-content .modal-header {
  display: flex;
  justify-content: space-between;
}
.html-viewer-modal .modal-dialog .modal-content .modal-header > div:first-child {
  flex: 1;
}
