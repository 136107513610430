.admin-user-registration {
  height: 100%;
}
.admin-user-registration .sidebar-form-options {
  display: none;
}
.admin-user-registration .form-block .form-control-block.small-control {
  width: 248px;
}
.admin-user-registration .form-block .form-control-block.big-control {
  width: 520px;
}
