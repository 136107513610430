.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.project-meeting-minutes .meeting-types-selector {
  margin-left: 16px;
}
.project-meeting-minutes .meeting-info {
  margin-top: 16px;
}
.project-meeting-minutes .meeting-info .details-title {
  margin-bottom: 16px;
}
.project-meeting-minutes .meeting-info .edit-meeting-info-details {
  color: #fb6120;
  cursor: pointer;
  font-size: 16px;
  margin-left: 40px;
}
.project-meeting-minutes .meeting-info .meeting-info-read-only {
  padding: 0px 40px;
}
.project-meeting-minutes .meeting-info .meeting-info-read-only .row {
  margin-bottom: 16px;
}
.project-meeting-minutes .meeting-info .meeting-info-read-only .row:last-child {
  margin-bottom: 0;
}
.project-meeting-minutes .meeting-minutes {
  margin-top: 40px;
}
.project-meeting-minutes .meeting-minutes .meeting-minutes-table-container {
  margin-top: 16px;
  padding: 0px 16px;
}
.project-meeting-minutes .meeting-minutes .meeting-minutes-table colgroup > col:nth-child(1) {
  width: 8%;
}
.project-meeting-minutes .meeting-minutes .meeting-minutes-table colgroup > col:nth-child(2) {
  width: 12%;
}
.project-meeting-minutes .meeting-minutes .meeting-minutes-table colgroup > col:nth-child(3) {
  width: 28%;
}
.project-meeting-minutes .meeting-minutes .meeting-minutes-table colgroup > col:nth-child(4) {
  width: 26%;
}
.project-meeting-minutes .meeting-minutes .meeting-minutes-table colgroup > col:nth-child(5) {
  width: 8%;
}
.project-meeting-minutes .meeting-minutes .meeting-minutes-table colgroup > col:nth-child(6) {
  width: 8%;
}
.project-meeting-minutes .meeting-minutes .meeting-minutes-table colgroup > col:nth-child(7) {
  width: 10%;
}
