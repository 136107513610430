.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.project-dashboard-photos .photo-slide .photo-slide-image {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.project-dashboard-photos .swiper-pagination-bullets {
  height: 72px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(32px);
}
.project-dashboard-photos .swiper-view-more {
  line-height: 72px;
  margin-top: -72px;
  margin-right: 36px;
}
.project-dashboard-photos .photo-date-footer {
  margin-left: 32px;
  margin-right: auto;
  font-weight: normal;
}
.project-dashboard-photos.disabled {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.project-dashboard-photos.disabled .disabled-photos-text {
  margin-top: 32px;
  font-size: 29px;
  line-height: 46px;
  color: #7a7e8b;
}
.project-dashboard-photos.dark-mode.disabled .disabled-photos-text {
  color: #bcbdc1;
}
@media (max-height: 800px), (max-width: 1300px) {
  .project-dashboard-photos .swiper-pagination-bullets {
    height: 32px;
  }
  .project-dashboard-photos .swiper-view-more {
    margin-top: -32px;
    line-height: 32px;
    margin-right: 16px;
  }
  .project-dashboard-photos .photo-date-footer {
    margin-left: 16px;
  }
}
