.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.invite-user-modal .modal-body {
  padding: 24px 40px 40px 40px !important;
}
.invite-user-modal .multiple-container > div {
  display: flex;
}
.invite-user-modal .multiple-container > div > *:first-child {
  margin-right: 16px;
}
