.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.client.login .subtitle {
  color: #3c4249;
}
.client.login .subtitle > img {
  vertical-align: middle;
  width: 19px;
}
.client.login .subtitle > span {
  margin-left: 10px;
  vertical-align: middle;
}
.client.login .bottom-title {
  display: flex;
  justify-content: center;
}
.client.login .bottom-title-wrapper {
  margin-top: 50px;
}
.client.login .bottom-title-wrapper.register-link-wrapper {
  margin-top: 16px;
}
.client.login .recover-password-span {
  color: #d4fb00;
  font-size: 12px;
  line-height: 18px;
  padding-left: 10px;
  padding-top: 5px;
  width: 130px;
}
.client.login .register-link-span {
  color: #d4fb00;
}
.client.login .recover-password-span:hover,
.client.login .register-link-span:hover {
  color: #d4fb00;
  opacity: 0.7;
}
