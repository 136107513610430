.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.dashboard-nav .dashboard-nav-header {
  align-items: center;
  display: flex;
}
.dashboard-nav .dashboard-nav-header .button-bids-due {
  height: 25px;
  line-height: 11px;
  margin-left: 20px;
}
.dashboard-nav .dashboard-nav-items {
  display: flex;
  margin-bottom: 32px;
  color: rgba(69, 74, 92, 0.5);
}
.dashboard-nav .dashboard-nav-items .dashboard-nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 0px 10px 0px;
  border-left: 1px solid rgba(69, 74, 92, 0.5);
  cursor: pointer;
  width: 25%;
}
.dashboard-nav .dashboard-nav-items .dashboard-nav-item:first-child {
  border: none !important;
}
.dashboard-nav .dashboard-nav-items .dashboard-nav-item.dark-mode {
  color: rgba(255, 255, 255, 0.3);
}
.dashboard-nav .dashboard-nav-items .dashboard-nav-item.selected {
  color: #454a5c;
  border-left: 1px solid #454a5c;
  opacity: 1;
}
.dashboard-nav .dashboard-nav-items .dashboard-nav-item.selected.dark-mode {
  color: #ffffff;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
}
.dashboard-nav .dashboard-nav-items .dashboard-nav-item.selected + .dashboard-nav-item {
  border-left: 1px solid #454a5c;
}
.dashboard-nav .dashboard-nav-items .dashboard-nav-item.selected + .dashboard-nav-item.dark-mode {
  border-left: 1px solid rgba(255, 255, 255, 0.5);
}
.dashboard-nav .dashboard-nav-items .dashboard-nav-item .dashboard-nav-item-count {
  font-size: 29px;
  line-height: 46px;
}
.dashboard-nav .dashboard-nav-items .dashboard-nav-item .dashboard-nav-item-title {
  font-size: 16px;
  line-height: 24px;
}
.dashboard-nav .dashboard-nav-indicator {
  border-bottom: 20px solid #fff;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  height: 0;
  -webkit-filter: drop-shadow(0 0 0 #edf1f7);
  filter: drop-shadow(0 -3px 1px #edf1f7);
  position: relative;
  width: 0;
}
.dashboard-nav .dashboard-nav-indicator.indicator-0 {
  border-color: transparent;
}
.dashboard-nav .dashboard-nav-indicator.indicator-1 {
  margin-left: calc(10% - 20px);
}
.dashboard-nav .dashboard-nav-indicator.indicator-2 {
  margin-left: calc(30% - 20px);
}
.dashboard-nav .dashboard-nav-indicator.indicator-3 {
  margin-left: calc(50% - 20px);
}
.dashboard-nav .dashboard-nav-indicator.indicator-4 {
  margin-left: calc(70% - 20px);
}
.dashboard-nav .dashboard-nav-indicator.indicator-5 {
  margin-left: calc(90% - 20px);
}
.dashboard-nav .dashboard-nav-content {
  padding: 24px 16px 32px 16px;
  background: #f7f7f8;
}
.dashboard-nav .dashboard-nav-content .pagination-container {
  margin-top: 16px;
}
.dashboard-nav .dashboard-nav-content.dark-mode {
  background: #21242f;
}
