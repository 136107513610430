.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.company-details .content-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.company-details .content-container .information-items {
  width: 100%;
  padding: 24px 40px 24px 40px;
  display: flex;
  flex-wrap: wrap;
}
.company-details .content-container .information-items .details-info-item {
  width: 25%;
  margin-bottom: 24px;
}
.company-details .content-container .information-items .details-info-item:not(:first-child) {
  padding-left: 16px;
}
.company-details .content-container .information-items .details-info-item .link-button {
  margin-top: 11px;
}
.company-details .content-container .information-items .details-info-item.details-info-item-stamp-container {
  width: unset;
}
.company-details .content-container .information-items .details-info-item.details-info-item-stamp-container .details-info-item-stamp {
  cursor: pointer;
}
.company-details .content-container .information-items .details-info-item.details-info-item-stamp-container .details-info-item-stamp > span > svg {
  margin-left: 5px;
}
.company-details .content-container .search-bar {
  margin: 0px 40px;
}
.company-details .content-container .search-bar .search-bar-right-content {
  margin-right: 0px;
}
.company-details .content-container .search-bar .search-bar-form:last-child {
  margin-right: 12px;
}
.company-details .content-container .users-table,
.company-details .content-container .projects-table {
  margin-top: 16px;
  padding: 0px 16px;
}
.company-details .navigation-bar {
  padding: 0px 40px;
}
.company-details .status-selector {
  margin-right: 24px;
}
