.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.activity-container {
  background: #f7f7f8;
  padding-top: 24px;
  padding-bottom: 24px;
}
.activity-container .activity-header {
  float: left;
  width: 100%;
  padding-left: 40px;
}
.activity-container .activity-header .activity-title {
  float: left;
  font-size: 23px;
  line-height: 38px;
  color: #454a5c;
}
.activity-container .activity-header .activity-total {
  float: left;
  margin-left: 6px;
  margin-top: 7px;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #fb6120;
}
.activity-container .activity-content {
  float: left;
  width: 100%;
  height: 266px;
  overflow: auto;
  overflow: overlay;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
  margin-top: 10px;
  padding-left: 16px;
  padding-right: 16px;
}
.activity-container .activity-content tr.link-tab td {
  padding-top: 12px;
  padding-bottom: 12px;
}
.activity-container .activity-content .activity-message {
  float: left;
}
.activity-container .pagination-container {
  float: left;
  width: 100%;
  margin-top: 16px;
  text-align: center;
}
.activity-container.dark-mode {
  background: #21242f;
  color: #ffffff;
}
.activity-container.dark-mode .activity-header .activity-title {
  color: #ffffff;
}
.activity-container.dark-mode .activity-header .activity-total {
  color: #02c7ff;
}
.activity-container.dark-mode .activity-content {
  scrollbar-color: rgba(86, 93, 114, 0.3) rgba(0, 0, 0, 0);
}
