.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.rfis-table table colgroup > col:nth-child(1) {
  width: 10%;
}
.rfis-table table colgroup > col:nth-child(2) {
  width: 20%;
}
.rfis-table table colgroup > col:nth-child(3) {
  width: 5%;
}
.rfis-table table colgroup > col:nth-child(4) {
  width: 10%;
}
.rfis-table table colgroup > col:nth-child(5) {
  width: 45%;
}
.rfis-table table colgroup > col:nth-child(6) {
  width: 10%;
}
