.project-closeout-requirement-body {
  margin-top: 30px;
}
.project-closeout-requirement-body table.closeout-cats {
  table-layout: fixed;
}
.project-closeout-requirement-body table.closeout-cats th {
  width: 16%;
}
.project-closeout-requirement-body table.closeout-cats th:last-child {
  width: 25%;
  padding-left: 100px;
}
.project-closeout-requirement-body table.closeout-cats th:first-child {
  width: 25%;
}
.project-closeout-requirement-body table.closeout-cats tr td:nth-child(2) div,
.project-closeout-requirement-body table.closeout-cats tr td:nth-child(3) div,
.project-closeout-requirement-body table.closeout-cats tr td:nth-child(4) div {
  margin: 0 auto;
}
.project-closeout-requirement-body table.closeout-cats tr th:nth-child(2),
.project-closeout-requirement-body table.closeout-cats tr th:nth-child(3),
.project-closeout-requirement-body table.closeout-cats tr th:nth-child(4) {
  text-align: center;
}
.project-closeout-requirement-body table.closeout-cats tr td:last-child {
  padding-left: 100px;
}
.project-closeout-requirement-body table.closeout-cats tr td:last-child button {
  display: inline-block;
}
.project-closeout-requirement-body table.closeout-cats tr td:last-child button:first-child {
  margin-right: 20px;
}
.project-closeout-requirement-body [data-rbd-droppable-id='closeout-cats'] {
  overflow: auto;
  max-height: 800px;
  cursor: url('../../../../shared/dragDropCursor.svg') 12 12, auto;
}
.project-closeout-requirement-body [data-rbd-droppable-id='closeout-cats'] tr,
.project-closeout-requirement-body [data-rbd-droppable-id='closeout-cats'] tbody {
  cursor: url('../../../../shared/dragDropCursor.svg') 12 12, auto;
}
