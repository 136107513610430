.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.advanced-documents .breadcrumb {
  margin: 0px;
  padding: 0px;
  font-size: 16px;
  line-height: 24px;
  background-color: unset;
  display: flex;
}
.advanced-documents .breadcrumb li {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.advanced-documents .breadcrumb li a {
  color: #7a7e8b;
}
.advanced-documents .breadcrumb li.active {
  color: #454a5c;
}
.advanced-documents .advanced-documents-table {
  margin-top: 16px;
  padding: 0px 16px;
}
.advanced-documents .advanced-documents-table tr > th:nth-child(1),
.advanced-documents .advanced-documents-table tr > td:nth-child(1) {
  width: 5%;
}
.advanced-documents .advanced-documents-table tr > th:nth-child(2),
.advanced-documents .advanced-documents-table tr > td:nth-child(2) {
  width: 55%;
}
.advanced-documents .advanced-documents-table tr > th:nth-child(4),
.advanced-documents .advanced-documents-table tr > td:nth-child(4) {
  width: 10%;
}
.advanced-documents .advanced-documents-table th:first-child {
  vertical-align: middle;
}
.advanced-documents.dark-mode .breadcrumb li a {
  color: rgba(255, 255, 255, 0.7);
}
.advanced-documents.dark-mode .breadcrumb li.active {
  color: #ffffff;
}
.advanced-documents .file-visibility-button {
  text-transform: capitalize;
}
