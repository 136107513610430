.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.project-open-item-form-modal .modal-dialog {
  display: flex;
  justify-content: center;
  width: auto;
  max-width: 90%;
}
.project-open-item-form-modal .modal-container {
  width: 90vw !important;
  max-width: 800px;
}
.project-open-item-form-modal .modal-body {
  max-height: calc(80vh - 70px) !important;
}
.project-open-item-form-modal .sidebar-closed + .modal-container {
  width: calc(90vw - 106px) !important;
}
.project-open-item-form-modal .sidebar-open + .modal-container {
  width: calc(90vw - 480px) !important;
}
.project-open-item-form-modal .project-open-item-actions {
  margin-bottom: 24px;
}
.project-open-item-form-modal .project-open-item-actions .error-message {
  margin-right: 40px;
}
.project-open-item-form-modal.create-project-open-item .modal-dialog {
  max-width: 800px;
}
