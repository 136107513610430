.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.basic-modal.form-modal.add-project-area .modal-dialog .modal-content .modal-body,
.basic-modal.form-modal.add-project-site .modal-dialog .modal-content .modal-body {
  padding: 0px 40px 24px;
  overflow: visible;
}
.basic-modal.form-modal.add-project-area .modal-dialog .modal-content .modal-body .block-container,
.basic-modal.form-modal.add-project-site .modal-dialog .modal-content .modal-body .block-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 24px;
}
.basic-modal.form-modal.add-project-area .modal-dialog .modal-content .modal-body .block-container .form-control-block,
.basic-modal.form-modal.add-project-site .modal-dialog .modal-content .modal-body .block-container .form-control-block {
  flex: 1;
  margin-right: 24px;
}
.basic-modal.form-modal.add-project-area .modal-dialog .modal-content .modal-body .block-container .form-control-block:last-child,
.basic-modal.form-modal.add-project-site .modal-dialog .modal-content .modal-body .block-container .form-control-block:last-child {
  margin-right: 0px;
}
.basic-modal.form-modal.add-project-area .modal-dialog .modal-content .modal-body .block-container .or-span,
.basic-modal.form-modal.add-project-site .modal-dialog .modal-content .modal-body .block-container .or-span {
  margin-top: 21px;
  margin-right: 24px;
  font-size: 16px;
  color: #9ea0aa;
}
.basic-modal.form-modal.add-project-area .modal-dialog .modal-content .modal-body .block-container .labeled-checkbox,
.basic-modal.form-modal.add-project-site .modal-dialog .modal-content .modal-body .block-container .labeled-checkbox {
  margin-top: 20px;
}
.basic-modal.form-modal.add-project-area .modal-dialog .modal-content .modal-body .error-message,
.basic-modal.form-modal.add-project-site .modal-dialog .modal-content .modal-body .error-message {
  margin-bottom: 8px;
}
.basic-modal.form-modal.add-project-area .modal-dialog .modal-content .modal-body .button-container,
.basic-modal.form-modal.add-project-site .modal-dialog .modal-content .modal-body .button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}
.basic-modal.form-modal.add-project-area .modal-dialog .modal-content .modal-body .button-container button,
.basic-modal.form-modal.add-project-site .modal-dialog .modal-content .modal-body .button-container button {
  margin-right: 16px;
}
.basic-modal.form-modal.add-project-area .modal-dialog .modal-content .modal-body .button-container button:last-child,
.basic-modal.form-modal.add-project-site .modal-dialog .modal-content .modal-body .button-container button:last-child {
  margin-right: 0px;
}
.basic-modal.form-modal.dark-mode.add-project-area .modal-dialog .modal-content .modal-body .block-container .or-span,
.basic-modal.form-modal.dark-mode.add-project-site .modal-dialog .modal-content .modal-body .block-container .or-span {
  color: #909197;
}
