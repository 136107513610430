.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.closeout-cats-by-company-container.dark-mode .divider {
  background: #191b24;
}
.closeout-cats-by-company-container.dark-mode .closeout-cats-by-company-header {
  color: #ffffff;
}
.closeout-cats-by-company-container.dark-mode .closeout-cats-by-company-header .closeout-cats-by-company-toggle {
  color: #d4fb00;
}
.closeout-cats-by-company-container.dark-mode .closeout-cats-by-company-table tr td:first-child,
.closeout-cats-by-company-container.dark-mode .closeout-cats-by-company-table tr th:first-child,
.closeout-cats-by-company-container.dark-mode .closeout-cats-by-company-table tr td:nth-child(2),
.closeout-cats-by-company-container.dark-mode .closeout-cats-by-company-table tr th:nth-child(2) {
  background: #21242f;
}
.closeout-cats-by-company-container .closeout-cats-by-company-header {
  margin-top: 15px;
  align-items: center;
  cursor: pointer;
  display: flex;
  color: #454a5c;
}
.closeout-cats-by-company-container .closeout-cats-by-company-header .closeout-cats-by-company-toggle {
  margin-left: 10px;
  font-size: 16px;
  color: #00b091;
}
.closeout-cats-by-company-container .closeout-cats-by-company-header h4 {
  font-size: 23px;
  line-height: 38px;
  margin: 0px;
  margin-left: 25px;
}
.closeout-cats-by-company-container .divider {
  height: 40px;
  background: white;
  margin-left: -16px;
}
.closeout-cats-by-company-container .closeout-cats-by-company-table-container {
  margin-top: 25px;
  overflow-x: scroll;
}
.closeout-cats-by-company-container .closeout-cats-by-company-table-container table {
  table-layout: fixed;
}
.closeout-cats-by-company-container .closeout-cats-by-company-table-container table colgroup > col {
  width: 175px;
}
.closeout-cats-by-company-container .closeout-cats-by-company-table-container table colgroup > col:first-child {
  width: 230px;
}
.closeout-cats-by-company-container .closeout-cats-by-company-table-container table colgroup > col:nth-child(2) {
  width: 200px;
}
.closeout-cats-by-company-container .closeout-cats-by-company-table-container tr td div {
  margin: 0 auto;
}
.closeout-cats-by-company-container .closeout-cats-by-company-table-container tr td:first-child,
.closeout-cats-by-company-container .closeout-cats-by-company-table-container tr td:nth-child(2) {
  position: sticky;
  background: #f7f7f8;
}
.closeout-cats-by-company-container .closeout-cats-by-company-table-container tr td:first-child div,
.closeout-cats-by-company-container .closeout-cats-by-company-table-container tr td:nth-child(2) div {
  margin: 0;
}
.closeout-cats-by-company-container .closeout-cats-by-company-table-container tr td:first-child {
  left: 0;
}
.closeout-cats-by-company-container .closeout-cats-by-company-table-container tr td:nth-child(2) {
  left: 230px;
}
.closeout-cats-by-company-container .closeout-cats-by-company-table-container th {
  vertical-align: top;
  text-align: center;
}
.closeout-cats-by-company-container .closeout-cats-by-company-table-container th:nth-child(1),
.closeout-cats-by-company-container .closeout-cats-by-company-table-container th:nth-child(2) {
  text-align: left;
  text-overflow: ellipsis;
  position: sticky;
  background: #f7f7f8;
}
.closeout-cats-by-company-container .closeout-cats-by-company-table-container th:first-child {
  left: 0;
}
.closeout-cats-by-company-container .closeout-cats-by-company-table-container th:nth-child(2) {
  left: 230px;
}
