.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.permissions-modal-view .permissions-modal-form {
  padding-bottom: 50px;
}
.permissions-modal-view .permissions-description {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #454a5c;
  margin-bottom: 36px;
  padding: 0px 40px;
}
.permissions-modal-view .permissions-description-dark {
  color: #ffffff;
}
.permissions-modal-view .permissions-checkbox-container {
  margin: 0px 16px;
  padding: 12px 24px;
  border-bottom: 1px solid #eaeaec;
}
.permissions-modal-view .permissions-checkbox-container-dark {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.permissions-modal-view .permissions-checkbox {
  margin-right: 36px;
}
.permissions-modal-view .permissions-buttons-section {
  display: flex;
  justify-content: center;
  margin: 48px 20px 0px 20px;
}
.permissions-modal-view .permissions-buttons-separate {
  width: 12px;
}
