.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.basic-modal .modal-dialog {
  top: 50%;
  transform: translateY(-50%) !important;
}
.basic-modal .modal-dialog .modal-content {
  border: none;
  border-radius: unset;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.04);
  text-align: center;
  background-color: #ffffff;
}
.basic-modal .modal-dialog .modal-content .modal-header {
  padding: 17px 40px;
  text-align: left;
  border: none;
}
.basic-modal .modal-dialog .modal-content .modal-header .modal-title {
  font-size: 23px;
  line-height: 38px;
  color: #000000;
}
.basic-modal .modal-dialog .modal-content .modal-body {
  padding: unset;
}
.basic-modal .modal-dialog .modal-content .modal-body .messages-container {
  padding: 32px 40px;
  font-size: 18px;
  line-height: 30px;
  color: #454a5c;
}
.basic-modal .modal-dialog .modal-content .modal-body .messages-container .message-icon {
  margin: 16px 0px 24px 0px;
}
.basic-modal .modal-dialog .modal-content .modal-body .messages-container .sub-message {
  padding-top: 10px;
  font-size: 14px;
  line-height: 24px;
}
.basic-modal .modal-dialog .modal-content .modal-body .buttons-container {
  padding-bottom: 50px;
}
.basic-modal .modal-dialog .modal-content .modal-body .buttons-container .button {
  margin: 0px 20px;
}
.basic-modal.dark-mode .modal-dialog .modal-content {
  background-color: #303441;
}
.basic-modal.dark-mode .modal-dialog .modal-content .modal-header .modal-title {
  color: #ffffff;
}
.basic-modal.dark-mode .modal-dialog .modal-content .modal-body .messages-container {
  color: #ffffff;
}
