.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.menu.dropdown-menu-container .dropdown-menu-selector {
  display: flex;
  align-items: center;
  color: #454a5c;
  user-select: none;
}
.menu.dropdown-menu-container .dropdown-menu-selector .menu-item-counter {
  margin-left: 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
}
.menu.dropdown-menu-container .dropdown-menu-selector .dropdown-menu-selector-arrow {
  padding: 9px 7px;
}
.menu.dropdown-menu-container .popover-arrow {
  display: none;
}
.menu.dropdown-menu-container.menu-open .dropdown-menu-selector .dropdown-menu-selector-arrow {
  transform: rotate(180deg);
}
.menu.dropdown-menu-container.form-dropdown-menu .dropdown-menu-selector {
  padding: 7px 0px 8px 8px;
  font-size: 16px;
  line-height: 24px;
  border-bottom: 1px solid #7a7e8b;
}
.menu.dropdown-menu-container.form-dropdown-menu.menu-open .dropdown-menu-selector {
  border-color: #00b091;
}
.menu.dropdown-menu-container.section-selector-dropdown-menu .dropdown-menu-selector {
  padding: 1px 8px 1px 0px;
  font-size: 23px;
  line-height: 38px;
}
.menu.dropdown-menu-container.dark-mode .dropdown-menu-selector {
  color: #ffffff;
}
.menu.dropdown-menu-container.dark-mode .popover-children {
  background-color: #303441;
}
.menu.dropdown-menu-container.dark-mode .popover-children .menu-item.selected {
  color: #d4fb00;
}
.menu.dropdown-menu-container.dark-mode .popover-children .menu-item.selected + .menu-item-separator {
  border-color: #d4fb00;
}
.menu.dropdown-menu-container.dark-mode .popover-children .menu-item:hover {
  background-color: #363a46;
}
.menu.dropdown-menu-container.dark-mode.form-dropdown-menu .dropdown-menu-selector {
  border-color: #bcbdc1;
}
.menu.dropdown-menu-container.dark-mode.form-dropdown-menu.menu-open .dropdown-menu-selector {
  border-color: #d4fb00;
}
.dropdown-menu-container.popover-children {
  max-height: 336px !important;
  display: block !important;
  overflow: auto !important;
}
.dropdown-menu-container.popover-children .menu-item .menu-item-counter {
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
}
.dropdown-menu-container.popover-children .menu-item.selected {
  color: #00b091;
}
.dropdown-menu-container.popover-children .menu-item.selected + .menu-item-separator {
  border-color: #00b091;
}
.dropdown-menu-container.form-dropdown-menu.popover-children {
  width: unset;
  min-width: 60px;
}
.dropdown-menu-container.form-dropdown-menu.popover-children .menu-item {
  padding: 8px 8px;
}
.dropdown-menu-container.form-dropdown-menu.popover-children .menu-item-separator {
  margin: 0px 4px;
  margin-top: -1px;
}
.dropdown-menu-container.section-selector-dropdown-menu.popover-children .menu-item {
  padding: 12px 12px 12px 20px;
}
.dropdown-menu-container.dark-mode.popover-children {
  background-color: #303441;
}
.dropdown-menu-container.dark-mode.popover-children .menu-item.selected {
  color: #d4fb00;
}
.dropdown-menu-container.dark-mode.popover-children .menu-item.selected + .menu-item-separator {
  border-color: #d4fb00;
}
.dropdown-menu-container.dark-mode.popover-children .menu-item:hover {
  background-color: #363a46;
}
