.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.project-photo-upload .project-photo-dropzone {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #9ea0aa;
  cursor: pointer;
}
.project-photo-upload .project-photo-dropzone img {
  margin-right: 12px;
}
.project-photo-upload .project-photo-dropzone span {
  color: #454a5c;
}
.project-photo-upload .project-photos-previews {
  gap: 12px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-height: 170px;
  overflow: auto;
}
.project-photo-upload .project-photos-previews .project-photo-preview {
  height: 94px;
  min-width: 0;
  min-height: 0;
  position: relative;
}
.project-photo-upload .project-photos-previews .project-photo-preview .cancel {
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
}
.project-photo-upload .project-photos-previews .project-photo-preview .cancel.percent {
  width: auto;
  padding-left: 4px;
  padding-right: 4px;
  font-weight: 600;
}
.project-photo-upload .project-photos-previews .project-photo-preview.FINISHED .cancel {
  color: #00b091;
}
.project-photo-upload .project-photos-previews .project-photo-preview.ERROR .cancel {
  color: #fb6120;
}
.project-photo-upload .project-photos-previews .project-photo-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.project-photo-upload .project-photos-previews .project-photo-preview.UPLOADING img,
.project-photo-upload .project-photos-previews .project-photo-preview.WAITING img,
.project-photo-upload .project-photos-previews .project-photo-preview.ERROR img {
  opacity: 0.6;
}
.project-photo-upload .project-photos-previews .project-photo-preview .video-icon {
  position: absolute;
  z-index: 1;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  padding-left: 9px;
  padding-top: 7px;
  color: #ffffff;
  border: 2px solid #ffffff;
  background-color: rgba(0, 0, 0, 0.7);
}
.dark-mode .project-photo-upload .project-photo-dropzone span {
  color: #ffffff;
}
.dark-mode .project-photo-upload .project-photo-preview .cancel {
  background-color: rgba(255, 255, 255, 0.7);
  color: #454a5c;
}
.dark-mode .project-photo-upload .project-photo-preview.FINISHED .cancel {
  color: #d4fb00;
}
.dark-mode .project-photo-upload .project-photo-preview.ERROR .cancel {
  color: #02c7ff;
}
