.loader-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.3;
  z-index: 2000;
  background-color: #000;
}
.loader-container > img {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
