.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.work-orders-title {
  display: flex;
  flex-direction: row;
}
.work-orders-title > div {
  flex-grow: 1;
}
.work-orders-title > div:first-child {
  padding-right: 20px;
}
.work-orders-title > div:nth-child(2) {
  border-left: 1px solid #dfe6f1;
  display: flex;
  line-height: 1;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-top: 5px;
}
.work-orders-title > div:nth-child(2) > div {
  flex-grow: 1;
}
.work-orders-title > div:nth-child(2) > div .wot-title {
  color: #8e9aae;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.17;
  letter-spacing: normal;
}
