.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.daily-report-log-table colgroup > col:nth-child(1) {
  width: 10%;
}
.daily-report-log-table colgroup > col:nth-child(2) {
  width: 20%;
}
.daily-report-log-table colgroup > col:nth-child(3) {
  width: 40%;
}
.daily-report-log-table colgroup > col:nth-child(4) {
  width: 30%;
}
.sub-daily-report-log-table colgroup > col:nth-child(1) {
  width: 10%;
}
.sub-daily-report-log-table colgroup > col:nth-child(2) {
  width: 15%;
}
.sub-daily-report-log-table colgroup > col:nth-child(3) {
  width: 17%;
}
.sub-daily-report-log-table colgroup > col:nth-child(4) {
  width: 25%;
}
.sub-daily-report-log-table colgroup > col:nth-child(5) {
  width: 15%;
}
.sub-daily-report-log-table colgroup > col:nth-child(6) {
  width: 18%;
}
.done-icon {
  margin-left: 5px;
  margin-bottom: 5px;
}
