.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.punch-list-modal .modal-dialog {
  display: flex;
  justify-content: center;
  width: auto;
  max-width: 90%;
}
.punch-list-modal .modal-container {
  width: 90vw !important;
  max-width: 975px;
}
.punch-list-modal .sidebar-closed + .modal-container {
  width: calc(90vw - 106px) !important;
}
.punch-list-modal .sidebar-open .sidebar-content {
  width: 400px;
}
.punch-list-modal .sidebar-open + .modal-container {
  width: calc(90vw - 400px) !important;
}
.punch-list-modal .punch-list-body {
  padding: 0px 40px;
  overflow: auto;
  overflow: overlay;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
}
.punch-list-modal.create-punch-list .modal-dialog {
  max-width: 975px;
}
.punch-list-modal .error-message {
  margin-right: 40px;
}
