.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.image-description-upload {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.image-description-upload .single-image-upload {
  margin-right: 32px;
  margin-bottom: 24px;
  margin-top: 8px;
}
.image-description-upload .drop-zone {
  width: 250px;
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  text-align: center;
  border: 1px dashed #9ea0aa;
  cursor: pointer;
}
.image-description-upload .drop-zone img {
  margin-right: 10px;
}
.image-description-upload .drop-zone span {
  color: #454a5c;
  font-size: 16px;
  line-height: 24px;
}
.dark-mode .image-description-upload .drop-zone span {
  color: #ffffff;
}
