.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.basic-modal.form-modal.project-photos-album-form .form-header,
.basic-modal.form-modal.project-photos-album-form .form-footer {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  z-index: 11;
  width: 100%;
  background-color: #ffffff;
}
.basic-modal.form-modal.project-photos-album-form .form-header {
  padding: 0px 40px;
  top: 0;
}
.basic-modal.form-modal.project-photos-album-form .form-footer {
  bottom: 0;
}
.basic-modal.form-modal.project-photos-album-form .form-content {
  padding: 16px 40px;
}
.basic-modal.form-modal.project-photos-album-form .form-content .image-description-upload .drop-zone {
  width: 164px;
  height: 124px !important;
  background-color: #eaeaec;
}
.basic-modal.form-modal.project-photos-album-form .form-content .image-description-upload .drop-zone::after {
  content: '';
  background-image: url('../../../../../images/image.svg');
  background-repeat: no-repeat;
  background-size: 40px;
  background-position: center;
  opacity: 0.3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 0;
}
.basic-modal.form-modal.project-photos-album-form .form-content .image-description-upload .drop-zone img {
  display: none;
}
.basic-modal.form-modal.project-photos-album-form .form-content .image-description-upload .drop-zone span {
  font-size: 15px;
  color: #00b091;
}
.basic-modal.form-modal.project-photos-album-form .form-content .image-description-upload .single-image-upload {
  margin: 0;
  width: 164px;
}
.basic-modal.form-modal.project-photos-album-form .form-content .image-description-upload .single-image-upload .image-block {
  width: 164px;
  height: 124px;
  margin-bottom: 0;
}
.basic-modal.form-modal.project-photos-album-form .form-content .chars-counter-textarea .text-area {
  font-size: 16px;
  min-height: 40px;
  padding-top: 8px;
}
.basic-modal.form-modal.project-photos-album-form .form-content .chars-counter-textarea .chars-counter {
  text-align: left;
}
.basic-modal.form-modal.project-photos-album-form .form-content .multiple-dropdown .dropdown-control {
  padding-top: 12px;
}
.basic-modal.form-modal.project-photos-album-form .form-content .chips-component .chip {
  font-size: 16px;
}
.basic-modal.form-modal.project-photos-album-form .form-content .user-permissions-form .user-permissions-table {
  overflow: visible;
}
.basic-modal.form-modal.project-photos-album-form .form-content .search-bar-form .form-control {
  background-color: #f7f7f8;
}
.basic-modal.form-modal.project-photos-album-form.dark-mode .modal-content,
.basic-modal.form-modal.project-photos-album-form.dark-mode .form-header,
.basic-modal.form-modal.project-photos-album-form.dark-mode .form-footer,
.basic-modal.form-modal.project-photos-album-form.dark-mode .form-header::before,
.basic-modal.form-modal.project-photos-album-form.dark-mode .form-footer::after {
  background-color: #303441;
}
.basic-modal.form-modal.project-photos-album-form.dark-mode .form-content .image-description-upload .drop-zone {
  background-color: #3b4050;
}
.basic-modal.form-modal.project-photos-album-form.dark-mode .form-content .image-description-upload .drop-zone span {
  color: #d4fb00;
}
.basic-modal.form-modal.project-photos-album-form.dark-mode .form-content .search-bar-form .form-control {
  background-color: #252834;
}
