.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.enterprise-menu {
  color: #8e9aae;
  display: table;
  width: 100%;
}
.enterprise-menu.selected {
  background-color: #1c232a;
  color: #ffffff;
}
.enterprise-menu.toggled {
  padding-bottom: 16px;
}
.enterprise-menu .sidebar-menu-row {
  display: table-row;
}
.enterprise-menu .sidebar-menu-row .sidebar-menu-cell {
  cursor: pointer;
  display: table-cell;
  height: 55px;
  padding-left: 30px;
  vertical-align: middle;
}
.enterprise-menu .sidebar-menu-row .sidebar-menu-cell > .icon {
  display: inline-block;
  width: 20px;
}
.enterprise-menu .sidebar-menu-row .sidebar-menu-cell > .icon img {
  width: 100%;
}
.enterprise-menu .sidebar-menu-row .sidebar-menu-cell > span {
  font-size: 15px;
  font-weight: 600;
  margin-left: 15px;
  vertical-align: middle;
}
.enterprise-menu .sidebar-menu-row .sidebar-menu-cell > span.toggle {
  float: right;
  margin-right: 36px;
}
