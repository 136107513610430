.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.checkbox-container {
  cursor: pointer;
}
.checkbox-container.size-small {
  height: 16px;
  width: 16px;
}
.checkbox-container.size-medium {
  height: 18px;
  width: 18px;
}
.checkbox-container.shape-square {
  background: #9ea0aa;
}
.checkbox-container.shape-square.dark-mode {
  background: #909197;
}
.checkbox-container.shape-round {
  border: 2px solid #9ea0aa;
  border-radius: 100px;
}
.checkbox-container.shape-round.dark-mode {
  border-color: #909197;
}
.checkbox-container.shape-bell {
  background: url('./bell-inactive.svg') center no-repeat;
}
.checkbox-container.shape-bell.dark-mode {
  background: url('./bell-inactiveDarkMode.svg') center no-repeat;
}
.checkbox-container.checked.shape-square {
  background: url('./selected.svg') center no-repeat;
}
.checkbox-container.checked.shape-square.dark-mode {
  background: url('./selectedDarkMode.svg') center no-repeat;
}
.checkbox-container.checked.shape-square.dark-mode.orange {
  background: url('./selectedDarkModeBlue.svg') center no-repeat;
}
.checkbox-container.checked.shape-square.orange {
  background: url('./selectedOrange.svg') center no-repeat;
}
.checkbox-container.checked.shape-round {
  border-color: #00b091;
  background: radial-gradient(#00b091 4px, rgba(255, 0, 0, 0) 5px);
}
.checkbox-container.checked.shape-round.dark-mode {
  border-color: #d4fb00;
  background: radial-gradient(#d4fb00 4px, rgba(255, 0, 0, 0) 5px);
}
.checkbox-container.checked.shape-bell {
  background: url('./bell-active.svg') center no-repeat;
}
.checkbox-container.checked.shape-bell.dark-mode {
  background: url('./bell-activeDarkMode.svg') center no-repeat;
}
.labeled-checkbox {
  display: flex;
  align-items: center;
}
.labeled-checkbox .checkbox-label {
  width: calc(100% - 24px);
  font-size: 16px;
  line-height: 24px;
  color: #454a5c;
  cursor: pointer;
}
.labeled-checkbox .checkbox-label-readonly {
  color: #9ea0aa;
}
.labeled-checkbox > *:first-child {
  margin-right: 8px;
}
.labeled-checkbox.invalid .checkbox-label {
  color: #fb6120;
}
.labeled-checkbox.dark-mode .checkbox-label {
  color: #ffffff;
}
.labeled-checkbox.dark-mode .checkbox-label-readonly {
  color: #909197;
}
.labeled-checkbox.dark-mode.invalid .checkbox-label {
  color: #02c7ff;
}
.native-checkbox {
  display: flex;
  margin: 0;
}
.native-checkbox input {
  display: none;
}
.native-checkbox input:checked ~ .checkbox-container.shape-square {
  background: url('./selected.svg') center no-repeat;
}
.native-checkbox input:checked ~ .checkbox-container.shape-square.dark-mode {
  background: url('./selectedDarkMode.svg') center no-repeat;
}
.native-checkbox input:checked ~ .checkbox-container.shape-square.dark-mode.orange {
  background: url('./selectedDarkModeBlue.svg') center no-repeat;
}
.native-checkbox input:checked ~ .checkbox-container.shape-square.orange {
  background: url('./selectedOrange.svg') center no-repeat;
}
.native-checkbox input:checked ~ .checkbox-container.shape-round {
  border-color: #00b091;
  background: radial-gradient(#00b091 4px, rgba(255, 0, 0, 0) 5px);
}
.native-checkbox input:checked ~ .checkbox-container.shape-round.dark-mode {
  border-color: #d4fb00;
  background: radial-gradient(#d4fb00 4px, rgba(255, 0, 0, 0) 5px);
}
.native-checkbox input:checked ~ .checkbox-container.shape-bell {
  background: url('./bell-active.svg') center no-repeat;
}
.native-checkbox input:checked ~ .checkbox-container.shape-bell.dark-mode {
  background: url('./bell-activeDarkMode.svg') center no-repeat;
}
