.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.selector-modal .modal-dialog {
  top: 50%;
  transform: translateY(-50%) !important;
}
.selector-modal .modal-dialog .modal-content {
  border-radius: 0px;
  box-shadow: none;
}
.selector-modal .modal-dialog .modal-content .modal-body {
  padding: 30px 40px 30px 40px;
}
.selector-modal .modal-dialog .modal-content .modal-body > div:first-child {
  color: #3c4249;
  font-size: 24px;
  letter-spacing: -0.5px;
  line-height: 1.17;
}
.selector-modal .modal-dialog .modal-content .modal-body > div:nth-child(2) {
  margin: 16px 0;
}
.selector-modal .modal-dialog .modal-content .modal-body > div.options {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.selector-modal .modal-dialog .modal-content .modal-body > div.options .labeled-checkbox {
  margin-right: 40px;
}
.selector-modal .modal-dialog .modal-content .modal-body > div.options .labeled-checkbox:last-child {
  margin-right: 0;
}
.selector-modal .modal-dialog .modal-content .modal-body > div:last-child {
  text-align: center;
  margin-top: 32px;
}
.selector-modal .modal-dialog .modal-content .modal-body > div:last-child button {
  margin: 0 6px;
}
.dark-mode .selector-modal .modal-dialog .modal-content {
  background-color: #303441;
}
.dark-mode .selector-modal .modal-dialog .modal-content .modal-body > div:first-child,
.dark-mode .selector-modal .modal-dialog .modal-content .modal-body > div:nth-child(2) {
  color: #ffffff;
}
