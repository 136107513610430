.similar-companies-modal .modal-body > * {
  margin: 0px 40px;
}
.similar-companies-modal .modal-body .initial-paragraph {
  margin-top: 32px;
  margin-bottom: 16px;
}
.similar-companies-modal .modal-body .yes-paragraph {
  margin-bottom: 4px;
}
.similar-companies-modal .modal-body .disclaimer-paragraph {
  margin-bottom: 8px;
}
.similar-companies-modal .modal-body .no-paragraph {
  margin-bottom: 32px;
}
.similar-companies-modal .modal-body .buttons-container {
  margin-top: 40px;
  margin-bottom: 50px;
}
