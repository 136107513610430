.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.user-header {
  align-items: center;
  display: flex;
}
.user-header .user-name {
  font-size: 18px;
  line-height: normal;
  margin-left: 13px;
  margin-right: 10px;
}
.user-header .user-name > span:first-child {
  color: #ffffff;
}
.user-header .user-name > span:nth-child(2) {
  color: #d4fb00;
  margin-left: 4px;
}
.user-header .popover-arrow {
  right: 22px !important;
}
.user-options.popover-children {
  width: 296px;
}
.user-options.popover-children .menu-item {
  padding-top: 16px;
  padding-bottom: 16px;
}
.user-options.popover-children .menu-item:hover {
  background-color: #fcfcfc !important;
}
.user-options.popover-children .menu-item.account-item {
  padding-top: 12px !important;
  padding-bottom: 16px !important;
  background-color: #f7f7f8;
}
.user-options.popover-children .menu-item.account-item .account-name {
  font-weight: 600;
}
.user-options.popover-children .menu-item.account-item .account-type {
  font-size: 12px;
  line-height: 18px;
}
.user-options.popover-children .menu-item.account-item .account-address {
  font-size: 12px;
  line-height: 18px;
  color: #9ea0aa;
}
.user-options.popover-children .menu-item.account-item.selected .account-name {
  font-weight: bold;
  color: #00b091;
}
.user-options.popover-children .menu-item.account-item:hover {
  background-color: #f3f3f3 !important;
}
.user-options.popover-children .menu-item-separator {
  position: relative;
  top: 0px;
  left: -2px;
}
.dark-mode .user-options.popover-children .menu-item:hover {
  background-color: #363a46 !important;
}
.dark-mode .user-options.popover-children .menu-item.account-item {
  background-color: #303441;
}
.dark-mode .user-options.popover-children .menu-item.account-item .account-address {
  color: #909197;
}
.dark-mode .user-options.popover-children .menu-item.account-item.selected .account-name {
  color: #d4fb00;
}
.dark-mode .user-options.popover-children .menu-item.account-item:hover {
  background-color: #363a46 !important;
}
