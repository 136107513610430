.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.rfi-questions {
  color: #454a5c;
}
.rfi-questions .rfi-title {
  margin-top: 40px;
  margin-bottom: 8px;
  padding: 20px 0px;
}
.rfi-questions .rfi-title .details-title-text {
  font-size: 29px;
  line-height: 46px;
  font-weight: 600;
}
.rfi-questions .rfi-questions {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}
.rfi-questions .rfi-questions .tomato-row {
  color: #fb6120;
  font-weight: bold;
}
.rfi-questions.dark-mode {
  color: #ffffff;
}
.rfi-questions.dark-mode .rfi-questions-table .tomato-row {
  color: #02c7ff;
}
