.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.simple-pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.simple-pagination .pagination {
  margin: 0px;
}
.simple-pagination .pagination li span,
.simple-pagination .pagination li a {
  margin: 0px;
  margin-left: 6px !important;
  margin-right: 6px;
  margin-bottom: 8px;
  padding: 0px;
  padding-top: 19px;
  font-size: 16px;
  line-height: 24px;
  color: #454a5c !important;
  background-color: unset !important;
  border: 0;
}
.simple-pagination .pagination li.pagination-prev span,
.simple-pagination .pagination li.pagination-next span,
.simple-pagination .pagination li.pagination-prev a,
.simple-pagination .pagination li.pagination-next a {
  padding-top: 16px;
  padding-left: 9px;
  padding-right: 9px;
  font-size: 32px;
  line-height: 24px;
}
.simple-pagination .pagination li.pagination-prev.disabled span,
.simple-pagination .pagination li.pagination-next.disabled span,
.simple-pagination .pagination li.pagination-prev.disabled a,
.simple-pagination .pagination li.pagination-next.disabled a {
  opacity: 0.3;
  cursor: default;
}
.simple-pagination .pagination li.active span {
  color: #00b091 !important;
  border-bottom: 1px solid #00b091;
}
.simple-pagination .go-to-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
  border-bottom: 1px solid #7a7e8b;
  padding-left: 8px;
  padding-right: 16px;
}
.simple-pagination .go-to-page .go-to-page-title {
  margin: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #7a7e8b;
}
.simple-pagination .go-to-page .go-to-page-body {
  display: flex;
  padding-bottom: 8px;
}
.simple-pagination .go-to-page .go-to-page-body .go-to-page-textbox {
  width: 36px;
  padding: 0px;
  border: none;
  background: unset;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #454a5c;
}
.simple-pagination .go-to-page .go-to-page-body .go-to-page-button {
  background: unset;
  border: none;
  margin-top: -4px;
  padding: 0px;
  font-size: 32px;
  line-height: 24px;
  color: #7a7e8b;
}
.simple-pagination.dark-mode .pagination li span,
.simple-pagination.dark-mode .pagination li a {
  color: #ffffff !important;
}
.simple-pagination.dark-mode .pagination li.active span {
  color: #d4fb00 !important;
  border-bottom: 1px solid #d4fb00;
}
.simple-pagination.dark-mode .go-to-page {
  border-bottom: 1px solid #bcbdc1;
}
.simple-pagination.dark-mode .go-to-page .go-to-page-title {
  color: #bcbdc1;
}
.simple-pagination.dark-mode .go-to-page .go-to-page-body .go-to-page-textbox {
  color: #ffffff;
}
.simple-pagination.dark-mode .go-to-page .go-to-page-body .go-to-page-button {
  color: #bcbdc1;
}
