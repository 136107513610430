.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.resolved-report-item .text-color {
  color: #454a5c;
}
.resolved-report-item .label-section {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.resolved-report-item .label-section .label {
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
  padding: 0px;
  margin-right: 12px;
}
.resolved-report-item .label-section .value {
  font-size: 16px;
  font-weight: 400;
  margin-right: 60px;
  opacity: 0.7;
}
.resolved-report-item .remark-section {
  display: flex;
  flex-direction: column;
  margin: 0px 7px 30px 7px;
}
.resolved-report-item .remark-section .label-remark {
  font-size: 12px;
  font-weight: 400;
  color: #00b091;
}
.resolved-report-item .remark-section .value-remark {
  font-size: 16px;
  font-weight: 400;
}
.dark-mode .resolved-report-item .text-color {
  color: #ffffff;
}
.dark-mode .resolved-report-item .label-remark {
  color: #d4fb00;
}
