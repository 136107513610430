.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.pdf-viewer-modal .modal-dialog {
  width: 95%;
  height: 93%;
}
.pdf-viewer-modal .modal-dialog .modal-content {
  height: 100%;
}
.pdf-viewer-modal .modal-dialog .modal-content .modal-body {
  height: 93%;
}
.pdf-viewer-modal .modal-dialog .modal-content .modal-body .pdf-viewer-iframe {
  border: 1px solid #edf1f7;
}
.pdf-viewer-modal .modal-dialog .modal-content .modal-body .pdf-web-viewer {
  height: 100%;
}
.pdf-viewer-modal .modal-dialog .modal-content .modal-header {
  display: flex;
  justify-content: space-between;
}
.pdf-viewer-modal .modal-dialog .modal-content .modal-header > div:first-child {
  flex: 1;
}
.pdf-viewer-modal .modal-dialog .modal-content .modal-header .bluebeam-link {
  padding: 10px;
}
.pdf-viewer-modal .modal-dialog .modal-content .modal-header input.button {
  margin-right: 10px;
}
.pdf-viewer-modal .modal-dialog .modal-content .modal-header p.pdfTronMessage {
  float: left;
  padding: 10px;
  margin: 0px;
}
.pdf-viewer-modal .modal-dialog .modal-content .modal-header p.pdfTronSuccess {
  color: #5ec452;
}
.pdf-viewer-modal .modal-dialog .modal-content .modal-header p.pdfTronError {
  color: #fb6120;
}
