.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.meeting-items {
  height: 80vh;
  padding: 0px 16px;
  overflow: auto;
  overflow: overlay;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
}
.meeting-items-table th:first-child,
.meeting-items-table td:first-child {
  padding-left: 23px;
}
.meeting-items-table th:last-child,
.meeting-items-table td:last-child {
  padding-right: 23px;
}
.meeting-items-table th.td-empty-table,
.meeting-items-table td.td-empty-table {
  height: auto;
}
.meeting-items-table th span,
.meeting-items-table td span {
  white-space: pre-wrap;
}
.meeting-items-table colgroup > col:nth-child(1) {
  width: 5%;
}
.meeting-items-table colgroup > col:nth-child(2) {
  width: 10%;
}
.meeting-items-table colgroup > col:nth-child(3) {
  width: 60%;
}
.meeting-items-table colgroup > col:nth-child(4) {
  width: 25%;
}
.meeting-items-table .meeting-item-new {
  font-weight: bold;
}
.meeting-items-table textarea {
  width: 100%;
}
.meeting-items-table .searchable-dropdown .Select.is-open .Select-control,
.meeting-items-table .searchable-dropdown .Select.is-focused .Select-control {
  background: transparent;
}
.meeting-items-table .searchable-dropdown .Select-control {
  background: transparent;
  border: none;
}
.meeting-items-table .contributor-selected {
  border-radius: 10px;
  background-color: #f7f7f8;
  cursor: pointer;
  margin-bottom: 8px;
  padding: 2px 8px;
}
.meeting-items-table .contributor-selected .remove {
  color: #fb6120;
  font-size: 15px;
  font-weight: bold;
  padding-left: 5px;
  padding-right: 10px;
}
.meeting-items-table .resolved-label {
  color: #00b091;
  font-size: 13px;
  font-weight: bold;
}
.meeting-items-table .menu-old .popover-children {
  width: 240px !important;
}
.meeting-items-table .menu-old .popover-children .text-area {
  margin-top: 16px;
}
.meeting-items-table .menu-old .popover-children .settings-menu-buttons {
  margin-top: 16px;
  display: flex;
}
.meeting-items-table .menu-old .popover-children .settings-menu-buttons > *:not(:first-child) {
  margin-left: 16px;
}
.meeting-items-table.dark-mode .contributor-selected {
  background-color: #3b4050;
}
.meeting-items-table.dark-mode .contributor-selected .remove {
  color: #02c7ff;
}
.meeting-items-table.dark-mode .resolved-label {
  color: #d4fb00;
}
