.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.chat {
  text-align: left;
}
.chat .sidebar-content {
  width: 480px;
}
.chat .chat-body {
  display: flex;
  flex-direction: column;
  min-height: 160px;
  max-height: 30vh;
  margin: 16px -24px;
  padding: 8px 24px;
  overflow: auto;
  overflow: overlay;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
}
.chat .chat-body .no-comments {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 23px;
  line-height: 38px;
  text-align: center;
  color: #454a5c;
  opacity: 0.4;
}
.chat .chat-body .chat-message {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  max-width: calc(100% - 32px);
  color: #454a5c;
}
.chat .chat-body .chat-message:not(:first-child) {
  margin-top: 32px;
}
.chat .chat-body .chat-message .chat-message-content {
  padding: 0px 24px 16px 24px;
  background-color: #eaeaec;
}
.chat .chat-body .chat-message .chat-message-content .chat-message-header {
  display: flex;
  align-items: center;
  height: 32px;
}
.chat .chat-body .chat-message .chat-message-content .chat-message-header .chat-message-name {
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  margin-right: 16px;
}
.chat .chat-body .chat-message .chat-message-content .chat-message-header .chat-message-date {
  font-size: 12px;
  line-height: 18px;
  opacity: 0.7;
  margin-left: auto;
}
.chat .chat-body .chat-message .chat-message-content .chat-message-text {
  font-size: 14px;
  line-height: 18px;
}
.chat .chat-body .chat-message .chat-message-content .chat-attachments {
  margin-top: 8px;
}
.chat .chat-body .chat-message .chat-message-content .chat-attachments .document-link-label a {
  font-size: 14px;
}
.chat .chat-body .chat-message .chat-message-content .chat-images {
  display: flex;
  flex-wrap: wrap;
}
.chat .chat-body .chat-message .chat-message-arrow {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 16px solid #eaeaec;
  margin-top: -4px;
  margin-left: 16px;
}
.chat .chat-body .chat-message .document-link-image {
  filter: brightness(0);
}
.chat .chat-body .chat-message.outgoing-message {
  align-self: flex-end;
  color: #ffffff;
}
.chat .chat-body .chat-message.outgoing-message .chat-message-content {
  background-color: #00b091;
}
.chat .chat-body .chat-message.outgoing-message .chat-message-arrow {
  border-top: 16px solid #00b091;
  margin-right: 16px;
  margin-left: auto;
}
.chat .chat-body .chat-message.outgoing-message .document-link-image {
  filter: brightness(0) invert(1);
}
.chat .chat-body .chat-message.outgoing-message .document-link-label a {
  color: #ffffff;
}
.chat .chat-textbox {
  margin-top: 16px;
}
.chat .document-upload {
  margin-top: 16px;
}
.chat .chat-upload-links > * {
  margin-top: 8px;
}
.chat .image-upload {
  margin-top: 16px;
}
.chat .send-button {
  width: 150px;
  margin-top: 16px;
  margin-left: auto;
}
.chat.dark-mode .chat-body .no-comments {
  color: #ffffff;
}
.chat.dark-mode .chat-body .chat-message {
  color: #ffffff;
}
.chat.dark-mode .chat-body .chat-message .chat-message-content {
  background-color: #43495a;
}
.chat.dark-mode .chat-body .chat-message .chat-message-arrow {
  border-top: 16px solid #43495a;
}
.chat.dark-mode .chat-body .chat-message .document-link-image {
  filter: brightness(0) invert(1);
}
.chat.dark-mode .chat-body .chat-message.outgoing-message {
  align-self: flex-end;
  color: #454a5c;
}
.chat.dark-mode .chat-body .chat-message.outgoing-message .chat-message-content {
  background-color: #f7f7f8;
}
.chat.dark-mode .chat-body .chat-message.outgoing-message .chat-message-arrow {
  border-top: 16px solid #f7f7f8;
}
.chat.dark-mode .chat-body .chat-message.outgoing-message .document-link-image {
  filter: brightness(0);
}
.chat.dark-mode .chat-body .chat-message.outgoing-message .document-link-label a {
  color: #454a5c;
}
