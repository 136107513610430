.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.project-dashboard {
  height: calc(100% - 111px - 6em);
  width: calc(100% - 2em);
  display: grid;
  grid-template-columns: 50% repeat(2, 25%);
  grid-template-rows: repeat(2, calc(200% / 7)) repeat(3, calc(100% / 7));
  grid-auto-flow: column;
  gap: 1em;
}
.project-dashboard > * {
  background-color: #f7f7f8;
}
.project-dashboard .project-dashboard-photos {
  grid-column: 1/2;
  grid-row: 1/3;
}
.project-dashboard .project-dashboard-budget {
  grid-column: 1/2;
  grid-row: 3/6;
}
.project-dashboard .project-dashboard-project-reports {
  grid-column: 2/3;
  grid-row: 1/2;
}
.project-dashboard .project-dashboard-construction-complete {
  grid-column: 2/3;
  grid-row: 2/3;
}
.project-dashboard .project-dashboard-project-completion {
  grid-column: 2/3;
  grid-row: 3/4;
}
.project-dashboard .project-dashboard-current-budget {
  grid-column: 2/3;
  grid-row: 4/5;
}
.project-dashboard .project-dashboard-paid-to-date {
  grid-column: 2/3;
  grid-row: 5/6;
}
.project-dashboard .project-dashboard-shared-folders {
  grid-column: 3/4;
  grid-row: 1/4;
}
.project-dashboard .project-dashboard-construction-current-budget {
  grid-column: 3/4;
  grid-row: 4/5;
}
.project-dashboard .project-dashboard-construction-paid-to-date {
  grid-column: 3/4;
  grid-row: 5/6;
}
.project-dashboard.dark-mode > * {
  background-color: #21242f;
}
