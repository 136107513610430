.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.navigation-bar {
  margin-bottom: 8px;
  padding-bottom: 2px;
}
.navigation-bar .navigation-item {
  display: inline-block;
  font-size: 23px;
  line-height: 38px;
  cursor: pointer;
  color: #9ea0aa;
}
.navigation-bar .navigation-item:not(:last-child) {
  margin-right: 40px;
}
.navigation-bar .navigation-item.selected {
  color: #454a5c;
  border-bottom: 2px solid #fb6120;
}
.navigation-bar .navigation-item .navigation-item-total {
  margin-left: 10px;
  font-weight: bold;
  font-size: 14px;
}
.navigation-bar.dark-mode .navigation-item {
  color: rgba(255, 255, 255, 0.5);
}
.navigation-bar.dark-mode .navigation-item.selected {
  color: #ffffff;
  border-bottom: 2px solid #02c7ff;
}
