.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.field-report-form {
  height: calc(100vh - 275px);
}
.field-report-form .button.button-transparent {
  background-color: transparent;
}
.field-report-form .sidebar-form-sidebar {
  height: auto;
}
.field-report-form .sidebar-form-title {
  margin-bottom: 25px;
}
.field-report-form .sidebar-form-body {
  height: auto;
  overflow-x: hidden;
}
.field-report-form .form-block {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 40px !important;
}
.field-report-form .form-block .form-label {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 10px;
  margin-right: 8px;
  color: #454a5c;
}
.field-report-form .form-block small {
  color: #454a5c;
  font-size: 12px;
  opacity: 0.75;
  margin-bottom: 10px;
}
.field-report-form .form-block .select-label .filter-header-value {
  font-size: 14px;
  font-weight: 600;
  color: #454a5c;
}
.field-report-form .form-block .select-label .header-label-icon {
  width: 15px !important;
  height: 9px !important;
  margin-left: 6px;
  margin-top: -2px;
}
.field-report-form .form-block .select-label .menu-item {
  white-space: nowrap;
}
.field-report-form .form-block .select-label .menu-item.selected {
  color: #7a7e8b !important;
  font-weight: 600;
}
.field-report-form .form-block .select-label .menu-item.selected + .menu-item-separator {
  border-bottom: 1px solid #eaeaec !important;
}
.field-report-form .form-block .chars-counter-textarea .chars-counter {
  text-align: left;
}
.field-report-form .form-block .add-button-label {
  display: flex;
  align-items: center;
}
.field-report-form .form-block .add-button-label .add-icon {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}
.field-report-form .form-block .inline-block .chars-counter-textarea .text-area {
  min-height: 41px;
  padding-top: 8px;
  font-size: 16px;
  line-height: 24px;
}
.field-report-form .form-block .load-prev-report {
  margin-top: 0;
}
.field-report-form .form-block .date-picker {
  max-width: 170px;
}
.field-report-form .form-block.small-margin {
  margin-top: 8px;
  margin-bottom: 10px !important;
}
.field-report-form .form-block.location-block {
  margin-bottom: 56px !important;
}
.field-report-form .form-block.location-block .form-label {
  margin-bottom: 16px;
}
.field-report-form .form-block.location-block .map-container {
  height: 290px;
}
.field-report-form .form-block.weather-block .input-condition,
.field-report-form .form-block.weather-block .input-precipitation,
.field-report-form .form-block.weather-block .input-wind,
.field-report-form .form-block.weather-block .input-humidity,
.field-report-form .form-block.weather-block .input-temperature,
.field-report-form .form-block.weather-block .input-wind-direction {
  flex-basis: 25%;
}
.field-report-form .form-block.weather-block .input-weather-remarks {
  flex-basis: 53%;
}
.field-report-form .form-block.weather-block .input-condition,
.field-report-form .form-block.weather-block .input-precipitation {
  min-width: 160px;
}
.field-report-form .form-block.weather-block .input-wind-direction {
  min-width: 140px;
}
.field-report-form .form-block.weather-block .weather-label {
  display: flex;
  align-items: center;
}
.field-report-form .form-block.weather-block .weather-label .link-button {
  margin-top: 0;
  margin-bottom: 10px;
}
.field-report-form .form-block.description-block {
  margin-bottom: 0 !important;
}
.field-report-form .form-block.description-block .description-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.field-report-form .form-block.description-block .description-label label {
  flex: 1;
}
.field-report-form .form-block.description-block .description-label button {
  margin-top: 0;
  margin-bottom: 10px;
}
.field-report-form .form-block.observation-block {
  margin-top: 4px;
  margin-bottom: 10px !important;
}
.field-report-form .form-block.observation-block .input-trade-count,
.field-report-form .form-block.observation-block .input-contract,
.field-report-form .form-block.observation-block .input-schedule,
.field-report-form .form-block.observation-block .input-design,
.field-report-form .form-block.observation-block .input-quality,
.field-report-form .form-block.observation-block .input-safety {
  width: 165px;
  flex-shrink: 0;
}
.field-report-form .form-block .document-upload {
  max-width: 250px;
  margin-bottom: 12px;
}
.field-report-form .form-block .document-upload-previews {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.field-report-form .form-block .document-upload-previews .document-link {
  width: 30%;
  margin-right: 5%;
  margin-top: 10px;
}
.field-report-form .form-block .document-upload-previews .document-link:nth-child(3n) {
  margin-right: 0;
}
.field-report-form .form-block .document-upload-previews .document-link .document-link-label {
  flex: 1;
}
.field-report-form .form-block .previus-container button {
  margin-top: 0px;
}
.field-report-form .form-block .disclaimer-remark .chars-counter-textarea .text-area {
  max-height: 45vh !important;
}
.field-report-form .form-block-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
}
.field-report-form .form-block-container > .form-block {
  margin-right: 60px;
}
.field-report-form .form-block-container > .form-block:last-child {
  margin-right: 0;
}
.field-report-form .form-block-container.trade-block {
  align-items: center;
  justify-content: space-between;
}
.field-report-form .form-block-container.trade-block > :first-child {
  flex: 1;
}
.field-report-form .form-block-container.trade-block button {
  margin-left: 16px;
}
.field-report-form .form-control-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
}
.field-report-form .form-control-container.weather {
  margin-right: 0;
  margin-bottom: 20px;
}
.field-report-form .form-control-container.observation {
  margin-top: 0px;
  margin-bottom: 16px;
  margin-right: 0 !important;
}
.field-report-form .open-items-field-report th:first-child,
.field-report-form .open-items-field-report td:first-child {
  padding-left: 0;
}
.field-report-form .open-items-field-report th:nth-child(2),
.field-report-form .open-items-field-report td:nth-child(2) {
  width: 250px;
}
.dark-mode .field-report-form .form-block .form-label {
  color: #ffffff;
}
.dark-mode .field-report-form .form-block small {
  color: #ffffff;
}
.dark-mode .field-report-form .form-block .select-label .filter-header-value {
  color: #ffffff;
}
.dark-mode .field-report-form .form-block .select-label .menu-item.selected {
  color: #ffffff !important;
}
.dark-mode .field-report-form .form-block .select-label .menu-item.selected + .menu-item-separator {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05) !important;
}
