.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.rfa-form {
  padding: 16px 40px 20px;
  overflow: auto;
  overflow: overlay;
}
.rfa-form .form-block-container {
  display: flex;
}
.rfa-form .form-block-container .form-block {
  margin-right: 32px;
}
.rfa-form .form-block-container .form-block:last-child {
  margin-right: 0;
}
.rfa-form .form-block-container .form-block.grow-block {
  flex-grow: 1;
}
.rfa-form .form-block-container .form-block.half-block {
  width: 50%;
}
.rfa-form .form-block-container.documents-block {
  margin-top: 40px;
}
.rfa-form .form-control-block.just-button .form-control-label,
.rfa-form .form-control-block.just-button .input-area {
  display: none;
}
.rfa-form .form-control-block.just-button button {
  margin-top: 0;
  margin-left: 8px;
}
.rfa-form h3 {
  font-weight: 600;
  margin-bottom: 24px;
}
.rfa-form h4 {
  font-weight: 600;
  margin-bottom: 24px;
  margin-top: 24px;
}
.rfa-form h4.tomato {
  color: #fb6120;
}
.rfa-form .rfa-resume-status {
  margin-bottom: 18px;
  margin-left: 8px;
}
.rfa-form .select-dropdown {
  min-width: 160px;
}
.rfa-form .due-date.readonly {
  max-width: 120px;
}
.rfa-form .due-date .rdtPicker {
  right: 0;
}
.rfa-form .note {
  font-size: 12px;
  line-height: 1rem;
  font-style: italic;
}
.rfa-form .request-textarea,
.rfa-form .recipients-dropdown,
.rfa-form .image-upload,
.rfa-form .document-upload-container {
  margin-top: 12px;
}
.rfa-form .image-upload,
.rfa-form .document-upload-container {
  margin-left: 8px;
}
.rfa-form .document-upload-container {
  max-width: 65%;
}
.rfa-form .approve-options {
  max-width: 500px;
  margin: 8px 10px 32px;
}
.rfa-form .approve-options.radio-button .radiobutton-control {
  margin-top: 2px;
}
.rfa-form .chars-counter-textarea.title .text-area {
  font-size: 16px;
  line-height: 16px;
  height: 40px !important;
  min-height: 40px !important;
  padding-top: 9px;
}
.rfa-form .chars-counter-textarea.title .chars-counter {
  text-align: left;
}
.rfa-actions .error-message {
  margin-right: 40px;
  margin-top: 20px;
  margin-bottom: 6px;
}
.rfa-actions .rfa-form-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rfa-actions .rfa-form-actions .button {
  margin: 16px 8px;
}
.rfa-actions .rfa-form-actions .button:first-child {
  margin-left: 0;
}
.rfa-actions .rfa-form-actions .button:last-child {
  margin-right: 0;
}
.dark-mode .rfa-form h3,
.dark-mode .rfa-form h4:not(.tomato),
.dark-mode .rfa-form .rfa-resume-status p {
  color: #ffffff;
}
