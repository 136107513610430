.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.project-site-area-selector .filter-header-value {
  font-size: 14px;
  font-weight: 600;
  color: #454a5c;
}
.project-site-area-selector .header-label-icon {
  width: 15px !important;
  height: 9px !important;
  margin-left: 6px;
  margin-top: -2px;
}
.project-site-area-selector .menu-item {
  white-space: nowrap;
}
.project-site-area-selector .menu-item.selected {
  color: #7a7e8b !important;
  background-color: #eaeaec;
  font-weight: 600;
}
.project-site-area-selector .menu-item.selected + .menu-item-separator {
  border-bottom: 1px solid #eaeaec !important;
}
.project-site-area-selector .menu-item .secondary {
  color: #fb6120;
}
.dark-mode .project-site-area-selector .filter-header-value {
  color: #ffffff;
}
.dark-mode .project-site-area-selector .menu-item.selected {
  color: #ffffff !important;
  background-color: #43495a;
}
.dark-mode .project-site-area-selector .menu-item.selected + .menu-item-separator {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05) !important;
}
.dark-mode .project-site-area-selector .menu-item .secondary {
  color: #02c7ff;
}
