.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.terms-modal .terms_conditions_buttons {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 80px;
  justify-content: space-between;
  width: 100%;
}
.terms-modal .modal-dialog {
  top: 50%;
  transform: translateY(-50%) !important;
  width: 50%;
}
.terms-modal .modal-dialog .modal-content {
  border-radius: 8px;
  box-shadow: none;
  text-align: center;
}
.terms-modal .modal-dialog .modal-content .modal-body > div:first-child {
  color: #3c4249;
  font-size: 24px;
  letter-spacing: -0.5px;
  line-height: 1.17;
}
.terms-modal .modal-dialog .modal-content .modal-body > div:last-child {
  margin-top: 25px;
}
.terms-modal .modal-dialog .modal-content .modal-body > div:last-child > input {
  height: 45px;
  width: 140px;
}
.terms-modal .modal-dialog .modal-content .modal-body > div:last-child > input:first-child {
  margin-right: 10px;
}
