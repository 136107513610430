.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.next-meeting-modal .modal-dialog {
  width: 600px;
}
.next-meeting-modal .modal-dialog .modal-content {
  background-color: #f7f7f8;
  width: 600px;
}
.next-meeting-modal .modal-dialog .modal-content .modal-container .modal-body {
  overflow: visible;
  position: static;
  padding: 0 40px;
  padding-bottom: 90px;
}
.next-meeting-modal .modal-dialog .modal-content .modal-container .modal-body .messages-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 32px 40px;
  font-size: 14px;
  line-height: 30px;
  color: #454a5c;
}
.next-meeting-modal .modal-dialog .modal-content .modal-container .modal-body .messages-container .exclamation {
  margin: 16px 0px 24px 0px;
  width: 10px;
}
.next-meeting-modal .modal-dialog .modal-content .modal-container .modal-body .general-info {
  padding: 24px 0;
}
.next-meeting-modal .modal-dialog .modal-content .modal-container .modal-body .general-info .form-control-label {
  font-size: 14px;
  margin-bottom: -2px;
}
.next-meeting-modal .modal-dialog .modal-content .modal-container .modal-body .general-info .details-info-item {
  padding: 0 8px;
}
.next-meeting-modal .modal-dialog .modal-content .modal-container .modal-body .general-info .input-type {
  margin-top: 20px;
}
.next-meeting-modal .modal-dialog .modal-content .modal-container .modal-body .general-info .duplicate-input {
  margin: 24px 0 28px;
}
.next-meeting-modal .modal-dialog .modal-content .modal-container .modal-body .user-permissions-form {
  margin-top: 20px;
}
.next-meeting-modal .modal-dialog .modal-content .modal-container .modal-body .button-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: -1px;
  left: 0;
  height: 90px;
  background-color: #f7f7f8;
  gap: 40px;
}
.next-meeting-modal .modal-dialog .modal-content .modal-container .modal-body .button-actions .button:first-child {
  margin-right: 16px;
}
.next-meeting-modal .modal-dialog .modal-content .modal-container .modal-body .button-actions .button.button-white {
  background-color: transparent;
}
.field-and-label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.description-text {
  font-size: 16px;
  line-height: 24px;
}
.help-text {
  font-size: 16px;
  line-height: 24px;
  font-style: italic;
  margin-top: 30px;
  margin-bottom: 50px;
}
.radio-buttons-container {
  display: flex;
  flex-direction: row;
  gap: 80px;
}
.time-container {
  margin-bottom: 15px;
  padding-bottom: 10px;
  width: 180px;
}
.dark-mode .description-text {
  color: white;
}
.dark-mode .help-text {
  color: white;
}
.dark-mode .button-actions {
  background-color: #303441 !important;
}
