.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.security-cameras-content-container {
  display: flex;
  flex-direction: column;
  /* Ensure stacking layout */
}
.security-cameras-content-container .empty-security-cameras {
  width: 100%;
  text-align: center;
  margin-top: 80px;
  font-size: 34px;
}
.security-cameras-content-container .security-cameras {
  width: 100%;
  padding: 0px 24px;
  display: grid;
}
.security-cameras-content-container .cameras-iframe {
  min-height: 900px;
  border: 1px solid #edf1f7;
  width: 100%;
  /* Ensure full width */
}
.security-cameras-content-container.dark-mode .empty-security-cameras {
  color: #ffffff;
}
