.rfa-log {
  height: calc(100% - 94px);
}
.rfa-log .rfa-log-table {
  margin-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}
.rfa-log-header {
  margin-left: 20px !important;
}
.rfa-log-content-container {
  background-color: #f7f7f8;
}
.dark-mode .rfa-log-content-container {
  background-color: rgba(69, 74, 92, 0.2);
}
