.tooltip-button {
  cursor: pointer;
}
.tooltip-button.tooltip-button-size-small {
  width: 16px;
  height: 16px;
}
.tooltip-button.tooltip-button-size-small.tooltip-button-position-top {
  margin-bottom: 8px;
}
.tooltip-button.tooltip-button-size-small.tooltip-button-position-bottom {
  margin-top: 8px;
}
.tooltip-button.tooltip-button-size-medium {
  width: 24px;
  height: 24px;
}
.tooltip-button.tooltip-button-size-medium.tooltip-button-position-top {
  margin-bottom: 12px;
}
.tooltip-button.tooltip-button-size-medium.tooltip-button-position-bottom {
  margin-top: 12px;
}
.tooltip-button.tooltip-button-size-big {
  width: 32px;
  height: 32px;
}
.tooltip-button.tooltip-button-size-big.tooltip-button-position-top {
  margin-bottom: 16px;
}
.tooltip-button.tooltip-button-size-big.tooltip-button-position-bottom {
  margin-top: 16px;
}
