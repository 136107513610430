.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.download-objects-modal .modal-dialog {
  width: 70%;
}
.download-objects-modal .download-objects-container {
  margin: 24px 40px;
}
.download-objects-modal .download-objects-container .text-area {
  height: 200px;
}
.download-objects-modal .buttons-container {
  margin-bottom: 50px;
}
