.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.company-onboarding {
  display: flex;
  flex-direction: column;
  background-color: none;
  text-align: center;
  color: #454a5c;
}
.company-onboarding .license-number-input {
  flex: 1;
  padding-top: 35px;
}
.company-onboarding .company-logo-upload {
  flex: 1;
  padding-top: 35px;
}
.company-onboarding .onboarding-title {
  margin-top: 90px;
  font-weight: 100;
}
.company-onboarding .selection-title {
  margin-bottom: 20px;
}
.company-onboarding .button {
  margin-top: 49px;
}
.company-onboarding .add-message {
  margin-top: 7px;
  width: 100%;
  height: 23px;
  opacity: 0.5;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
.company-onboarding .details-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 55px;
  text-align: center;
}
.company-onboarding .details-form .onboarding-input {
  width: 373px;
}
.company-onboarding .dropzone-container {
  border-radius: 4px;
  border: dotted 2px #d5d8de;
  padding: 60px;
  padding-top: 55px;
  width: 421px;
  height: 160px;
}
.company-onboarding .dropzone-container .logo-upload-row {
  width: 100%;
  height: 100%;
}
.company-onboarding .dropzone-container .logo-upload-row .files-dropzone {
  width: 100%;
  height: 100%;
}
.company-onboarding .image-container {
  border: none;
}
.company-onboarding.dark-mode {
  color: #ffffff;
}
