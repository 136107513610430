.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.image-upload .image-upload-button-container {
  display: table;
}
.image-upload .image-upload-button-container > div {
  display: table-cell;
}
.image-upload .image-upload-button-container > div:nth-child(2) {
  vertical-align: top;
}
.image-upload .image-upload-button-container > div ul {
  list-style: none;
  margin: 0;
  padding-left: 20px;
}
.image-upload .image-upload-button-container > div ul > li {
  color: #9fa9ba;
  font-size: 14px;
}
.image-upload .preview-container {
  display: flex;
  flex-wrap: wrap;
}
.image-upload .preview-container > div {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0px 8px 8px 0px;
}
.image-upload .preview-container > div > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
.image-upload .preview-container > div:not(:hover) .remove-container {
  display: none;
}
.image-upload .preview-container > div:hover .remove-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 10px;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
}
.image-upload .preview-container > div:hover .remove-container img {
  cursor: pointer;
  width: 14px;
  filter: brightness(0);
}
.image-upload .preview-container .img-full-screen {
  cursor: zoom-in;
}
.image-upload.dark-mode .remove-image {
  filter: brightness(0) invert(1);
}
.image-upload-edit-form .modal-body {
  padding: 0px 40px 50px 40px !important;
}
.rotation-button-image-viewer {
  width: 20px;
  height: 20px;
  margin-right: 15px;
  opacity: 0.8;
  cursor: pointer;
  border: none;
}
.rotation-button-image-viewer:hover {
  opacity: 1;
}
