.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.rfi-addl-assignees {
  margin-top: 8px;
  padding: 0px 8px;
}
.rfi-addl-assignees .simple-table.rfi-addl-assignees-table {
  width: unset;
}
.rfi-addl-assignees .simple-table.rfi-addl-assignees-table tr {
  margin-top: 8px;
}
.rfi-addl-assignees .simple-table.rfi-addl-assignees-table tr td {
  padding: 0px;
  border: none;
}
.rfi-addl-assignees .simple-table.rfi-addl-assignees-table tr td > * {
  margin-top: 8px;
}
.rfi-addl-assignees .simple-table.rfi-addl-assignees-table tr td:not(:first-child) {
  padding-left: 48px;
}
.rfi-addl-assignees .simple-table.rfi-addl-assignees-table tr td .form-block-text {
  min-height: unset;
  padding-top: 0px;
}
.rfi-addl-assignees .simple-table.rfi-addl-assignees-table tr td .link-button {
  margin-top: 0px;
}
.rfi-addl-assignees .simple-table.rfi-addl-assignees-table tr td .rfi-addl-assignee {
  display: flex;
}
.rfi-addl-assignees .simple-table.rfi-addl-assignees-table tr td .rfi-addl-assignee-data {
  display: flex;
}
.rfi-addl-assignees .simple-table.rfi-addl-assignees-table tr td .rfi-addl-assignee-data .assignee-date {
  margin-left: auto;
  font-weight: 600;
}
.rfi-addl-assignees .simple-table.rfi-addl-assignees-table tr td .rfi-addl-assignee-actions {
  display: flex;
}
.rfi-addl-assignees .add-assignee-button {
  margin: 16px 0px 0px 0px !important;
}
