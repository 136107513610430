.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.field-work-order-modal .modal-dialog {
  width: 80%;
  max-width: 975px;
}
@media (max-width: 1400px) {
  .field-work-order-modal .modal-dialog {
    width: 90%;
  }
}
.field-work-order-modal .field-work-order-body {
  padding: 0px 40px;
  overflow: auto;
  overflow: overlay;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
}
.field-work-order-modal .error-message {
  margin: 0px 40px;
}
.field-work-order-modal .field-work-order-actions {
  margin-bottom: 24px;
}
.field-work-order-modal .work-order-status-block > *:first-child:last-child {
  width: 50%;
}
