.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.project-open-items-modal .modal-dialog {
  width: 80% !important;
}
.project-open-items-modal .modal-dialog .modal-body {
  padding: 16px 16px 40px 16px !important;
}
