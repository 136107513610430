.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.main {
  background: #ffffff;
  bottom: 0;
  position: absolute;
  top: 80px;
  width: 100%;
}
