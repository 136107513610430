.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.submittal-log-table .csi-code-row td,
.submittal-lead-time-log-table .csi-code-row td {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
  text-transform: uppercase;
  font-weight: 600;
  background-color: #303441;
  color: rgba(255, 255, 255, 0.5);
  border: none;
}
.submittal-log-table .rate-title,
.submittal-lead-time-log-table .rate-title,
.submittal-log-table .rate-items,
.submittal-lead-time-log-table .rate-items {
  width: 212px;
}
.trade-code-header .popover-children {
  min-width: 280px !important;
}
.submittal-log-table colgroup > col:nth-child(1) {
  width: 16%;
}
.submittal-log-table colgroup > col:nth-child(2) {
  width: 16%;
}
.submittal-log-table colgroup > col:nth-child(3) {
  width: 10%;
}
.submittal-log-table colgroup > col:nth-child(4) {
  width: 6%;
}
.submittal-log-table colgroup > col:nth-child(5) {
  width: 8%;
}
.submittal-log-table colgroup > col:nth-child(6) {
  width: 8%;
}
.submittal-log-table colgroup > col:nth-child(7) {
  width: 8%;
}
.submittal-log-table colgroup > col:nth-child(8) {
  width: 8%;
}
.submittal-log-table colgroup > col:nth-child(9) {
  width: 12%;
}
.submittal-log-table colgroup > col:nth-child(10) {
  width: 8%;
}
.submittal-lead-time-log-table colgroup > col:nth-child(1) {
  width: 18%;
}
.submittal-lead-time-log-table colgroup > col:nth-child(2) {
  width: 18%;
}
.submittal-lead-time-log-table colgroup > col:nth-child(3) {
  width: 12%;
}
.submittal-lead-time-log-table colgroup > col:nth-child(4) {
  width: 10%;
}
.submittal-lead-time-log-table colgroup > col:nth-child(5) {
  width: 10%;
}
.submittal-lead-time-log-table colgroup > col:nth-child(6) {
  width: 10%;
}
.submittal-lead-time-log-table colgroup > col:nth-child(7) {
  width: 10%;
}
.submittal-lead-time-log-table colgroup > col:nth-child(8) {
  width: 10%;
}
