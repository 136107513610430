.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.header-nav {
  align-items: center;
  display: flex;
}
.header-nav .headerNavItem {
  font-size: 18px;
  line-height: 30px;
  text-decoration: none;
  color: #ffffff;
  margin-right: 50px;
  cursor: pointer;
  user-select: none;
}
