.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.schedules-resolved-modal .modal-dialog {
  width: 90%;
}
.schedules-resolved-modal .modal-dialog .modal-content {
  background-color: #f7f7f8;
}
.schedules-resolved-modal .modal-dialog .modal-content .modal-container .modal-header .modal-header-title-label {
  width: 100%;
}
.schedules-resolved-modal .modal-dialog .modal-content .modal-container .modal-header .modal-header-title-label .search-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.schedules-resolved-modal .modal-dialog .modal-content .modal-container .modal-header .modal-header-title-label .search-bar .search-bar-title {
  font-weight: 400;
  font-size: 20px;
}
.schedules-resolved-modal .modal-dialog .modal-content .modal-container .modal-header .modal-header-title-label .search-bar .search-bar-form .search-input {
  font-weight: 400;
}
.schedules-resolved-modal .modal-dialog .modal-content .modal-container .modal-body {
  padding: 0 28px;
  margin-top: -1px;
  padding-bottom: 12px;
  height: 70vh;
}
.schedules-resolved-modal .modal-dialog .modal-content .modal-container .modal-body .schedule-resolved-table > tbody > tr > td,
.schedules-resolved-modal .modal-dialog .modal-content .modal-container .modal-body .schedule-resolved-table > thead > tr > td,
.schedules-resolved-modal .modal-dialog .modal-content .modal-container .modal-body .schedule-resolved-table > tbody > tr > th,
.schedules-resolved-modal .modal-dialog .modal-content .modal-container .modal-body .schedule-resolved-table > thead > tr > th {
  padding: 12px;
}
.schedules-resolved-modal .modal-dialog .modal-content .modal-container .modal-body .schedule-resolved-table > tbody > tr > td > span,
.schedules-resolved-modal .modal-dialog .modal-content .modal-container .modal-body .schedule-resolved-table > thead > tr > td > span,
.schedules-resolved-modal .modal-dialog .modal-content .modal-container .modal-body .schedule-resolved-table > tbody > tr > th > span,
.schedules-resolved-modal .modal-dialog .modal-content .modal-container .modal-body .schedule-resolved-table > thead > tr > th > span {
  width: 120px;
  display: inline-block;
}
.schedules-resolved-modal .modal-dialog .modal-content .modal-container .modal-body .schedule-resolved-table > tbody > tr > td:nth-child(1) > span,
.schedules-resolved-modal .modal-dialog .modal-content .modal-container .modal-body .schedule-resolved-table > thead > tr > td:nth-child(1) > span,
.schedules-resolved-modal .modal-dialog .modal-content .modal-container .modal-body .schedule-resolved-table > tbody > tr > th:nth-child(1) > span,
.schedules-resolved-modal .modal-dialog .modal-content .modal-container .modal-body .schedule-resolved-table > thead > tr > th:nth-child(1) > span {
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  top: 3px;
}
.schedules-resolved-modal .modal-dialog .modal-content .modal-container .modal-body .schedule-resolved-table > tbody > tr > td:nth-child(11) > span,
.schedules-resolved-modal .modal-dialog .modal-content .modal-container .modal-body .schedule-resolved-table > thead > tr > td:nth-child(11) > span,
.schedules-resolved-modal .modal-dialog .modal-content .modal-container .modal-body .schedule-resolved-table > tbody > tr > th:nth-child(11) > span,
.schedules-resolved-modal .modal-dialog .modal-content .modal-container .modal-body .schedule-resolved-table > thead > tr > th:nth-child(11) > span {
  width: 160px;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  top: 3px;
}
.schedules-resolved-modal .modal-dialog .modal-content .modal-container .modal-body .schedule-resolved-table > thead > tr > th {
  vertical-align: middle;
  padding-top: 8px;
  padding-bottom: 6px;
}
.schedules-resolved-modal .modal-dialog .modal-content .modal-container .modal-body .schedule-resolved-table > thead > tr > th span {
  display: inline-block;
  line-height: 20px;
  word-spacing: 1000px;
}
.schedules-resolved-modal .modal-dialog .modal-content .modal-container .modal-body .schedule-resolved-table > tbody tr td {
  padding-top: 10px;
  padding-bottom: 10px;
}
.schedules-resolved-modal .modal-dialog .modal-content .modal-container .modal-body .schedule-resolved-table > tbody tr td span {
  white-space: nowrap;
}
.schedules-resolved-modal .modal-dialog .modal-content .modal-container .modal-body .schedule-resolved-table > tbody tr:last-child td {
  border-bottom: none;
}
