.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.add-project-report-log {
  display: flex;
  margin: -24px 0px;
  min-height: 100%;
}
.add-project-report-log .add-project-report-menu-container {
  width: 25%;
  display: flex;
  flex-direction: column;
  background-color: #eaeaec;
  padding: 21px 40px;
}
.add-project-report-log .add-project-report-menu-container .menu-title {
  font-size: 29px;
  line-height: 46px;
  font-weight: 600;
  margin-bottom: 70px;
  color: #454a5c;
}
.add-project-report-log .add-project-report-menu-container .menu-item-container {
  margin-bottom: 20px;
}
.add-project-report-log .add-project-report-menu-container .menu-item-container .menu-item {
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  color: #7a7e8b;
  margin-bottom: 24px;
  cursor: pointer;
}
.add-project-report-log .add-project-report-menu-container .menu-item-container .menu-item-active {
  color: #00b091;
}
.add-project-report-log .add-project-report-menu-container .menu-item-container .menu-item-fail {
  color: #fb6120;
}
.add-project-report-log .add-project-report-menu-container .menu-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.add-project-report-log .add-project-report-menu-container .menu-buttons-container .menu-button {
  width: 153px;
  margin-bottom: 12px;
}
.add-project-report-log .add-project-report-menu-container .menu-buttons-container .buttons-separate {
  height: 12px;
}
.add-project-report-log .add-project-report-menu-container .menu-buttons-container .white-button-background-project-reports {
  background-color: inherit !important;
}
.add-project-report-log .add-project-report-form-container {
  width: 75%;
  padding: 20px 40px;
  position: relative;
}
.add-project-report-log .add-project-report-form-container .title-form {
  font-size: 23px;
  font-weight: 400;
  line-height: 38px;
  color: #454a5c;
  margin-bottom: 37px;
}
.project-report-save-draft-modal-view .modal-body-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 40px 50px 40px;
}
.project-report-save-draft-modal-view .text-section {
  text-align: center;
  margin: 21px 0px 48px 0px;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}
.project-report-save-draft-modal-view .buttons-section {
  display: flex;
  justify-content: center;
}
.project-report-save-draft-modal-view .buttons-separate {
  width: 40px;
}
.project-report-save-draft-modal-view .done-icon {
  width: 60px;
}
.project-report-save-draft-modal-view .dropdown-section {
  margin: 24px 0px 62px 0px;
}
.project-report-save-draft-modal-view .dropdown-section .multiple-dropdown {
  width: 520px !important;
}
.project-report-save-draft-modal-view .dropdown-description {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}
.project-report-save-draft-modal-view .modal-dialog .modal-content .modal-body {
  overflow: visible !important;
}
.send-email-modal .modal-dialog {
  width: 70%;
}
.send-email-modal .send-email-container {
  margin: 24px 40px;
}
.send-email-modal .buttons-container {
  display: flex;
  margin-bottom: 50px;
}
.dark-mode .dropdown-description,
.dark-mode .text-section {
  color: #ffffff;
}
.dark-mode .add-project-report-log .primary-text-color {
  color: #ffffff !important;
}
.dark-mode .add-project-report-log .add-project-report-menu-container {
  background-color: #303441;
}
.dark-mode .add-project-report-log .add-project-report-menu-container .menu-item-container .menu-item {
  color: rgba(255, 255, 255, 0.5);
}
.dark-mode .add-project-report-log .add-project-report-menu-container .menu-item-container .menu-item-active {
  color: #d4fb00;
}
.dark-mode .add-project-report-log .add-project-report-menu-container .menu-item-container .menu-item-fail {
  color: #02c7ff;
}
