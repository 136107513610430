.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.security-cameras-modal .modal-dialog {
  max-height: 95vh;
  width: 95vw;
}
.security-cameras-modal .modal-body {
  padding: 8px 0px 40px 0px !important;
}
.security-cameras-modal .form-control-bottom-label {
  display: none;
}
.security-cameras-modal .cameras-list {
  width: 100%;
  max-height: calc(95vh - 200px);
  padding: 0px 40px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1em;
  overflow-y: auto;
  overflow-y: overlay;
}
.security-cameras-modal .cameras-list .camera {
  display: flex;
  flex-direction: column;
}
.security-cameras-modal .cameras-list .camera .camera-picture {
  width: 100%;
  aspect-ratio: 1.25;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  cursor: pointer;
}
.security-cameras-modal .cameras-list .camera .camera-picture .camera-channel {
  margin: 8px 8px auto auto;
  padding: 2px 4px;
  border-radius: 8px;
  background-color: #ffffff;
  color: #3c4249;
  user-select: none;
}
.security-cameras-modal .cameras-list .camera .camera-form {
  display: flex;
  flex-direction: column;
  margin: 10px 8px 0px 8px;
}
.security-cameras-modal .cameras-list .camera.selected {
  border: 4px solid #00b091;
}
.security-cameras-modal .cameras-list .camera.selected .camera-picture {
  background-size: calc(100% + 8px);
}
.security-cameras-modal .cameras-list .camera.selected .camera-picture .camera-channel {
  margin: 4px 4px auto auto;
  background-color: #00b091;
  color: #ffffff;
}
.security-cameras-modal.dark-mode .cameras-list .camera .camera-picture .camera-channel {
  background-color: #909197;
  color: #ffffff;
}
.security-cameras-modal.dark-mode .cameras-list .camera.selected {
  border-color: #d4fb00;
}
.security-cameras-modal.dark-mode .cameras-list .camera.selected .camera-picture .camera-channel {
  background-color: #d4fb00;
  color: #3c4249;
}
