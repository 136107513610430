.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.menu.dropdown-menu-container.filter-header > div:first-child > div:first-child {
  width: 100%;
}
.menu.dropdown-menu-container.filter-header .filter-header-selector {
  display: flex;
  flex-direction: column;
}
.menu.dropdown-menu-container.filter-header .filter-header-selector .filter-header-title {
  height: 10px;
  margin: -10px 0px 0px 0px;
  font-size: 10px;
  line-height: 100%;
  color: #7a7e8b;
}
.menu.dropdown-menu-container.filter-header .filter-header-selector .filter-header-body {
  display: flex;
}
.menu.dropdown-menu-container.filter-header .filter-header-selector .filter-header-body .filter-header-value.selected-value {
  color: #454a5c;
}
.menu.dropdown-menu-container.filter-header .filter-header-selector .filter-header-body .input {
  height: unset;
  line-height: 20px;
  padding: 2px 0px;
  border: unset;
}
.menu.dropdown-menu-container.filter-header .filter-header-selector .filter-header-body .header-label-icon-container {
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.menu.dropdown-menu-container.filter-header .filter-header-selector .filter-header-body .header-label-icon {
  width: 11px;
  height: 6.25px;
}
.menu.dropdown-menu-container.filter-header .filter-header-selector.filter-open .header-label-icon {
  transform: rotate(180deg);
}
.menu.dropdown-menu-container.filter-header.search-header .filter-header-selector.filter-open {
  border-bottom: 1px solid #454a5c;
}
.menu.dropdown-menu-container.filter-header.clearable-header .header-label-clear .header-label-icon-clear {
  width: 8px;
  height: 8px;
  margin-left: 6px;
  margin-bottom: 2px;
}
.menu.dropdown-menu-container.filter-header.disabled-header {
  opacity: 0.5;
  pointer-events: none;
}
.menu.dropdown-menu-container.filter-header.dark-mode .filter-header-selector .filter-header-title {
  color: rgba(255, 255, 255, 0.7);
}
.menu.dropdown-menu-container.filter-header.dark-mode .filter-header-selector .filter-header-body .filter-header-value.selected-value {
  color: #ffffff;
}
.dropdown-menu-container.filter-header.popover-children {
  margin-top: 0px;
}
.dropdown-menu-container.filter-header.popover-children .menu-item {
  padding: 8px 16px;
}
