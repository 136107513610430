.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.mega-menu {
  cursor: pointer;
  user-select: none;
  color: black;
  font-size: 24px;
  font-weight: 600;
}
.mega-menu .mega-menu-image-sector-container.batch {
  display: flex;
}
.mega-menu .mega-menu-image-sector-container .mega-menu-image-sector-batch {
  color: #00b091;
}
.mega-menu .popover-arrow {
  color: #ffffff;
  font-size: 24px;
}
.mega-menu.rotated-icon .mega-menu-image-sector {
  transform: rotate(-90deg);
}
.mega-menu.more-options .popover-children {
  width: 160px;
  margin-right: -20px;
}
.mega-menu.more-options.rotated-icon .mega-menu-image-sector-container {
  height: 24px;
}
.mega-menu.dark-mode .popover-arrow {
  color: #303441;
}
.mega-menu.dark-mode .mega-menu-image-sector-container .mega-menu-image-sector-batch {
  color: #d4fb00;
}
.mega-menu.dark-mode.mega-menu {
  color: white;
}
.mega-menu-popover .arrow {
  display: none;
  width: 36px;
  height: 36px;
  border-width: 18px;
  margin-top: -18px;
  border-style: solid;
  border-color: transparent;
  border-bottom-color: #ffffff;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: 16px;
}
.mega-menu-popover.mega-menu-popover-arrow .MuiPopover-paper {
  background-color: transparent;
}
.mega-menu-popover.mega-menu-popover-arrow .arrow {
  display: block;
}
.mega-menu-popover .Mui-Menu-list {
  padding-top: 0;
  padding-bottom: 0;
}
.mega-menu-popover .MuiPaper-rounded {
  border-radius: 0;
}
.mega-menu-popover .popover-children {
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  min-width: 150px;
  background-color: #ffffff;
  overflow: auto;
  overflow: overlay;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
}
.mega-menu-popover .popover-children .mega-menu-item {
  padding: 16px 24px !important;
  font-size: 16px;
  line-height: 24px;
  color: #454a5c;
  display: flex;
  font-family: Titillium Web, sans-serif !important;
}
.mega-menu-popover .popover-children .mega-menu-item:hover {
  background-color: #f7f7f8;
}
.mega-menu-popover .popover-children .mega-menu-item .mega-menu-item-counter {
  margin-left: 20px !important;
  font-weight: bold;
  font-size: 14px;
}
.mega-menu-popover .popover-children .mega-menu-item .mega-menu-item-arrow-container {
  margin-left: auto;
  user-select: none;
  padding-left: 12px;
}
.mega-menu-popover .popover-children .mega-menu-item .mega-menu-item-arrow-container .mega-menu-item-arrow {
  padding: 8px;
}
.mega-menu-popover .popover-children .mega-menu-item-separator {
  margin: 0px 16px;
  margin-top: -1px;
  border-bottom: 1px solid #eaeaec;
}
.mega-menu-popover .popover-children .mega-menu-item-separator:last-child {
  display: none;
}
.mega-menu-popover .popover-children.with-sub-menu .mega-menu-item:hover {
  background-color: #fcfcfc;
}
.mega-menu-popover .popover-children.with-sub-menu .mega-menu-item.submega-menu-open {
  background-color: #f7f7f8;
  color: #00b091;
}
.mega-menu-popover .popover-children.with-sub-menu .mega-menu-item.submega-menu-open:hover {
  background-color: #f3f3f3;
}
.mega-menu-popover .popover-children.with-sub-menu .mega-menu-item.submega-menu-open .mega-menu-item-arrow {
  transform: rotate(180deg);
}
.mega-menu-popover .popover-children.with-sub-menu .mega-menu-item.submega-menu-open + .mega-menu-item-separator {
  border-bottom: 1px solid #9ea0aa;
}
.mega-menu-popover .popover-children.with-sub-menu .sub-menu .mega-menu-item {
  padding-left: 48px;
  background-color: #f7f7f8;
}
.mega-menu-popover .popover-children.with-sub-menu .sub-menu .mega-menu-item:hover {
  background-color: #f3f3f3;
}
.mega-menu-popover .popover-children.with-sub-menu .sub-menu .mega-menu-item-separator:last-child {
  display: block;
}
.mega-menu-popover.dark-mode.mega-menu-popover .arrow {
  border-bottom-color: #303441;
}
.mega-menu-popover.dark-mode .popover-children {
  background-color: #303441;
  scrollbar-color: rgba(86, 93, 114, 0.3) rgba(0, 0, 0, 0);
}
.mega-menu-popover.dark-mode .popover-children .mega-menu-item {
  color: #ffffff;
}
.mega-menu-popover.dark-mode .popover-children .mega-menu-item:hover {
  background-color: #363a46;
}
.mega-menu-popover.dark-mode .popover-children .mega-menu-item-separator {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.mega-menu-popover.dark-mode .popover-children.with-sub-menu .mega-menu-item.sub-menu-open {
  background-color: #3b4050;
  color: #d4fb00;
}
.mega-menu-popover.dark-mode .popover-children.with-sub-menu .mega-menu-item.sub-menu-open:hover {
  background-color: #43495a;
}
.mega-menu-popover.dark-mode .popover-children.with-sub-menu .mega-menu-item.sub-menu-open + .mega-menu-item-separator {
  border-bottom: 1px solid #909197;
}
.mega-menu-popover.dark-mode .popover-children.with-sub-menu .sub-menu .mega-menu-item {
  background-color: #3b4050;
}
.mega-menu-popover.dark-mode .popover-children.with-sub-menu .sub-menu .mega-menu-item:hover {
  background-color: #43495a;
}
.date-picker-container {
  margin-left: -30px;
}
.mega-menu-item-counter {
  margin-left: 10px;
  font-weight: bold;
  font-size: 14px;
}
