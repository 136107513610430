.simple-button {
  position: relative;
}
.simple-button > img {
  margin-top: 3px;
  position: absolute;
  right: 15px;
}
.simple-button > i {
  margin-top: 3px;
  position: absolute;
  right: 15px;
}
