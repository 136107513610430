.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.rfi-resources {
  display: flex;
  width: 100%;
}
.rfi-resources > * {
  width: 50%;
}
.rfi-resources > * .rfi-resources-body {
  padding: 16px 16px 0px 8px;
}
.rfi-resources .rfi-documents .document-link {
  margin-bottom: 8px;
}
.rfi-resources .rfi-documents .document-link .document-link-remove {
  margin-left: auto;
}
.rfi-resources .rfi-photos {
  align-self: flex-end;
}
