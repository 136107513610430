.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.select-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.select-list ul > li {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
}
.select-list ul > li > div {
  vertical-align: middle;
}
.select-list .select-table {
  width: 100%;
}
.select-list .select-table tr:not(:first-child) td {
  padding-top: 15px;
}
.select-list .select-table tr td {
  width: 50%;
}
.select-list .select-table tr td > div {
  display: flex;
  align-items: center;
}
