.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.project-dashboard-project-reports {
  display: flex;
  flex-direction: column;
  padding: 0px 32px;
}
.project-dashboard-project-reports .reports-title {
  padding: 16px 0px;
  font-size: 29px;
  line-height: 46px;
  font-weight: 600;
  color: #454a5c;
}
.project-dashboard-project-reports .report-item {
  display: flex;
  width: 164px;
  cursor: pointer;
  /*&:not(.swiper-slide-active) {
      .report-item-tint {
        display: block;
        position: absolute;
        width: 162px;
        height: 83px;
        background: linear-gradient(
          269.62deg,
          #21242f -6.33%,
          rgba(33, 36, 47, 0) 103.98%
        );
      }
    }*/
}
.project-dashboard-project-reports .report-item .report-item-tint {
  display: none;
}
.project-dashboard-project-reports .report-item .report-item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 12px;
  background-color: #f0f1f2;
}
.project-dashboard-project-reports .report-item .report-item-details {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}
.project-dashboard-project-reports .report-item .report-item-details .report-item-name {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #454a5c;
}
.project-dashboard-project-reports .report-item .report-item-details .report-item-date {
  font-size: 14px;
  line-height: 24px;
  color: #7a7e8b;
}
.project-dashboard-project-reports .swiper-pagination-bullets {
  height: 48px;
}
.project-dashboard-project-reports .swiper-view-more {
  margin-top: -48px;
  line-height: 48px;
}
.project-dashboard-project-reports.disabled .report-item {
  align-items: center;
  width: unset;
  cursor: default;
}
.project-dashboard-project-reports.disabled .report-item .report-item-disabled-text {
  font-size: 16px;
  line-height: 24px;
  color: #7a7e8b;
}
.project-dashboard-project-reports.dark-mode .reports-title {
  color: #ffffff;
}
.project-dashboard-project-reports.dark-mode .report-item .report-item-icon {
  background-color: #303441;
}
.project-dashboard-project-reports.dark-mode .report-item .report-item-details .report-item-name {
  color: #ffffff;
}
.project-dashboard-project-reports.dark-mode .report-item .report-item-details .report-item-description {
  color: #bcbdc1;
}
.project-dashboard-project-reports.dark-mode.disabled .report-item .report-item-disabled-text {
  color: #bcbdc1;
}
@media (max-height: 1000px), (max-width: 1600px) {
  .project-dashboard-project-reports {
    padding: 0px 16px;
  }
  .project-dashboard-project-reports .reports-title {
    padding: 8px 0px;
    font-size: 26px;
    line-height: 38px;
  }
}
@media (max-height: 800px), (max-width: 1300px) {
  .project-dashboard-project-reports .reports-title {
    padding: 8px 0px;
    font-size: 18px;
    line-height: 24px;
  }
  .project-dashboard-project-reports .report-item .report-item-icon {
    width: 42px;
    height: 42px;
  }
  .project-dashboard-project-reports .report-item .report-item-icon img {
    width: 18px;
  }
  .project-dashboard-project-reports .report-item .report-item-details .report-item-name {
    font-size: 14px;
    line-height: 18px;
  }
  .project-dashboard-project-reports .report-item .report-item-details .report-item-date {
    font-size: 12px;
    line-height: 16px;
  }
  .project-dashboard-project-reports .swiper-pagination-bullets {
    height: 24px;
  }
  .project-dashboard-project-reports .swiper-view-more {
    font-size: 12px;
    margin-top: -24px;
    line-height: 24px;
  }
  .project-dashboard-project-reports .swiper-view-more img {
    width: 12px;
    margin-left: 4px;
  }
}
