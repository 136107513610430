.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.document-link {
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
}
.document-link .document-link-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.document-link .document-link-label a {
  font-size: 16px;
  line-height: 24px;
  color: #454a5c;
  text-decoration: none;
}
.document-link .document-link-label a.not-active {
  pointer-events: none;
}
.document-link .document-link-label a > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.document-link .document-link-image {
  width: 16px;
  height: 16px;
  padding: 1px;
}
.document-link .document-link-image:first-child {
  margin-right: 8px;
}
.document-link .document-link-image:last-child {
  margin-left: 8px;
}
.document-link .document-link-remove {
  width: 16px;
  height: 16px;
  padding: 1px;
}
.document-link .document-link-remove:first-child {
  margin-right: 24px;
}
.document-link .document-link-remove:last-child {
  margin-left: 24px;
}
.document-link.dark-mode .document-link-label a {
  color: #ffffff;
}
