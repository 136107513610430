.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.project-home .content-container {
  float: left;
  width: 100%;
}
.project-home .content-container .home-title {
  width: 100%;
  padding-left: 40px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.project-home .content-container .home-body {
  margin-left: 40px;
  margin-right: 40px;
}
.project-home .content-container .home-body .home-details {
  float: left;
  width: 420px;
}
.project-home .content-container .home-body .home-details .project-logo {
  width: 100%;
  height: 240px;
}
.project-home .content-container .home-body .home-details .project-logo .logo-upload {
  height: 100%;
}
.project-home .content-container .home-body .home-details .project-logo .logo-upload .preview-container {
  max-height: 100%;
}
.project-home .content-container .home-body .home-details .project-logo .logo-upload .preview-container > div {
  width: 100%;
  height: 100%;
  border: none;
}
.project-home .content-container .home-body .home-details .project-logo .logo-upload .preview-container img {
  max-height: 240px;
}
.project-home .content-container .home-body .home-details .project-logo .logo-upload .logo-upload-button-container {
  width: 100%;
  height: 100%;
}
.project-home .content-container .home-body .home-details .project-logo .logo-upload .logo-upload-button-container .dropzone-container {
  float: left;
  width: 100%;
  height: 100%;
  padding: 0px;
  border: none;
}
.project-home .content-container .home-body .home-details .project-logo .logo-upload .logo-upload-button-container .dropzone-container img {
  margin-left: calc(50% - 15px);
  margin-top: 90px;
}
.project-home .content-container .home-body .home-details .project-logo .logo-upload .logo-upload-button-container .dropzone-container p {
  text-align: center;
}
.project-home .content-container .home-body .home-details .project-description {
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
}
.project-home .content-container .home-body .home-items {
  width: calc(100% - 420px);
  padding-left: 30px;
  display: flex;
  flex-wrap: wrap;
}
.project-home .content-container .home-body .home-items .details-info-item {
  width: 25%;
  margin: 0px 0px 64px 0px;
  padding-left: 16px;
}
.project-home .content-container .home-body .home-items .details-info-item.half-item {
  width: 12.5%;
}
.project-home .content-container .home-body .home-items .details-info-item .add-member-button {
  margin-top: 11px;
}
.project-home .project-home-activity {
  float: left;
  width: 100%;
  margin-top: 24px;
}
.project-home .project-home-activity .recent-activity,
.project-home .project-home-activity .chat-activity {
  width: calc(50% - 12px);
}
.project-home .project-home-activity .recent-activity.recent-activity,
.project-home .project-home-activity .chat-activity.recent-activity {
  float: left;
}
.project-home .project-home-activity .recent-activity.chat-activity,
.project-home .project-home-activity .chat-activity.chat-activity {
  float: right;
}
