.table-dragable .empty-table-message {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  font-size: 22px;
  letter-spacing: -0.6px;
  color: rgba(69, 74, 92, 0.5);
}
.table-dragable .td-icon {
  cursor: move;
}
.table-dragable .disabled .td-icon {
  cursor: not-allowed;
}
.dark-mode .table-dragable .empty-table-message {
  color: rgba(255, 255, 255, 0.5);
}
.dark-mode .table-dragable .dragable-table tbody tr td {
  border-color: rgba(255, 255, 255, 0.05);
}
