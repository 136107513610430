.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.notification-viewer {
  margin-right: 26px;
  cursor: pointer;
}
.notification-viewer .notifications-selector .nv-bell-image {
  width: 22px;
  height: 22px;
}
.notification-viewer .notifications-selector .nv-unread-indicator {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  top: -4px;
  right: -2px;
  border-radius: 12px;
  font-size: 11px;
  font-weight: bold;
  background-color: #fb6120;
  color: #ffffff;
}
.notification-viewer > div > div:nth-child(2) {
  margin-left: 24px;
}
.notification-viewer .nv-popover-arrow {
  color: #ffffff;
  font-size: 24px;
  margin-right: 24px;
}
.notification-viewer .nv-notification-container {
  display: flex;
  flex-direction: column;
  width: 480px;
  background-color: #ffffff;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.15));
}
.notification-viewer .nv-notification-container .nv-notifications-header {
  display: flex;
  padding: 16px 24px 16px 40px;
  background-color: #f7f7f8;
}
.notification-viewer .nv-notification-container .nv-notifications-header .nv-notifications-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: #454a5c;
}
.notification-viewer .nv-notification-container .nv-notifications-header .nv-read-all-button {
  margin-left: auto;
  font-size: 16px;
  line-height: 36px;
}
.notification-viewer .nv-notification-container .nv-notifications-body {
  height: 480px;
  max-height: 80vh;
  margin-left: 0px !important;
  overflow: auto;
  overflow: overlay;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
}
.notification-viewer .nv-notification-container .nv-notifications-body .nv-notifications-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 16px 8px 40px;
  height: 96px;
  font-size: 16px;
  line-height: 24px;
  color: #454a5c;
}
.notification-viewer .nv-notification-container .nv-notifications-body .nv-notifications-item .nv-notifications-item-title {
  color: #00b091;
}
.notification-viewer .nv-notification-container .nv-notifications-body .nv-notifications-item .nv-notifications-item-title:after {
  content: ' ';
}
.notification-viewer .nv-notification-container .nv-notifications-body .nv-notifications-item .nv-notifications-item-unread {
  float: left;
  width: 8px;
  height: 8px;
  margin-left: -22px;
  margin-top: 8px;
  border-radius: 100%;
  background-color: #00b091;
}
.notification-viewer .nv-notification-container .nv-notifications-body .nv-notifications-item .nv-message-popover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.notification-viewer .nv-notification-container .nv-notifications-body .nv-notifications-item .nv-message-popover .nv-message-popover-buttons {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}
.notification-viewer .nv-notification-container .nv-notifications-body .nv-notifications-item .nv-message-popover .nv-message-popover-buttons > *:not(:first-child) {
  margin-left: 24px;
}
.notification-viewer .nv-notification-container .nv-notifications-body .nv-notifications-item .nv-notifications-item-content {
  overflow: hidden;
  line-height: 2rem;
  max-height: 8rem;
  display: block;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  /* autoprefixer: off */
}
.notification-viewer .nv-notification-container .nv-notifications-body .nv-notifications-item:hover .nv-message-popover {
  display: block;
}
.notification-viewer .nv-notification-container .nv-notifications-body .nv-notifications-item:hover .nv-notifications-item-content {
  opacity: 0.1;
}
.notification-viewer .nv-notification-container .nv-notifications-body .nv-notifications-item.nv-loading {
  height: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 29px;
  line-height: 46px;
  color: #00b091;
}
.notification-viewer .nv-notification-container .nv-notifications-body .nv-notifications-separator {
  margin: 0px 16px;
  margin-top: -1px;
  border-bottom: 1px solid #eaeaec;
}
.notification-viewer .nv-notification-container .simple-pagination {
  margin-bottom: 16px;
}
.notification-viewer.dark-mode .notifications-selector .nv-unread-indicator {
  background-color: #02c7ff;
}
.notification-viewer.dark-mode .nv-popover-arrow {
  color: #2b2f3d;
}
.notification-viewer.dark-mode .nv-notification-container {
  background-color: #252834;
}
.notification-viewer.dark-mode .nv-notification-container .nv-notifications-header {
  background-color: #2b2f3d;
}
.notification-viewer.dark-mode .nv-notification-container .nv-notifications-header .nv-notifications-title {
  color: #ffffff;
}
.notification-viewer.dark-mode .nv-notification-container .nv-notifications-body .nv-notifications-item {
  color: #ffffff;
}
.notification-viewer.dark-mode .nv-notification-container .nv-notifications-body .nv-notifications-item .nv-notifications-item-title {
  color: #d4fb00;
}
.notification-viewer.dark-mode .nv-notification-container .nv-notifications-body .nv-notifications-item .nv-notifications-item-unread {
  background-color: #d4fb00;
}
.notification-viewer.dark-mode .nv-notification-container .nv-notifications-body .nv-notifications-item.nv-loading {
  color: #d4fb00;
}
.notification-viewer.dark-mode .nv-notification-container .nv-notifications-body .nv-notifications-separator {
  margin: 0px 16px;
  margin-top: -1px;
  opacity: 0.2;
}
