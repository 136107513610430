.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.mega-menu-dropdown.mega-menu-dropdown-container .mega-menu-dropdown-selector {
  display: flex;
  align-items: center;
  user-select: none;
  font-weight: 500;
}
.mega-menu-dropdown.mega-menu-dropdown-container .mega-menu-dropdown-selector .mega-menu-dropdown-selector-arrow {
  padding: 19px 7px;
}
.mega-menu-dropdown.mega-menu-dropdown-container .popover-arrow {
  display: none;
}
.mega-menu-dropdown.mega-menu-dropdown-container.mega-menu-open .mega-menu-dropdown-selector .mega-menu-dropdown-selector-arrow {
  transform: rotate(180deg);
}
.mega-menu-dropdown.mega-menu-dropdown-container.form-mega-menu-dropdown .mega-menu-dropdown-selector {
  padding: 7px 0px 8px 8px;
  font-size: 16px;
  line-height: 24px;
  border-bottom: 1px solid #7a7e8b;
}
.mega-menu-dropdown.mega-menu-dropdown-container.form-mega-menu-dropdown.mega-menu-open .mega-menu-dropdown-selector {
  border-color: #00b091;
}
.mega-menu-dropdown.mega-menu-dropdown-container.section-selector-mega-menu-dropdown .mega-menu-dropdown-selector {
  padding: 1px 8px 1px 0px;
  font-size: 23px;
  line-height: 38px;
}
.mega-menu-dropdown.mega-menu-dropdown-container.section-selector-mega-menu-dropdown .mega-menu-dropdown-selector.extra-padding {
  padding-left: 15px;
}
.mega-menu-dropdown.mega-menu-dropdown-container.dark-mode .mega-menu-dropdown-selector {
  color: #ffffff;
}
.mega-menu-dropdown.mega-menu-dropdown-container.dark-mode .popover-children {
  background-color: #303441;
}
.mega-menu-dropdown.mega-menu-dropdown-container.dark-mode .popover-children .mega-menu-item.selected {
  color: #d4fb00;
}
.mega-menu-dropdown.mega-menu-dropdown-container.dark-mode .popover-children .mega-menu-item.selected + .mega-menu-item-separator {
  border-color: #d4fb00;
}
.mega-menu-dropdown.mega-menu-dropdown-container.dark-mode .popover-children .mega-menu-item:hover {
  background-color: #363a46;
}
.mega-menu-dropdown.mega-menu-dropdown-container.dark-mode.form-mega-menu-dropdown .mega-menu-dropdown-selector {
  border-color: #bcbdc1;
}
.mega-menu-dropdown.mega-menu-dropdown-container.dark-mode.form-mega-menu-dropdown.menu-open .mega-menu-dropdown-selector {
  border-color: #d4fb00;
}
.mega-menu-dropdown-container.popover-children {
  max-height: 336px !important;
  display: block !important;
  overflow: auto !important;
}
.mega-menu-dropdown-container.popover-children .mega-menu-item.selected {
  color: #00b091;
}
.mega-menu-dropdown-container.popover-children .mega-menu-item.selected + .mega-menu-item-separator {
  border-color: #00b091;
}
.mega-menu-dropdown-container.form-mega-menu-dropdown.popover-children {
  width: unset;
  min-width: 60px;
}
.mega-menu-dropdown-container.form-mega-menu-dropdown.popover-children .mega-menu-item {
  padding: 8px 8px;
}
.mega-menu-dropdown-container.form-mega-menu-dropdown.popover-children .mega-menu-item-separator {
  margin: 0px 4px;
  margin-top: -1px;
}
.mega-menu-dropdown-container.section-selector-mega-menu-dropdown.popover-children .mega-menu-item {
  padding: 12px 12px 12px 20px;
}
.mega-menu-dropdown-container.dark-mode.popover-children {
  background-color: #303441;
}
.mega-menu-dropdown-container.dark-mode.popover-children .mega-menu-item.selected {
  color: #d4fb00;
}
.mega-menu-dropdown-container.dark-mode.popover-children .mega-menu-item.selected + .mega-menu-item-separator {
  border-color: #d4fb00;
}
.mega-menu-dropdown-container.dark-mode.popover-children .mega-menu-item:hover {
  background-color: #363a46;
}
