.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.schedules-collapsible-header {
  margin: 1px 0 0;
  background-color: #eaeaec;
  position: sticky;
  left: 0;
  width: 100%;
  padding: 6px 16px;
  z-index: 1;
}
.schedules-collapsible-header .collapsible-title .collapsible-label {
  font-size: 16px;
  font-weight: normal;
}
.schedules-collapsible-header .collapsible-title .collapsible-icon.start {
  margin-right: 60px;
}
.schedules-collapsible-header .header-actions {
  display: flex;
  align-items: center;
}
.schedules-collapsible-header .header-actions button {
  font-size: 14px;
}
.schedules-collapsible-header .header-actions button img {
  width: 20px;
  height: 20px;
}
.schedules-collapsible-header .header-actions button img ~ span {
  margin-left: 6px;
  color: #454a5c;
}
.schedules-collapsible-header .header-actions .menu-image-sector {
  width: 16px;
}
.schedules-collapsible-header .header-actions > * {
  margin-left: 24px;
}
.schedules-collapsible-header .header-actions .menu.more-options .popover-children {
  width: 200px;
  margin-right: -62px;
  position: relative;
  z-index: 2000;
  right: 52px;
}
.schedules-collapsible-header .header-actions .menu.more-options .popover-children .menu-item {
  position: relative;
  z-index: 1000;
}
.schedules-collapsible-body {
  padding: 0;
}
.schedules-table > tbody > tr > td,
.schedules-table > thead > tr > td,
.schedules-table > tbody > tr > th,
.schedules-table > thead > tr > th {
  padding: 12px;
}
.schedules-table > tbody > tr > td > span,
.schedules-table > thead > tr > td > span,
.schedules-table > tbody > tr > th > span,
.schedules-table > thead > tr > th > span {
  width: 130px;
  display: inline-block;
}
.schedules-table > tbody > tr > td:first-child,
.schedules-table > thead > tr > td:first-child,
.schedules-table > tbody > tr > th:first-child,
.schedules-table > thead > tr > th:first-child {
  padding-left: 19px !important;
}
.schedules-table > tbody > tr > td:first-child > span,
.schedules-table > thead > tr > td:first-child > span,
.schedules-table > tbody > tr > th:first-child > span,
.schedules-table > thead > tr > th:first-child > span {
  width: 46px;
}
.schedules-table > tbody > tr > td:nth-child(2) > span,
.schedules-table > thead > tr > td:nth-child(2) > span,
.schedules-table > tbody > tr > th:nth-child(2) > span,
.schedules-table > thead > tr > th:nth-child(2) > span {
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  top: 3px;
}
.schedules-table > tbody > tr > td:nth-child(12) > span,
.schedules-table > thead > tr > td:nth-child(12) > span,
.schedules-table > tbody > tr > th:nth-child(12) > span,
.schedules-table > thead > tr > th:nth-child(12) > span {
  width: 160px;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  top: 3px;
}
.schedules-table > tbody > tr > td:nth-child(13) > span,
.schedules-table > thead > tr > td:nth-child(13) > span,
.schedules-table > tbody > tr > th:nth-child(13) > span,
.schedules-table > thead > tr > th:nth-child(13) > span {
  width: 180px;
}
.schedules-table > tbody > tr.schedule-error,
.schedules-table > thead > tr.schedule-error {
  background-color: rgba(251, 97, 32, 0.15);
}
.schedules-table > thead > tr > th {
  vertical-align: middle;
  padding-top: 8px;
  padding-bottom: 6px;
}
.schedules-table > thead > tr > th span {
  display: inline-block;
  line-height: 20px;
  word-spacing: 1000px;
}
.schedules-table > tbody tr td {
  padding-top: 0px;
  padding-bottom: 0px;
}
.schedules-table > tbody tr td span {
  white-space: nowrap;
}
.schedules-table > tbody tr:last-child td {
  border-bottom: none;
}
.schedules-table .schedule-datetime-picker .MuiInputBase-root {
  height: auto;
}
.schedules-table .schedule-datetime-picker .date-picker-remove {
  top: 12px;
}
.schedules-table .schedule-datetime-picker .icon-image {
  opacity: 0;
  top: 0;
}
.schedules-table .schedule-datetime-picker:hover .icon-image {
  opacity: 1;
}
.schedules-table .schedule-datetime-picker.readonly-input {
  padding-left: 0;
  padding-right: 0;
}
.schedules-table .responsible-dropdown .MuiInputBase-root {
  height: auto;
}
.schedules-table .schedule-text-area {
  border-bottom: none;
  height: auto !important;
  padding: 2px 0 3px;
}
.schedules-table .schedule-text-area:not(:read-only):hover {
  background-size: 13px;
  background-position: right 0 top 8px;
  background-repeat: no-repeat;
  background-image: url('../../../../../images/editable-pencil.svg');
}
.schedules-table .actions {
  display: flex;
  align-items: center;
}
.schedules-table .actions button {
  margin-right: 28px;
}
.schedules-table.table-header {
  background-color: #f7f7f8;
}
.schedules-table.table-header thead tr th {
  border-bottom: none;
}
.schedules-table.table-header thead tr th:first-child {
  width: 76px;
  display: inline-block;
}
.schedules-table.table-header + .empty-table-message {
  display: none;
}
.dark-mode .schedules-collapsible-header {
  background-color: #43495a;
}
.dark-mode .schedules-collapsible-header .header-actions button img ~ span {
  color: #ffffff;
}
.dark-mode .schedules-collapsible-header .collapsible-title .collapsible-label {
  color: #ffffff;
}
.dark-mode .schedules-table.table-header {
  background-color: #21242f;
}
.dark-mode .schedules-table tbody tr td {
  color: #ffffff;
}
.dark-mode .schedules-table > tbody > tr.schedule-error,
.dark-mode .schedules-table > thead > tr.schedule-error {
  background-color: rgba(2, 199, 255, 0.18);
}
.dark-mode .schedules-table .schedule-text-area:not(:read-only):hover {
  background-image: url('../../../../../images/editable-pencil-dark.svg');
}
