.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.client {
  position: absolute;
  width: 100%;
  top: -80px;
  bottom: 0px;
  background: url('../../images/aude-andre-saturnio-x_HQ8uh5Wg8-unsplash.jpg');
  background-size: cover;
  overflow: auto;
}
.client .form-container {
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(110.72deg, rgba(30, 31, 36, 0.52) 18.81%, rgba(30, 31, 36, 0) 106.45%);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding: 60px 0px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
}
.client .form-container-firefox {
  position: relative;
  background: linear-gradient(110.72deg, rgba(30, 31, 36, 0.89) 18.81%, rgba(30, 31, 36, 0.97) 106.45%);
  margin-left: auto;
  margin-right: auto;
  padding: 60px 0px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
}
.client .align-center {
  min-height: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.client .max-height {
  height: 100%;
}
.client .input-margin-bottom {
  margin-bottom: 20px;
}
.client .input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.client .btn-continue,
.client .button-log-in,
.client .button-log-in:hover,
.client .button-log-in:focus,
.client .button-log-in:active {
  background-color: transparent !important;
  border: none;
  outline: none !important;
}
.client .btn-continue {
  display: flex;
  justify-content: center;
  align-items: center;
}
.client .button-disabled {
  cursor: not-allowed;
}
.client .text-btn {
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.5;
  margin-right: 10px;
}
.client .button-log-in {
  margin-top: 180%;
}
.client ::-ms-reveal {
  display: none;
}
.client .icon-log-in,
.client .text-log-in,
.client .arrow,
.client .plus {
  display: inline-block;
  opacity: 0.5;
  background-color: #ffffff;
}
.client .icon-log-in {
  height: 32px;
  width: 32px;
  -webkit-mask-image: url('./login/arrow.svg');
  mask-image: url('./login/arrow.svg');
}
.client .text-log-in {
  height: 36px;
  width: 61px;
  -webkit-mask-image: url('./login/logIn.svg');
  mask-image: url('./login/logIn.svg');
}
.client .arrow {
  height: 20px;
  width: 21px;
  -webkit-mask-image: url('./login/arrowLeft.svg');
  mask-image: url('./login/arrowLeft.svg');
}
.client .plus {
  height: 32px;
  width: 32px;
  -webkit-mask-image: url('./login/plusIcon.svg');
  mask-image: url('./login/plusIcon.svg');
}
.client .button-log-in:hover .icon-log-in {
  opacity: 1;
  background-color: #d4fb00;
}
.client .button-log-in:hover .text-log-in {
  opacity: 1;
}
.client .btn-continue:hover .arrow {
  opacity: 1;
  background-color: #d4fb00;
}
.client .btn-continue:hover .plus {
  opacity: 1;
  background-color: #d4fb00;
}
.client .btn-continue:hover .text-btn {
  opacity: 1;
}
.client .errors {
  text-align: center;
  color: #fb6120;
  margin-top: 20px;
}
.client .eye {
  width: 19px;
  height: 15px;
}
.client .MuiFormLabel-root {
  color: rgba(255, 255, 255, 0.7) !important;
  left: -4px !important;
  top: 10px !important;
}
.client .label-error .MuiFormLabel-root,
.client .label-error .MuiFormLabel-root.Mui-focused {
  color: #fb6120 !important;
}
.client .MuiFormLabel-root.Mui-focused,
.client .label-error .MuiFormLabel-root.Mui-focused {
  color: #d4fb00 !important;
}
.client .MuiInputBase-input {
  color: #ffffff !important;
  padding: 8px 10px !important;
}
.client .MuiInputBase-input,
.client .MuiFormLabel-root {
  font-size: 14px !important;
}
.client .MuiInput-underline:before {
  border-bottom-color: rgba(255, 255, 255, 0.7) !important;
}
.client .MuiInput-underline.Mui-focused:after {
  border-bottom-color: #d4fb00 !important;
}
.client input:-webkit-autofill,
.client input:-webkit-autofill:hover,
.client input:-webkit-autofill:focus,
.client input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #ffffff;
}
.client .title {
  text-align: left;
  margin-bottom: 60px;
}
.client .title .title-1 {
  font-size: 48px;
  font-weight: 600;
  color: #fff;
}
.client .title .title-2 {
  font-size: 24px;
  opacity: 0.7;
  color: #fff;
}
.client .right-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}
.client .left-panel {
  border-right: 1px solid #ffffff4d;
  padding-left: 80px;
  padding-right: 80px;
}
.client .support {
  color: #d4fb00;
  padding-left: 5px;
  text-decoration: underline;
}
.client .support:hover {
  color: #d4fb00;
  opacity: 0.7;
}
.client .logo {
  margin-bottom: 54px;
}
.client .logo.logo-with-back {
  margin-top: 34px;
  margin-bottom: 25px;
}
.client .mobile-container-button,
.client .mobile-container-button-invite {
  display: none;
}
.client .button-reset {
  margin-top: 80%;
}
.client .button-reset:hover {
  background-color: #d4fb00;
}
.client .btn-select.btn-none.button-reset:hover {
  background-color: inherit;
}
.client .bottom-title-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  color: #d4fb00;
  font-size: 14px;
}
.client .col-md-9.form-container.col-md-5 {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.client .top-buttons {
  display: flex;
  justify-content: space-between;
  margin: 0px 46px 0px;
}
.client .top-buttons > * {
  cursor: pointer;
}
@media (max-width: 991px) {
  .client .mobile.right-panel {
    display: none;
  }
  .client .mobile-container-button-invite {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .client .button-log-in {
    margin-top: 10px;
  }
  .client .left-panel {
    border: none;
  }
  .client .right-panel {
    display: none;
  }
  .client .mobile-container-button {
    display: flex;
    justify-content: center;
  }
  .client .button-reset {
    margin: 10px auto 0;
    width: 200px;
  }
  .client .top-buttons {
    margin: 0px 30px 0px;
  }
}
@media (max-width: 460px) {
  .client .left-panel {
    padding: 0 30px;
  }
}
