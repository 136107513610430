.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.messages {
  position: absolute;
  bottom: 27px;
  right: 27px;
  z-index: 2050;
}
.messages .error-message {
  background-color: #ffffff;
  border: 1px solid #ef4834;
  border-top-width: 14px;
  border-radius: 11px;
  display: table;
  opacity: 0.95;
  padding: 15px 15px 20px 15px;
  width: 320px;
}
.messages .error-message:not(:first-child) {
  margin-top: 10px;
}
.messages .error-message > div {
  display: table-cell;
  vertical-align: middle;
}
.messages .error-message > div:first-child {
  width: 15%;
}
.messages .error-message > div:first-child img {
  height: 30px;
}
.messages .error-message > div:nth-child(2) {
  color: #3c4249;
  font-size: 16px;
  width: 75%;
}
.messages .error-message > div:nth-child(3) {
  width: 10%;
}
.messages .error-message > div:nth-child(3) img {
  cursor: pointer;
  height: 20px;
}
