.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.work-orders {
  position: relative;
}
.work-orders .full-title {
  padding-top: 37px;
}
.work-orders .content-container .button-add-work-order {
  z-index: 20;
}
.work-orders .content-container .button-add-work-order.on-top {
  position: absolute;
  right: 15px;
  top: 0;
}
.work-orders .content-container .empty-button-block {
  display: inline-block;
  margin-left: 15px;
  width: 150px;
}
.work-orders .content-container .search-bar {
  padding-left: 8px;
}
.work-orders .no-work-orders-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  color: #3c4249;
  font-weight: 300;
  margin-left: -20px;
  margin-right: -20px;
  padding-top: 150px;
  padding-right: 200px;
  z-index: 10;
}
.work-orders .no-work-orders-overlay :first-child {
  font-size: 34px;
  text-align: right;
  width: 100%;
}
.work-orders .no-work-orders-overlay :first-child img {
  height: 20px;
  margin: 0 0 0 10px;
  width: 25px;
}
.work-orders .no-work-orders-overlay :nth-child(2) {
  font-size: 24px;
  padding-right: 40px;
  text-align: right;
  width: 100%;
}
