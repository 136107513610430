.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.logo-upload .add-photos-button {
  background-color: #edf1f7;
  color: #3c4249;
  font-weight: normal;
  height: 45px;
  width: 200px;
}
.logo-upload .logo-upload-button-container {
  display: table;
  border: 1px dashed #9ea0aa;
}
.logo-upload .logo-upload-button-container > div {
  display: table-cell;
}
.logo-upload .logo-upload-button-container > div:nth-child(2) {
  vertical-align: top;
}
.logo-upload .logo-upload-button-container > div ul {
  list-style: none;
  margin: 0;
  padding-left: 20px;
}
.logo-upload .logo-upload-button-container > div ul > li {
  color: #9fa9ba;
  font-size: 14px;
}
.logo-upload .logo-upload-button-container .add-message {
  color: #454a5c;
  font-size: 16px;
}
.logo-upload .preview-container {
  display: flex;
  flex-wrap: wrap;
}
.logo-upload .preview-container > div {
  position: relative;
  width: 100px;
  height: 100px;
}
.logo-upload .preview-container > div .logoImage {
  width: 100%;
  height: 100%;
}
.logo-upload .preview-container > div .logoImage.containMode {
  background-color: #f7f7f8;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.logo-upload .preview-container > div:not(:hover) .remove-container {
  display: none;
}
.logo-upload .preview-container > div:hover .remove-container {
  background-color: rgba(255, 255, 255, 0.1);
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
}
.logo-upload .preview-container > div:hover .remove-container img {
  cursor: pointer;
  position: relative;
  transform: translateY(-50%);
  top: 50%;
  width: 40px;
}
.logo-upload .preview-container .img-full-screen {
  cursor: zoom-in;
}
.logo-upload.form-uploader {
  display: flex;
}
.logo-upload.form-uploader .preview-container {
  display: flex;
  align-content: center;
  justify-content: center;
}
.logo-upload.form-uploader .dropzone-container {
  display: flex;
  cursor: pointer;
  align-items: center;
  text-align: center;
  padding: 24px 40px;
}
.logo-upload.form-uploader .dropzone-container .add-message {
  margin-bottom: 0px;
  margin-left: 16px;
}
.logo-upload.form-uploader .logo-upload-row {
  width: 100%;
  height: 100%;
}
.logo-upload.form-uploader .logo-upload-row .files-dropzone {
  width: 100%;
  height: 100%;
}
.logo-upload.details-uploader .logo-upload-button-container {
  border: none;
  background-color: #ffffff;
}
.logo-upload.invalid .logo-upload-button-container {
  border-color: #fb6120;
}
.logo-upload.dark-mode .logo-upload-button-container {
  border-color: #909197;
}
.logo-upload.dark-mode .logo-upload-button-container .add-message {
  color: #ffffff;
}
.logo-upload.dark-mode .preview-container > div .logoImage.containMode {
  background-color: #21242f;
}
.logo-upload.dark-mode.details-uploader .logo-upload-button-container {
  background-color: #303441;
}
.logo-upload.dark-mode.invalid .logo-upload-button-container {
  border-color: #02c7ff;
}
