.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.project-open-item-details {
  padding: 0px 40px;
  overflow: auto;
  overflow: overlay;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
}
.project-open-item-details .project-open-item-request-title,
.project-open-item-details .project-open-item-response-title {
  margin-bottom: 16px;
  padding: 9px 0px;
}
.project-open-item-details .project-open-item-request-title .details-title-text,
.project-open-item-details .project-open-item-response-title .details-title-text {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
}
.project-open-item-details .project-open-item-request-title.project-open-item-response-title .details-title-text,
.project-open-item-details .project-open-item-response-title.project-open-item-response-title .details-title-text {
  color: #fb6120;
}
.project-open-item-details.dark-mode .project-open-item-response-title .details-title-text {
  color: #02c7ff;
}
