.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.user-status .base-status-color.active {
  background-color: #00b091;
}
.user-status .base-status-color.inactive,
.user-status .base-status-color.rejected {
  background-color: #fb6120;
}
.user-status .base-status-color.pending_invitation,
.user-status .base-status-color.registering,
.user-status .base-status-color.verifying {
  background-color: #bcbdc1;
}
