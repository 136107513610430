.project-location-details .content-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.project-location-details .content-container .information-container {
  display: flex;
  padding: 24px 40px 24px 40px;
}
.project-location-details .content-container .information-container .information-items {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.project-location-details .content-container .information-container .information-items .details-info-item {
  width: 33%;
  margin-bottom: 24px;
}
.project-location-details .content-container .information-container .information-items .details-info-item:not(:last-child) {
  padding-right: 16px;
}
.project-location-details .content-container .information-container .information-items .details-info-item.half-item {
  width: 12.5%;
}
.project-location-details .content-container .information-container .information-items .details-info-item .link-button {
  margin-top: 11px;
}
.project-location-details .content-container .information-container .information-items .details-info-item .contractor {
  list-style: square;
}
.project-location-details .content-container .resource-projects {
  margin-top: 16px;
  padding: 0px 16px;
}
.project-location-details .right-header > div:first-child > div {
  margin-left: 40px;
  width: auto;
}
