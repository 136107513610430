.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.companies .search-bar {
  margin-left: 24px;
}
.companies .search-bar .provider-selector {
  margin-right: 24px;
}
.companies .search-bar .provider-selector .popover-children {
  width: 260px !important;
}
.companies .content-container .pagination-container {
  margin-top: 16px;
  text-align: center;
}
