.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.field-project-report-log-table colgroup > col:nth-child(1) {
  width: 10%;
}
.field-project-report-log-table colgroup > col:nth-child(2) {
  width: 15%;
}
.field-project-report-log-table colgroup > col:nth-child(3) {
  width: 45%;
}
.field-project-report-log-table colgroup > col:nth-child(4) {
  width: 15%;
}
.field-project-report-log-table colgroup > col:nth-child(5) {
  width: 15%;
}
.field-project-report-log-table thead tr th,
.field-project-report-log-table tbody tr td {
  border: none !important;
  border-bottom: 0.5px solid #eaeaec !important;
}
.done-icon {
  margin-left: 5px;
  margin-bottom: 5px;
}
.row-block {
  display: flex;
  flex-direction: row;
}
.buttons-separate {
  margin-right: 12px;
}
.dark-mode .field-project-report-log-table thead tr th,
.dark-mode .field-project-report-log-table tbody tr td {
  border-bottom-color: rgba(255, 255, 255, 0.1) !important;
}
