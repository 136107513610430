.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.contractor-status {
  border-radius: 50%;
  display: inline-block;
  height: 9px;
  margin-right: 7px;
  width: 9px;
}
.contractor-status.active {
  background-color: #00b091;
}
.contractor-status.inactive {
  background-color: #fb6120;
}
