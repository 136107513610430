.user-permissions-form {
  margin-bottom: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.user-permissions-form .user-permissions-table {
  flex-grow: 1;
  overflow: auto;
  overflow: overlay;
}
.user-permissions-form .user-permissions-table .simple-table {
  flex-grow: 1;
}
.user-permissions-form .user-permissions-table .simple-table tbody tr td {
  padding-top: 12px;
  padding-bottom: 12px;
}
.user-permissions-form .user-permissions-table .simple-table thead tr th:first-child,
.user-permissions-form .user-permissions-table .simple-table tbody tr th:first-child,
.user-permissions-form .user-permissions-table .simple-table thead tr td:first-child,
.user-permissions-form .user-permissions-table .simple-table tbody tr td:first-child {
  width: 75px;
}
.user-permissions-form .user-permissions-table .simple-pagination .pagination li.active span {
  z-index: 0;
}
.user-permissions-form .user-permissions-filters {
  margin-bottom: 16px;
}
.user-permissions-form .user-permissions-filters .container {
  width: 100%;
  padding: 0;
}
.user-permissions-form .user-permissions-filters .container .row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.user-permissions-form .user-permissions-filters .permission-label {
  position: relative;
  padding-top: 6px;
}
.user-permissions-form .user-permissions-filters .permission-label .small-bold {
  position: absolute;
  top: -6px;
}
.user-permissions-form .user-permissions-filters .search-bar {
  margin-bottom: 0;
  width: 100%;
}
.user-permissions-form .user-permissions-filters .search-bar .search-bar-form {
  width: 100%;
}
.user-permissions-form .user-permissions-filters .search-bar .search-bar-form .search-input,
.user-permissions-form .user-permissions-filters .search-bar .search-bar-form .input-group,
.user-permissions-form .user-permissions-filters .search-bar .search-bar-form .form-group {
  width: 100%;
}
