.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.coi-exp th,
.coi-exp td {
  border: none !important;
}
.coi-exp .coi-exp-header,
.coi-exp .coi-exp-container {
  border-bottom: 1px solid #eaeaec !important;
}
.coi-exp .coi-exp-header.show-form,
.coi-exp .coi-exp-container.show-form {
  border: none !important;
}
.coi-exp .coi-exp-header {
  cursor: pointer;
}
.coi-exp .coi-exp-header .coi-exp-table thead {
  display: none;
}
.coi-exp .coi-exp-header .coi-exp-toggle-container {
  display: flex;
}
.coi-exp .coi-exp-header .coi-exp-toggle-container .coi-exp-toggle-title {
  margin: 0px;
  font-size: 16px;
  line-height: 24px;
  color: #454a5c;
}
.coi-exp .coi-exp-header .coi-exp-toggle-container .coi-exp-toggle-title .coi-exp-total {
  margin-left: 5px;
  font-size: 14px;
  font-weight: bold;
}
.coi-exp .coi-exp-header .coi-exp-toggle-container .coi-exp-toggle {
  margin-left: 10px;
  font-size: 16px;
  color: #00b091;
}
.coi-exp .coi-exp-header .expires-soon {
  font-weight: bold;
}
.coi-exp .coi-exp-header .expired {
  color: #fb6120;
}
.coi-exp.dark-mode .coi-exp-header,
.coi-exp.dark-mode .coi-exp-container {
  border-color: rgba(255, 255, 255, 0.05) !important;
}
.coi-exp.dark-mode .coi-exp-header .coi-exp-toggle-container .coi-exp-toggle-title {
  color: #ffffff;
}
.coi-exp.dark-mode .coi-exp-header .coi-exp-toggle-container .coi-exp-toggle {
  margin-left: 10px;
  font-size: 16px;
  color: #d4fb00;
}
.coi-exp.dark-mode .coi-exp-header .expired {
  color: #02c7ff;
}
