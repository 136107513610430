.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.rfi-response-modal .modal-dialog {
  display: flex;
  justify-content: center;
  width: auto;
  max-width: 90%;
}
.rfi-response-modal .modal-container {
  width: 90vw !important;
  max-width: 975px;
}
.rfi-response-modal .sidebar-closed + .modal-container {
  width: calc(90vw - 106px) !important;
}
.rfi-response-modal .sidebar-open + .modal-container {
  width: calc(90vw - 480px) !important;
}
.rfi-response-modal .rfi-body {
  padding: 0px 40px;
  overflow: auto;
  overflow: overlay;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
}
.rfi-response-modal.create-rfi .modal-dialog {
  max-width: 975px;
}
.rfi-response-modal .error-message {
  margin-right: 40px;
}
.rfi-response-modal-response-container {
  margin-top: 20px;
  padding-left: 35px;
  padding-right: 20px;
}
.rfi-response-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
  padding-bottom: 40px;
  gap: 20px;
}
