.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.closeout-form {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 80px;
  padding: 0px 24px;
}
.closeout-form .form-control-block {
  margin-right: 24px;
}
.closeout-form .form-control-block .date-picker {
  max-width: 130px;
}
.closeout-form .form-control-block:last-child {
  margin-right: 0;
}
.closeout-form .closeout-form-buttons {
  margin-left: 16px;
}
.closeout-form .closeout-form-buttons.download-documents {
  margin-bottom: 15px;
}
.project-closeout-requirement-body {
  padding-left: 16px;
}
@media (min-width: 1580px) {
  .project-closeout-requirement-body .closeout-form {
    position: absolute;
    right: 40px;
    top: -75px;
  }
}
@media (min-width: 1320px) {
  .project-closeout-body .closeout-form {
    position: absolute;
    right: 40px;
    top: -75px;
  }
}
