.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.project-photos-detail-modal {
  padding-right: 470px;
  display: flex;
  align-items: center;
}
.project-photos-detail-modal .detail-backdrop {
  backdrop-filter: blur(4px);
}
.project-photos-detail-modal .right-form {
  transition: all ease-in-out 300ms;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
}
.project-photos-detail-modal .right-form .close-space {
  height: 68px;
  top: 0;
  right: 0;
  position: sticky;
  position: -webkit-sticky;
  z-index: 100;
}
.project-photos-detail-modal .main-content {
  height: calc(100% - 80px);
  width: calc(100% - 72px);
  flex-grow: 1;
  margin-left: 40px;
  margin-right: 32px;
}
.project-photos-detail-modal .main-content .project-photos-carousel {
  width: 100%;
  height: calc(100% - 60px);
}
.project-photos-detail-modal .main-content .project-photos-carousel .carousel-inner {
  height: 100%;
}
.project-photos-detail-modal .main-content .project-photos-carousel .carousel-inner .item {
  height: 100%;
}
.project-photos-detail-modal .main-content .project-photos-carousel .carousel-inner .item > div {
  width: 100%;
  height: 100%;
}
.project-photos-detail-modal .main-content .project-photos-carousel .carousel-inner .item > div img,
.project-photos-detail-modal .main-content .project-photos-carousel .carousel-inner .item > div video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.project-photos-detail-modal .main-content .project-photos-carousel .carousel-inner .carousel-zoom-controls {
  position: absolute;
  right: 74px;
  top: 2px;
  z-index: 100;
  width: auto;
  padding: 14px 8px;
}
.project-photos-detail-modal .main-content .project-photos-carousel .carousel-inner .carousel-zoom-controls button {
  cursor: pointer;
  padding: 8px 10px;
  font-size: 17px;
  color: #7a7e8b;
}
.project-photos-detail-modal .main-content .project-photos-carousel .carousel-control {
  width: 66px;
  background: #eaeaec;
  color: #7a7e8b;
}
.project-photos-detail-modal .main-content .detail-video .detail-video-control-bar {
  height: 5em;
  background-color: #eaeaec;
  color: #7a7e8b;
}
.project-photos-detail-modal .main-content .detail-video .detail-video-control-bar .video-react-slider,
.project-photos-detail-modal .main-content .detail-video .detail-video-control-bar .video-react-load-progress,
.project-photos-detail-modal .main-content .detail-video .detail-video-control-bar .video-react-load-progress div {
  background-color: #ffffff;
}
.project-photos-detail-modal .main-content .detail-video .detail-video-control-bar .video-react-volume-level,
.project-photos-detail-modal .main-content .detail-video .detail-video-control-bar .video-react-play-progress {
  background-color: #7a7e8b;
}
.project-photos-detail-modal .main-content .detail-video .detail-video-control-bar .video-react-play-progress:after {
  background-color: #ffffff;
  color: #454a5c;
  font-family: Titillium Web, sans-serif !important;
  font-size: 0.8em;
  top: -2.7em;
  padding-bottom: 6px;
}
.project-photos-detail-modal .main-content .detail-video .detail-video-control-bar .video-react-volume-bar {
  margin: 2.35em 0.45em;
}
.project-photos-detail-modal .main-content .detail-video .detail-video-control-bar .video-react-control {
  min-width: 4.8em;
}
.project-photos-detail-modal .main-content .detail-video .detail-video-control-bar .video-react-control:before {
  font-size: 2.2em;
  line-height: 2.35;
}
.project-photos-detail-modal .main-content .detail-video .detail-video-play-button {
  background-color: #eaeaec;
  border-radius: 50%;
  width: 3em;
  height: 3em;
}
.project-photos-detail-modal .main-content .detail-video .detail-video-play-button::before {
  font-size: 2.8em;
  color: #7a7e8b;
  top: 0.25em;
}
.project-photos-detail-modal .main-content .slider-menu img {
  width: 22px;
  height: 22px;
}
.project-photos-detail-modal .main-content .file-editor {
  width: 100%;
  height: 100%;
}
.project-photos-detail-modal .main-content .file-editor .FIE_tools-bar-wrapper label {
  margin-bottom: 0;
}
.project-photos-detail-modal .main-content .file-editor .FIE_topbar-save-button span,
.project-photos-detail-modal .main-content .file-editor input {
  font-family: Titillium Web, sans-serif !important;
}
.project-photos-detail-modal .main-content .file-editor .FIE_topbar-save-button span {
  font-size: 13px !important;
  color: #ffffff;
}
.project-photos-detail-modal .main-content .file-editor .FIE_text-font-family-option,
.project-photos-detail-modal .main-content .file-editor .FIE_image-tool-button,
.project-photos-detail-modal .main-content .file-editor .FIE_annotation-option-triggerer[title='Shadow'],
.project-photos-detail-modal .main-content .file-editor .FIE_annotation-option-triggerer[title='Position'] {
  display: none;
}
.project-photos-detail-modal .main-content .file-editor .FIE_topbar-close-button {
  margin-top: 4px;
}
.project-photos-detail-modal .main-content .file-editor .FIE_topbar-close-button svg {
  width: 28px;
  height: 28px;
}
.project-photos-detail-modal .close-button {
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 101;
}
.project-photos-detail-modal .toggle-form-button {
  position: absolute;
  z-index: 101;
  padding: 8px;
  top: 16px;
  right: 0px;
  background-color: #eaeaec;
}
.project-photos-detail-modal .toggle-form-button img {
  transform: rotate(90deg);
}
.project-photos-detail-modal.show-form .toggle-form-button {
  right: 438px;
}
.project-photos-detail-modal.show-form .toggle-form-button img {
  transform: rotate(270deg);
}
.project-photos-detail-modal.hide-form {
  padding-right: 0px;
}
.project-photos-detail-modal.hide-form .right-form {
  width: 0;
}
.project-photos-detail-modal.hide-form .main-content {
  margin-right: 40px;
  width: calc(100% - 80px);
}
.project-photos-detail-modal.hide-form .close-button {
  top: 64px;
  right: 64px;
}
#SfxPopper *,
.SfxModal-Wrapper * {
  font-family: Titillium Web, sans-serif !important;
}
#SfxPopper button span,
.SfxModal-Wrapper button span,
#SfxPopper .SfxMenuItem-wrapper *,
.SfxModal-Wrapper .SfxMenuItem-wrapper * {
  font-family: Titillium Web, sans-serif !important;
  color: #454a5c;
}
#SfxPopper .SfxSlider-root,
.SfxModal-Wrapper .SfxSlider-root {
  color: #00b091;
}
#SfxPopper button[color='primary'] span,
.SfxModal-Wrapper button[color='primary'] span {
  color: #ffffff;
}
.dark-mode .project-photos-detail-modal .toggle-form-button {
  background-color: #3b4050;
}
.dark-mode .project-photos-detail-modal .project-photos-carousel .carousel-control {
  background: #3b4050;
  color: #ffffff;
}
.dark-mode .project-photos-detail-modal .detail-video .detail-video-control-bar {
  background-color: #3b4050;
  color: #ffffff;
}
.dark-mode .project-photos-detail-modal .detail-video .detail-video-control-bar .video-react-slider,
.dark-mode .project-photos-detail-modal .detail-video .detail-video-control-bar .video-react-load-progress,
.dark-mode .project-photos-detail-modal .detail-video .detail-video-control-bar .video-react-load-progress div {
  background-color: #7a7e8b;
}
.dark-mode .project-photos-detail-modal .detail-video .detail-video-control-bar .video-react-volume-level,
.dark-mode .project-photos-detail-modal .detail-video .detail-video-control-bar .video-react-play-progress {
  background-color: #ffffff;
}
.dark-mode .project-photos-detail-modal .detail-video .detail-video-control-bar .video-react-play-progress:after {
  background-color: #ffffff;
  color: #454a5c;
}
.dark-mode .project-photos-detail-modal .detail-video .detail-video-control-bar .video-react-control {
  text-shadow: none;
}
.dark-mode .project-photos-detail-modal .detail-video .detail-video-play-button {
  background-color: #3b4050;
}
.dark-mode .project-photos-detail-modal .detail-video .detail-video-play-button::before {
  color: #ffffff;
}
.dark-mode .project-photos-detail-modal .file-editor .FIE_topbar-save-button span {
  color: #454a5c;
}
.dark-mode #SfxPopper button span,
.dark-mode .SfxModal-Wrapper button span,
.dark-mode #SfxPopper .SfxMenuItem-wrapper *,
.dark-mode .SfxModal-Wrapper .SfxMenuItem-wrapper * {
  color: #ffffff;
}
.dark-mode #SfxPopper .SfxSlider-root,
.dark-mode .SfxModal-Wrapper .SfxSlider-root {
  color: #d4fb00;
}
.dark-mode #SfxPopper button[color='primary'] span,
.dark-mode .SfxModal-Wrapper button[color='primary'] span {
  color: #454a5c;
}
