.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.project-photos .search-bar-right-content {
  margin-right: 0;
}
.project-photos .search-bar-right-content .upload-photos-button img {
  width: 22px;
  height: 18px;
}
.project-photos .main-content {
  padding: 8px 40px;
}
.project-photos .main-content .project-photos-header {
  margin: 8px 0;
}
.project-photos .main-content .project-photos-header .search-bar {
  margin: 0 16px 0 0;
}
.project-photos .main-content .project-photos-header .filter-header.sort-filter {
  position: relative;
  top: 1px;
}
.project-photos .main-content .project-photos-header .filter-header.sort-filter .filter-header-title {
  display: none;
}
.project-photos .main-content .project-photos-header .filter-header.sort-filter .filter-header-value.selected-value {
  font-size: 16px;
  margin-right: 8px;
}
.project-photos .main-content .project-photos-header .filter-header.sort-filter .header-label-icon-container {
  width: auto;
  height: auto;
}
.project-photos .main-content .project-photos-header .filter-header.sort-filter .header-label-icon-container .header-label-icon {
  width: 18px;
  height: 12px;
}
.project-photos .main-content .more-container {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}
.project-photos .main-content .more-container button {
  font-size: 14px;
}
.project-photos .main-content .project-photos-content .empty-message img {
  opacity: 0.7;
  width: 100px;
}
.project-photos-filter-modal {
  position: relative;
}
.project-photos-filter-modal .filter-modal-header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  left: 0;
  z-index: 1;
}
.project-photos-filter-modal .filter-modal-footer {
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}
.project-photos-filter-modal .filter-modal-footer button {
  width: 100%;
}
