.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.base-status {
  text-transform: capitalize;
}
.base-status .base-status-color {
  border-radius: 50%;
  display: inline-block;
  height: 9px;
  margin-right: 7px;
  width: 9px;
}
