.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.radio-button {
  font-size: 16px;
  align-self: center;
  display: flex;
  justify-content: center;
}
.radio-button.radio-button-vertical {
  display: table;
}
.radio-button.radio-button-horizontal-row {
  display: flex;
}
.radio-button .radiobutton-control {
  cursor: pointer;
  height: 18px;
  width: 18px;
  border: 2px solid #9ea0aa;
  border-radius: 100px;
  background-color: #9ea0aa;
}
.radio-button .radiobutton-control.checked {
  border-color: #00b091;
  background: radial-gradient(#00b091 4px, rgba(255, 0, 0, 0) 5px);
}
.radio-button .radiobutton-control.read-only {
  cursor: default;
}
.radio-button span {
  margin-left: 8px;
  vertical-align: middle;
  color: #454a5c;
  cursor: pointer;
}
.radio-button.invalid span {
  color: #fb6120;
}
.radio-button.dark-mode .orientation-simple.vertical:hover {
  background-color: #43495a;
}
.radio-button.dark-mode .radiobutton-control {
  border-color: #909197;
}
.radio-button.dark-mode .radiobutton-control.checked {
  border-color: #d4fb00;
  background: radial-gradient(#d4fb00 4px, rgba(255, 0, 0, 0) 5px);
}
.radio-button.dark-mode.invalid span {
  color: #02c7ff;
}
.radio-button.dark-mode span {
  color: #ffffff;
}
