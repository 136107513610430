.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.review-corrections-modal .modal-dialog {
  width: 50%;
}
.review-corrections-modal .modal-dialog .modal-content {
  background-color: #f7f7f8;
}
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-header .modal-header-title-label {
  width: 100%;
}
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-header .modal-header-title-label .search-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-header .modal-header-title-label .search-bar .search-bar-title {
  font-weight: 400;
  font-size: 20px;
}
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-header .modal-header-title-label .search-bar .search-bar-form .search-input {
  font-weight: 400;
}
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-body {
  padding: 0 28px;
  margin-top: -1px;
  padding-bottom: 12px;
  height: 70vh;
}
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-body .review-corrections-table > tbody > tr > td,
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-body .review-corrections-table > thead > tr > td,
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-body .review-corrections-table > tbody > tr > th,
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-body .review-corrections-table > thead > tr > th {
  padding: 12px;
}
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-body .review-corrections-table > tbody > tr > td > span,
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-body .review-corrections-table > thead > tr > td > span,
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-body .review-corrections-table > tbody > tr > th > span,
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-body .review-corrections-table > thead > tr > th > span {
  width: 120px;
  display: inline-block;
}
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-body .review-corrections-table > tbody > tr > td:nth-child(1) > span,
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-body .review-corrections-table > thead > tr > td:nth-child(1) > span,
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-body .review-corrections-table > tbody > tr > th:nth-child(1) > span,
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-body .review-corrections-table > thead > tr > th:nth-child(1) > span {
  width: 200px;
}
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-body .review-corrections-table > tbody > tr > td:nth-child(5),
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-body .review-corrections-table > thead > tr > td:nth-child(5),
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-body .review-corrections-table > tbody > tr > th:nth-child(5),
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-body .review-corrections-table > thead > tr > th:nth-child(5) {
  padding-left: 0 !important;
}
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-body .review-corrections-table > tbody > tr > td:nth-child(5) > span,
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-body .review-corrections-table > thead > tr > td:nth-child(5) > span,
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-body .review-corrections-table > tbody > tr > th:nth-child(5) > span,
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-body .review-corrections-table > thead > tr > th:nth-child(5) > span {
  width: 160px;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  top: 3px;
}
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-body .review-corrections-table > thead > tr > th {
  vertical-align: middle;
  padding-top: 8px;
  padding-bottom: 6px;
}
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-body .review-corrections-table > thead > tr > th span {
  display: inline-block;
  line-height: 20px;
  word-spacing: 1000px;
}
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-body .review-corrections-table tbody tr border:none td {
  height: 10px;
}
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-body .review-corrections-table tbody tr border:none td span {
  white-space: nowrap;
}
.review-corrections-modal .modal-dialog .modal-content .modal-container .modal-body .review-corrections-table tbody tr:last-child td {
  border-bottom: none;
}
.meeting-title-and-number {
  font-size: 18px;
  font-weight: 600;
}
.explanation {
  margin-top: 20px;
  margin-bottom: 20px;
}
.approve-options {
  max-width: 500px;
  justify-content: flex-start;
}
.approve-options.radio-button .radiobutton-control {
  margin-top: 2px;
  padding-left: 0px !important;
}
.corrections-action-status-container {
  padding-left: 0 !important;
}
.correction-row-style {
  height: 10px;
}
.correction-row-style.first-child {
  max-width: 50px;
}
.custom-table-style tr td {
  padding-top: 5px !important;
  border: 0 black solid !important;
  padding-bottom: 5px !important;
  width: 500px;
}
.custom-table-style tr td:first-child {
  height: 20px;
}
.custom-table-style tr td:nth-child(2) {
  width: 1250px;
  height: 20px;
}
.custom-table-style tr th:last-child {
  padding-left: 30px !important;
}
.button-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: -1px;
  left: 0;
  height: 90px;
  background-color: #f7f7f8;
  gap: 40px;
}
.button-actions .button:first-child {
  margin-right: 16px;
}
.button-actions .button.button-white {
  background-color: transparent;
}
.custom-table-style .edit-mode-row > td:nth-child(5) {
  padding-left: 100 !important;
  border: 1px solid red;
}
.attempt {
  display: flex;
  justify-content: flex-start;
}
.radio-buttons-cell {
  padding-left: 0 !important;
}
