.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.dropdown .Select-control {
  height: 40px;
}
.dropdown .Select-control .Select-input {
  height: 40px;
}
.dropdown .Select-control .Select-input input {
  height: 40px;
}
.dropdown .Select-control .Select-placeholder {
  line-height: 40px;
}
.dropdown .Select-control .Select-value-label {
  line-height: 40px;
}
.dropdown .Select .Select-control {
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  background: transparent !important;
  color: #454a5c;
  box-shadow: none !important;
  outline: none !important;
  border: none;
  border-radius: unset;
  border-bottom: 1px solid #7a7e8b;
}
.dropdown .Select .Select-control .Select-input {
  background: transparent !important;
}
.dropdown .Select .Select-control .Select-input input {
  padding: 0;
}
.dropdown .Select .Select-control .Select-value-label {
  color: #454a5c !important;
}
.dropdown .Select .Select-control .Select-placeholder {
  padding-left: 8px;
  color: #454a5c;
  opacity: 0.7;
}
.dropdown .Select .Select-control .Select-value {
  padding-left: 8px;
}
.dropdown .Select .Select-control .Select-clear-zone {
  color: #7a7e8b;
}
.dropdown .Select .Select-control .Select-clear-zone:hover {
  color: #00b091;
}
.dropdown .Select.is-clearable .Select-value {
  padding-right: 45px;
}
.dropdown .Select.is-focused .Select-control {
  border-color: #00b091 !important;
}
.dropdown .Select.is-focused .Select-control .Select-placeholder {
  color: #00b091;
  opacity: 1;
}
.dropdown .Select .Select-menu-outer {
  border: none;
  margin-top: 5px;
}
.dropdown .Select .Select-menu-outer .Select-menu {
  max-height: 336px;
  background-color: #ffffff;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
  overflow: auto;
  overflow: overlay;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
}
.dropdown .Select .Select-menu-outer .Select-menu .Select-option {
  display: flex;
  flex-direction: column;
  padding: 12px 16px 10px 12px;
  font-size: 16px;
  line-height: 24px;
  background-color: #ffffff;
}
.dropdown .Select .Select-menu-outer .Select-menu .Select-option:after {
  content: '';
  width: calc(100% + 8px);
  margin: 10px 0px -10px -4px;
  border-bottom: 1px solid rgba(69, 74, 92, 0.08);
}
.dropdown .Select .Select-menu-outer .Select-menu .Select-option:last-child:after {
  display: none;
}
.dropdown .Select .Select-menu-outer .Select-menu .Select-option.is-selected {
  background-color: #ffffff;
  color: #00b091;
}
.dropdown .Select .Select-menu-outer .Select-menu .Select-option.is-selected:after {
  border-color: #00b091;
}
.dropdown .Select .Select-menu-outer .Select-menu .Select-option.is-focused {
  background-color: #f7f7f8;
}
.dropdown.invalid .Select .Select-control {
  border-color: #fb6120 !important;
}
.dropdown.invalid .Select .Select-control .Select-placeholder {
  color: #fb6120 !important;
  opacity: 1;
}
.dropdown.invalid .Select .Select-menu-outer .Select-menu .Select-option.is-selected {
  color: #fb6120;
}
.dropdown.invalid .Select .Select-menu-outer .Select-menu .Select-option.is-selected:after {
  border-color: #fb6120;
}
.dropdown.dark-mode .Select .Select-control {
  color: #ffffff;
  border-color: #bcbdc1;
}
.dropdown.dark-mode .Select .Select-control .Select-value-label {
  color: #ffffff !important;
}
.dropdown.dark-mode .Select .Select-control .Select-placeholder {
  color: #ffffff;
}
.dropdown.dark-mode .Select .Select-control .Select-clear-zone {
  color: #bcbdc1;
}
.dropdown.dark-mode .Select .Select-control .Select-clear-zone:hover {
  color: #d4fb00;
}
.dropdown.dark-mode .Select.is-focused .Select-control {
  border-color: #d4fb00 !important;
}
.dropdown.dark-mode .Select.is-focused .Select-control .Select-placeholder {
  color: #d4fb00;
}
.dropdown.dark-mode .Select .Select-menu-outer {
  border-color: #191b24;
}
.dropdown.dark-mode .Select .Select-menu-outer .Select-menu {
  background-color: #303441;
  scrollbar-color: rgba(86, 93, 114, 0.3) rgba(0, 0, 0, 0);
}
.dropdown.dark-mode .Select .Select-menu-outer .Select-menu .Select-option {
  background-color: #303441;
  color: #ffffff;
}
.dropdown.dark-mode .Select .Select-menu-outer .Select-menu .Select-option:after {
  border-color: rgba(255, 255, 255, 0.05);
}
.dropdown.dark-mode .Select .Select-menu-outer .Select-menu .Select-option.is-selected {
  color: #d4fb00;
}
.dropdown.dark-mode .Select .Select-menu-outer .Select-menu .Select-option.is-selected:after {
  border-color: #d4fb00;
}
.dropdown.dark-mode .Select .Select-menu-outer .Select-menu .Select-option.is-focused {
  background-color: #363a46;
}
.dropdown.dark-mode.invalid .Select .Select-control {
  border-color: #02c7ff !important;
}
.dropdown.dark-mode.invalid .Select .Select-control .Select-placeholder {
  color: #02c7ff !important;
}
.dropdown.dark-mode.invalid .Select .Select-menu-outer .Select-menu .Select-option.is-selected {
  color: #02c7ff;
}
.dropdown.dark-mode.invalid .Select .Select-menu-outer .Select-menu .Select-option.is-selected:after {
  border-color: #02c7ff;
}
.basic-modal .dropdown.dark-mode .Select .Select-menu-outer .Select-menu {
  background-color: #3b4050;
}
.basic-modal .dropdown.dark-mode .Select .Select-menu-outer .Select-menu .Select-option {
  background-color: #3b4050;
}
.basic-modal .dropdown.dark-mode .Select .Select-menu-outer .Select-menu .Select-option.is-focused {
  background-color: #43495a;
}
