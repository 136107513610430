.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.hyperlinks-table {
  background: #ffffff;
  margin: 24px 40px 0px 40px;
  padding: 24px 0px;
}
.hyperlinks-table .navigation-bar {
  padding: 0px 40px;
  margin: 0px;
}
.hyperlinks-table .navigation-bar .navigation-item {
  border: none;
}
.hyperlinks-table .hyperlinks-table-container {
  padding: 0px 16px;
}
.hyperlinks-table .hyperlinks-table-container .hyperlink-link {
  color: #454a5c;
}
.hyperlinks-table .hyperlinks-table-container .td-empty-table {
  height: auto;
}
.hyperlinks-table .hyperlink-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}
.hyperlinks-table .hyperlink-form .form-control {
  width: 470px;
  margin-bottom: 24px;
}
.hyperlinks-table.can-remove-doc th:first-child {
  padding-left: 90px !important;
}
.hyperlinks-table.can-remove-doc td:first-child {
  padding-left: 40px !important;
}
.hyperlinks-table.can-remove-doc td:first-child .remove-doc-icon {
  margin-right: 36px;
  cursor: pointer;
}
.hyperlinks-table.dark-mode {
  background: #303441;
}
.hyperlinks-table.dark-mode .hyperlinks-table-container .hyperlink-link {
  color: #ffffff;
}
