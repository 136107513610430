.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.sidebar-item-row {
  display: table-row;
}
.sidebar-item-row .sidebar-item-cell {
  cursor: pointer;
  display: table-cell;
  height: 55px;
  padding-left: 30px;
  vertical-align: middle;
}
.sidebar-item-row .sidebar-item-cell.selected {
  background-color: #1c232a;
}
.sidebar-item-row .sidebar-item-cell > img {
  width: 20px;
}
.sidebar-item-row .sidebar-item-cell > span {
  color: #8e9aae;
  font-size: 15px;
  font-weight: 600;
  margin-left: 15px;
  vertical-align: middle;
}
.sidebar-item-row .sidebar-item-cell > span.selected {
  color: #ffffff;
}
