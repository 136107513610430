.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.edit-company .content-container {
  display: flex;
  flex-direction: column;
  padding: 24px 40px !important;
}
.edit-company .company-form-row {
  display: flex;
}
.edit-company .details-title {
  margin-bottom: 24px;
  padding: 0px;
}
.edit-company .form-group {
  margin-bottom: 24px;
}
.edit-company .edit-company-form {
  width: 60%;
  padding-left: 0px;
}
.edit-company .special-note {
  display: flex;
  justify-content: flex-end;
  width: 40%;
  padding-right: 0px;
}
.edit-company .logo-upload {
  margin-left: 16px;
}
.edit-company .stamp {
  margin: 0px;
}
