.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.coi-exps .coi-exps-nav {
  display: none;
  margin-top: 24px;
  padding-left: 40px;
}
.coi-exps .coi-exps-table {
  margin-top: 24px;
  padding: 0px 16px;
}
.coi-exps .coi-exps-table colgroup > col:nth-child(1) {
  width: 60%;
}
.coi-exps .coi-exps-table colgroup > col:nth-child(2) {
  width: 10%;
}
.coi-exps .coi-exps-table colgroup > col:nth-child(3) {
  width: 10%;
}
.coi-exps .coi-exps-table colgroup > col:nth-child(4) {
  width: 10%;
}
.coi-exps .coi-exps-table colgroup > col:nth-child(5) {
  width: 10%;
}
