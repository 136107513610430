.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.simple-table {
  margin-bottom: 0;
}
.simple-table.sticky-header {
  position: relative;
}
.simple-table.sticky-header thead th {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  top: 0;
  background-color: #f7f7f8;
  z-index: 1;
}
.simple-table thead tr th {
  padding-top: 13px;
  padding-bottom: 13px;
  border-bottom: 1px solid #eaeaec;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #9ea0aa;
}
.simple-table thead tr th:first-child {
  padding-left: 24px;
}
.simple-table tbody tr.selectable {
  cursor: pointer;
}
.simple-table tbody tr:first-child td {
  border-top: none;
}
.simple-table tbody tr:not(:first-child) td {
  border-top: 1px solid #d2d5d9;
}
.simple-table tbody tr.last-row:last-child td {
  border-bottom: 1px solid #d2d5d9;
}
.simple-table tbody tr.link-tab td > a {
  color: inherit;
  display: block;
  text-decoration: none;
}
.simple-table tbody tr td {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eaeaec;
  vertical-align: middle;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #454a5c;
}
.simple-table tbody tr td:first-child {
  padding-left: 24px;
}
.simple-table tbody tr td.read {
  color: #9ea0aa;
}
.simple-table tbody tr td .clickable {
  color: #00b091;
}
.simple-table tbody tr td .total-row {
  font-weight: bold;
  color: #00b091;
}
.simple-table tbody tr td .base-status-color.unread {
  background-color: #fb6120;
  margin-left: -16px;
  margin-right: 7px;
}
.simple-table tbody tr td .action-buttons {
  display: flex;
  align-items: center;
}
.simple-table tbody tr td .action-buttons > *:not(:last-child) {
  margin-right: 12px;
}
.simple-table tbody tr td .more-options .menu-image-sector-container .menu-image-sector {
  vertical-align: unset;
}
.simple-table .td-empty-table {
  text-align: center;
  height: 200px;
  vertical-align: middle;
  font-size: 24px;
  letter-spacing: -0.6px;
  color: rgba(69, 74, 92, 0.5);
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.simple-table.stretch-table tbody tr td {
  padding-top: 10px;
  padding-bottom: 10px;
}
.simple-table.dark-mode thead > tr > th {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.05);
}
.simple-table.dark-mode tbody tr td {
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.05);
}
.simple-table.dark-mode tbody tr td.read {
  color: rgba(255, 255, 255, 0.5);
}
.simple-table.dark-mode tbody tr td .clickable {
  color: #d4fb00;
}
.simple-table.dark-mode tbody tr td .total-row {
  color: #d4fb00;
}
.simple-table.dark-mode tbody tr td .base-status-color.unread {
  background-color: #02c7ff;
}
.simple-table.dark-mode tbody tr.link-tab td > a {
  color: #ffffff;
}
.simple-table.dark-mode tbody tr.link-tab td.read > a {
  color: rgba(255, 255, 255, 0.5);
}
.simple-table.dark-mode .td-empty-table {
  color: rgba(255, 255, 255, 0.5);
}
.simple-table.dark-mode.sticky-header thead,
.simple-table.dark-mode.sticky-header th {
  background-color: #21242f;
}
.basic-table thead tr th {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
  border-bottom: 1px solid rgba(69, 74, 92, 0.08) !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: rgba(69, 74, 92, 0.5) !important;
}
.basic-table thead tr th:first-child {
  padding-left: 24px !important;
}
.basic-table tbody tr td {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  border-bottom: 1px solid rgba(69, 74, 92, 0.08) !important;
  vertical-align: middle !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #454a5c !important;
}
.basic-table tbody tr td:first-child {
  padding-left: 24px !important;
}
.basic-table tbody tr td .clickable {
  color: #00b091;
}
.basic-table tbody tr td .total-row {
  color: #00b091;
}
.basic-table tbody tr td .base-status-color.unread {
  background-color: #fb6120;
  margin-left: -16px;
  margin-right: 7px;
}
.basic-table .empty-table-message {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  font-size: 24px;
  letter-spacing: -0.6px;
  color: rgba(69, 74, 92, 0.5);
}
.basic-table.dark-mode thead > tr > th {
  color: rgba(255, 255, 255, 0.5) !important;
  border-color: rgba(255, 255, 255, 0.05) !important;
  background-color: #21242f;
}
.basic-table.dark-mode tbody tr td {
  color: #ffffff !important;
  border-color: rgba(255, 255, 255, 0.05) !important;
}
.basic-table.dark-mode tbody tr td .clickable {
  color: #d4fb00;
}
.basic-table.dark-mode tbody tr td .total-row {
  color: #d4fb00;
}
.basic-table.dark-mode tbody tr td .base-status-color.unread {
  background-color: #02c7ff;
}
.basic-table.dark-mode .empty-table-message {
  color: rgba(255, 255, 255, 0.5);
}
