.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.meeting-title-and-number {
  font-size: 18px;
  font-weight: 600;
}
.explanation {
  margin-top: 20px;
  margin-bottom: 20px;
}
.approve-options {
  max-width: 500px;
  justify-content: flex-start;
}
.approve-options.radio-button .radiobutton-control {
  margin-top: 2px;
  padding-left: 0px !important;
}
.corrections-action-status-container {
  padding-left: 0 !important;
}
.correction-row-style {
  height: 10px;
}
.correction-row-style.first-child {
  max-width: 50px;
}
.instance-custom-table-style tr td {
  padding-top: 5px !important;
  border: 0 black solid !important;
  padding-bottom: 5px !important;
  width: 2000px;
  vertical-align: top !important;
}
.instance-custom-table-style tr th {
  width: 2000px;
}
.instance-custom-table-style tr th:nth-child(2) {
  width: 3500px;
}
.instance-custom-table-style tr td:first-child {
  height: 20px;
  width: 200px;
  vertical-align: top !important;
}
.instance-custom-table-style tr td:nth-child(2) {
  width: 6500px;
  height: 20px;
  vertical-align: top !important;
}
.instance-custom-table-style tr th:nth-child(5) {
  padding-left: 10px !important;
}
.instance-custom-table-style tr th:last-child {
  padding-left: 30px !important;
}
.button-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: -1px;
  left: 0;
  height: 90px;
  background-color: #f7f7f8;
  gap: 40px;
}
.button-actions .button:first-child {
  margin-right: 16px;
}
.button-actions .button.button-white {
  background-color: transparent;
}
.instance-custom-table-style .edit-mode-row > td:nth-child(5) {
  padding-left: 100 !important;
  border: 1px solid red;
}
.attempt {
  display: flex;
  justify-content: flex-start;
}
.radio-buttons-cell {
  padding-left: 0 !important;
}
