.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.update-files-modal .files-title {
  margin-bottom: 15px;
  margin-top: 25px;
}
.update-files-modal .document-upload {
  margin: 8px 40px 24px 40px;
}
.update-files-modal .buttons-container {
  margin-top: 0px !important;
  margin-bottom: 50px;
}
.update-files-modal .files-viewer {
  padding: 0px 40px 16px 40px;
}
.update-files-modal .files-viewer ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.update-files-modal .files-viewer ul li {
  display: flex;
  justify-content: space-between;
}
.update-files-modal .files-viewer ul li:not(:first-child) {
  margin-top: 10px;
}
.update-files-modal .files-viewer ul li > div:first-child {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.update-files-modal .files-viewer ul li img {
  cursor: pointer;
  height: 23px;
}
.update-files-modal .file-upload-preview {
  margin: 0px 40px;
}
