.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.report-form-block-label {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 20px;
  margin-right: 7px;
  color: #454a5c;
}
.report-form-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
}
.report-form-block .report-form-block-note {
  color: #454a5c;
  margin-bottom: 20px;
}
.report-form-block .image-upload-container {
  position: relative;
  min-height: 58px;
  display: flex;
  align-items: center;
}
.report-form-block .cover-image {
  position: absolute;
  top: 0;
  left: 0;
}
.report-form-block .multiple-dropdown-container {
  width: 781px;
}
.report-form-block .radiobox-group-label {
  font-weight: 400;
  color: #7a7e8b;
  margin-bottom: 8px;
}
.report-form-block .numbers-of-letters {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  padding-left: 8px;
  margin-top: 4px;
  color: #7a7e8b;
}
.report-form-block .project-info-description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 20px;
  color: #454a5c;
}
.report-form-block .checkbox-container {
  width: 600px;
}
.report-form-block .checkbox-row-container {
  width: 300px;
  margin-bottom: 15px;
}
.report-form-block .project-info-checkbox {
  margin-right: 10px;
  width: 15px;
  height: 15px;
}
.report-form-block .buttons-contract-information-separate {
  width: 24px;
}
.report-form-block .buttons-actions-separate {
  margin-right: 12px;
}
.report-form-block.contract-info-container {
  margin-bottom: 32px;
}
.report-form-block .field-project-report-contract-information-table colgroup > col:nth-child(1) {
  width: 18%;
}
.report-form-block .field-project-report-contract-information-table colgroup > col:nth-child(2) {
  width: 13%;
}
.report-form-block .field-project-report-contract-information-table colgroup > col:nth-child(3) {
  width: 13%;
}
.report-form-block .field-project-report-contract-information-table colgroup > col:nth-child(4) {
  width: 13%;
}
.report-form-block .field-project-report-contract-information-table colgroup > col:nth-child(5) {
  width: 9%;
}
.report-form-block .field-project-report-contract-information-table colgroup > col:nth-child(6) {
  width: 8%;
}
.report-form-block .field-project-report-contract-information-table colgroup > col:nth-child(7) {
  width: 8%;
}
.report-form-block .field-project-report-contract-information-table colgroup > col:nth-child(8) {
  width: 18%;
}
.report-form-block .field-project-report-contract-information-table .row-justify-center {
  display: flex;
  justify-content: center;
}
.report-form-block .field-project-report-contract-information-table thead tr th,
.report-form-block .field-project-report-contract-information-table tbody tr td {
  border: none !important;
  border-bottom: 0.5px solid #eaeaec !important;
}
.report-form-block .collapsible-header .collapsible-title .collapsible-label {
  font-size: 16px;
}
.report-form-block.schedule_categories {
  margin-bottom: 60px;
}
.report-form-block.schedule_categories .item-block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 60%;
}
.report-form-block.schedule_categories .item-block .labeled-checkbox {
  margin-right: 36px;
  margin-bottom: 20px;
}
.report-form-block.schedule_categories .item-block .labeled-checkbox:last-child {
  margin-right: 0;
}
.report-form-block.schedule_categories .item-block .labeled-checkbox .checkbox-container {
  width: 16px;
}
.report-form-block .item-block.shedule-datetime-picker {
  display: flex;
  flex-direction: row;
}
.report-form-block .item-block.shedule-datetime-picker .form-control-block {
  margin-right: 24px;
  min-width: 145px;
}
.report-form-block .item-block.shedule-datetime-picker .form-control-block:last-child {
  margin-right: 0;
}
.report-form-block .item-block.shedule-datetime-picker .form-control-block.variance .readonly-input {
  white-space: nowrap;
}
.contract-info .simple-table > thead th:nth-child(1),
.contract-info .simple-table > tbody td:not(.rdtDay):nth-child(1) {
  width: 100%;
  min-width: 150px;
}
.contract-info .simple-table > thead th:nth-child(1) .separate-icon,
.contract-info .simple-table > tbody td:not(.rdtDay):nth-child(1) .separate-icon {
  width: 100%;
}
.contract-info .simple-table > thead th:nth-child(2),
.contract-info .simple-table > tbody td:not(.rdtDay):nth-child(2),
.contract-info .simple-table > thead th:nth-child(3),
.contract-info .simple-table > tbody td:not(.rdtDay):nth-child(3),
.contract-info .simple-table > thead th:nth-child(4),
.contract-info .simple-table > tbody td:not(.rdtDay):nth-child(4),
.contract-info .simple-table > thead th:nth-child(5),
.contract-info .simple-table > tbody td:not(.rdtDay):nth-child(5),
.contract-info .simple-table > thead th:nth-child(6),
.contract-info .simple-table > tbody td:not(.rdtDay):nth-child(6) {
  min-width: 90px;
  text-align: right;
}
.contract-info .simple-table > thead th:nth-child(2) .input-area,
.contract-info .simple-table > tbody td:not(.rdtDay):nth-child(2) .input-area,
.contract-info .simple-table > thead th:nth-child(3) .input-area,
.contract-info .simple-table > tbody td:not(.rdtDay):nth-child(3) .input-area,
.contract-info .simple-table > thead th:nth-child(4) .input-area,
.contract-info .simple-table > tbody td:not(.rdtDay):nth-child(4) .input-area,
.contract-info .simple-table > thead th:nth-child(5) .input-area,
.contract-info .simple-table > tbody td:not(.rdtDay):nth-child(5) .input-area,
.contract-info .simple-table > thead th:nth-child(6) .input-area,
.contract-info .simple-table > tbody td:not(.rdtDay):nth-child(6) .input-area {
  text-align: right;
}
.contract-info .simple-table > thead th:nth-child(2) .input-area::placeholder,
.contract-info .simple-table > tbody td:not(.rdtDay):nth-child(2) .input-area::placeholder,
.contract-info .simple-table > thead th:nth-child(3) .input-area::placeholder,
.contract-info .simple-table > tbody td:not(.rdtDay):nth-child(3) .input-area::placeholder,
.contract-info .simple-table > thead th:nth-child(4) .input-area::placeholder,
.contract-info .simple-table > tbody td:not(.rdtDay):nth-child(4) .input-area::placeholder,
.contract-info .simple-table > thead th:nth-child(5) .input-area::placeholder,
.contract-info .simple-table > tbody td:not(.rdtDay):nth-child(5) .input-area::placeholder,
.contract-info .simple-table > thead th:nth-child(6) .input-area::placeholder,
.contract-info .simple-table > tbody td:not(.rdtDay):nth-child(6) .input-area::placeholder {
  opacity: 1 !important;
}
.contract-info .simple-table > thead th:nth-child(6),
.contract-info .simple-table > tbody td:not(.rdtDay):nth-child(6) {
  min-width: 120px;
  padding-right: 24px;
}
.contract-info .simple-table > thead th:nth-child(7) .date-picker,
.contract-info .simple-table > tbody td:not(.rdtDay):nth-child(7) .date-picker,
.contract-info .simple-table > thead th:nth-child(8) .date-picker,
.contract-info .simple-table > tbody td:not(.rdtDay):nth-child(8) .date-picker,
.contract-info .simple-table > thead th:nth-child(7) span,
.contract-info .simple-table > tbody td:not(.rdtDay):nth-child(7) span,
.contract-info .simple-table > thead th:nth-child(8) span,
.contract-info .simple-table > tbody td:not(.rdtDay):nth-child(8) span {
  display: block;
  width: 125px;
}
.non-margin-bottom-form-block {
  margin-bottom: 20px;
}
.table-with-form .input {
  border-bottom-width: 0px !important;
}
.table-with-form .input-area {
  border-bottom-width: 0px;
}
.table-with-form .input-area:not(textarea):focus {
  border-bottom: 1px solid #7a7e8b;
}
.table-action {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.table-action .select-item {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #00b091;
  margin-right: 12px;
  cursor: pointer;
  opacity: 0.5;
}
.table-action .select-item:last-child {
  margin-right: 0;
}
.table-action .select-item-active {
  opacity: 1;
}
.row-block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.row-block .item-block {
  display: flex;
  flex-direction: column;
  width: 335px;
  padding-right: 20px;
}
.row-block .item-block-documents {
  width: 100%;
}
.row-block-documents {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.row-block-documents .item-block-documents {
  width: 100%;
  padding-right: 40px;
}
.documents {
  width: 40%;
}
.photos {
  width: 60%;
}
.row-space-between {
  justify-content: space-between;
}
.row-align-items-center {
  align-items: center;
}
.align-self-start {
  align-self: flex-start;
}
.schedule-view-resolved {
  position: absolute;
  right: 65px;
  top: 26px;
}
.project-status-view-resolved {
  position: absolute;
  right: 40px;
  top: 26px;
}
.filter-icon {
  display: flex;
  flex-direction: row-reverse;
  cursor: pointer;
  opacity: 0.7;
}
.budget-view-resolved,
.shedule-view-resolved {
  display: flex;
  flex-direction: row;
}
.field-project-report-project-status-table colgroup > col:nth-child(1) {
  width: 10%;
}
.field-project-report-project-status-table colgroup > col:nth-child(2) {
  width: 60%;
}
.field-project-report-project-status-table colgroup > col:nth-child(3) {
  width: 12%;
}
.field-project-report-project-status-table colgroup > col:nth-child(4) {
  width: 18%;
}
.field-project-report-project-status-table thead tr th,
.field-project-report-project-status-table tbody tr td {
  border: none !important;
  border-bottom: 0.5px solid #eaeaec !important;
}
.table-with-form {
  overflow: auto;
}
.table-with-form .table-draggable-subitems {
  width: 100%;
}
.table-with-form .table-draggable-subitems .budget-subitem {
  display: -ms-grid;
  display: -moz-grid;
  display: grid;
}
.table-with-form .table-draggable-subitems .budget-subitem tr td {
  width: 100%;
}
.table-with-form .table-draggable-subitems tr:last-child table tbody tr td {
  font-weight: 600;
  border-top: 0.5px solid #eaeaec !important;
}
.table-with-form .table-draggable-subitems tr:last-child table tbody tr td:first-child {
  padding-left: 35px;
}
.table-with-form .table-draggable-subitems .field-project-report-budget-table thead tr th:first-child {
  padding-left: 34px;
}
.table-with-form .table-draggable-subitems .field-project-report-budget-table thead tr th span {
  white-space: nowrap;
}
.table-with-form .table-draggable-subitems .field-project-report-budget-table .construction-budget {
  width: 16px;
  margin: 0 auto;
}
.table-with-form .table-draggable-subitems .field-project-report-budget-table .parent-item-cell {
  background-color: #eaeaec;
  border-bottom: 1px solid #f7f7f8;
}
.table-with-form .table-draggable-subitems .field-project-report-budget-table .child-item-cell.td-icon {
  padding-left: 32px !important;
}
.table-with-form .table-draggable-subitems .field-project-report-budget-table .child-item-cell.td-icon .drag-drop-icon {
  left: 35px;
}
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary thead th,
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary tbody td {
  width: 120px;
  min-width: 120px;
  padding-left: 0;
  padding-right: 0;
}
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary thead th:first-child,
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary tbody td:first-child {
  width: 80%;
  min-width: 200px;
}
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary thead th:nth-child(8),
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary tbody td:nth-child(8) {
  width: 140px;
  min-width: 140px;
  text-align: center;
}
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary thead th:last-child,
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary tbody td:last-child {
  width: 70px;
  min-width: 70px;
  padding-right: 10px;
}
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary thead th:last-child .table-action,
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary tbody td:last-child .table-action {
  justify-content: center;
}
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary thead th:nth-child(5) span,
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary tbody td:nth-child(5) span,
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary thead th:nth-child(6) span,
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary tbody td:nth-child(6) span {
  white-space: normal;
}
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary thead th:nth-child(2),
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary tbody td:nth-child(2),
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary thead th:nth-child(3),
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary tbody td:nth-child(3),
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary thead th:nth-child(4),
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary tbody td:nth-child(4),
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary thead th:nth-child(5),
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary tbody td:nth-child(5),
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary thead th:nth-child(6),
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary tbody td:nth-child(6),
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary thead th:nth-child(7),
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary tbody td:nth-child(7) {
  text-align: right;
}
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary thead th:nth-child(2) .input-area,
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary tbody td:nth-child(2) .input-area,
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary thead th:nth-child(3) .input-area,
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary tbody td:nth-child(3) .input-area,
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary thead th:nth-child(4) .input-area,
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary tbody td:nth-child(4) .input-area,
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary thead th:nth-child(5) .input-area,
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary tbody td:nth-child(5) .input-area,
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary thead th:nth-child(6) .input-area,
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary tbody td:nth-child(6) .input-area,
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary thead th:nth-child(7) .input-area,
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary tbody td:nth-child(7) .input-area {
  text-align: right;
}
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary thead th:nth-child(7),
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-summary tbody td:nth-child(7) {
  padding-left: 40px;
}
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-pay-to-date thead th,
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-pay-to-date tbody td {
  width: 120px;
  min-width: 120px;
  padding-left: 0;
  padding-right: 0;
}
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-pay-to-date thead th:first-child,
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-pay-to-date tbody td:first-child {
  width: 80%;
  min-width: 200px;
}
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-pay-to-date thead th:nth-child(6),
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-pay-to-date tbody td:nth-child(6) {
  width: 140px;
  min-width: 140px;
  text-align: center;
}
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-pay-to-date thead th:last-child,
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-pay-to-date tbody td:last-child {
  width: 70px;
  min-width: 70px;
  padding-right: 10px;
}
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-pay-to-date thead th:last-child .table-action,
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-pay-to-date tbody td:last-child .table-action {
  justify-content: center;
}
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-pay-to-date thead th:nth-child(2),
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-pay-to-date tbody td:nth-child(2),
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-pay-to-date thead th:nth-child(3),
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-pay-to-date tbody td:nth-child(3),
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-pay-to-date thead th:nth-child(4),
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-pay-to-date tbody td:nth-child(4) {
  text-align: right;
}
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-pay-to-date thead th:nth-child(2) .input-area,
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-pay-to-date tbody td:nth-child(2) .input-area,
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-pay-to-date thead th:nth-child(3) .input-area,
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-pay-to-date tbody td:nth-child(3) .input-area,
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-pay-to-date thead th:nth-child(4) .input-area,
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-pay-to-date tbody td:nth-child(4) .input-area {
  text-align: right;
}
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-pay-to-date thead th:nth-child(5),
.table-with-form .table-draggable-subitems .field-project-report-budget-table.budget-pay-to-date tbody td:nth-child(5) {
  text-align: center;
}
.field-project-report-shedule-table colgroup > col:nth-child(1) {
  width: 17%;
}
.field-project-report-shedule-table colgroup > col:nth-child(2) {
  width: 11%;
}
.field-project-report-shedule-table colgroup > col:nth-child(3) {
  width: 14%;
}
.field-project-report-shedule-table colgroup > col:nth-child(4) {
  width: 14%;
}
.field-project-report-shedule-table colgroup > col:nth-child(5) {
  width: 14%;
}
.field-project-report-shedule-table colgroup > col:nth-child(6) {
  width: 14%;
}
.field-project-report-shedule-table colgroup > col:nth-child(7) {
  width: 16%;
}
.field-project-report-shedule-table thead tr th,
.field-project-report-shedule-table tbody tr td {
  border: none !important;
  border-bottom: 0.5px solid #eaeaec !important;
}
.field-project-report-shedule-table .date-picker input {
  background: none;
  width: 90px;
}
.field-project-report-shedule-table .date-picker input::placeholder {
  opacity: 1;
}
.field-project-report-shedule-table .date-picker .rdtPicker th.rdtNext,
.field-project-report-contract-information-table .date-picker .rdtPicker th.rdtNext,
.field-project-report-shedule-table .date-picker .rdtPicker th.rdtPrev,
.field-project-report-contract-information-table .date-picker .rdtPicker th.rdtPrev {
  font-size: 21px !important;
  font-weight: 700 !important;
}
.field-project-report-shedule-table .date-picker .rdtPicker th.rdtSwitch,
.field-project-report-contract-information-table .date-picker .rdtPicker th.rdtSwitch {
  font-weight: normal !important;
}
.field-project-report-shedule-table .date-picker .rdtPicker th,
.field-project-report-contract-information-table .date-picker .rdtPicker th {
  height: 28px !important;
  padding: 0px !important;
  font-weight: 700 !important;
}
.field-project-report-shedule-table .date-picker thead tr th,
.field-project-report-contract-information-table .date-picker thead tr th {
  font-size: 12px !important;
}
.field-project-report-shedule-table .date-picker tbody tr,
.field-project-report-contract-information-table .date-picker tbody tr,
.field-project-report-shedule-table .date-picker tbody td,
.field-project-report-contract-information-table .date-picker tbody td {
  border: none !important;
}
.field-project-report-shedule-table .date-picker input,
.field-project-report-contract-information-table .date-picker input {
  background: none;
  width: 90px;
}
.field-project-report-shedule-table .date-picker input::placeholder,
.field-project-report-contract-information-table .date-picker input::placeholder {
  opacity: 1;
}
.table-with-form .field-project-report-contract-information-table .input-area,
.table-with-form .field-project-report-project-status-table .input-area,
.table-with-form .field-project-report-budget-table .input-area,
.table-with-form .field-project-report-shedule-table .input-area,
.table-with-form .field-project-report-contract-information-table .input,
.table-with-form .field-project-report-project-status-table .input,
.table-with-form .field-project-report-budget-table .input,
.table-with-form .field-project-report-shedule-table .input {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.table-with-form .field-project-report-contract-information-table th,
.table-with-form .field-project-report-project-status-table th,
.table-with-form .field-project-report-budget-table th,
.table-with-form .field-project-report-shedule-table th {
  vertical-align: top !important;
}
.table-with-form .field-project-report-contract-information-table > tbody tr td,
.table-with-form .field-project-report-project-status-table > tbody tr td,
.table-with-form .field-project-report-budget-table > tbody tr td,
.table-with-form .field-project-report-shedule-table > tbody tr td {
  height: 60px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.table-with-form .field-project-report-contract-information-table tr.dragging,
.table-with-form .field-project-report-project-status-table tr.dragging,
.table-with-form .field-project-report-budget-table tr.dragging,
.table-with-form .field-project-report-shedule-table tr.dragging {
  display: table;
}
.table-with-form .field-project-report-contract-information-table .td-icon,
.table-with-form .field-project-report-project-status-table .td-icon,
.table-with-form .field-project-report-budget-table .td-icon,
.table-with-form .field-project-report-shedule-table .td-icon {
  position: relative;
  padding-left: 12px !important;
}
.table-with-form .field-project-report-contract-information-table .td-icon .separate-icon,
.table-with-form .field-project-report-project-status-table .td-icon .separate-icon,
.table-with-form .field-project-report-budget-table .td-icon .separate-icon,
.table-with-form .field-project-report-shedule-table .td-icon .separate-icon {
  padding-left: 23px;
}
.table-with-form .field-project-report-contract-information-table .td-icon .drag-drop-icon,
.table-with-form .field-project-report-project-status-table .td-icon .drag-drop-icon,
.table-with-form .field-project-report-budget-table .td-icon .drag-drop-icon,
.table-with-form .field-project-report-shedule-table .td-icon .drag-drop-icon {
  position: absolute;
  left: 12px;
  top: 16px;
}
.table-with-form .date-picker {
  min-width: 140px;
  max-width: 140px;
}
.table-with-form .date-picker .MuiInputBase-root {
  width: 100%;
}
.table-with-form .date-picker .MuiInputBase-root .MuiInputAdornment-root {
  margin-left: 20px;
}
.table-with-form .date-picker .date-picker-remove.hidden-underline {
  right: 36px;
}
/* .summary-shedule {
  tr:last-child td {
    font-weight: 600;
    border-bottom-width: 0px;
  }
} */
.report-form-weekly-report-date {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 23px;
  color: #7a7e8b;
}
.document-links-label {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-left: 5px;
  color: #00b091;
  cursor: pointer;
}
.document-name {
  width: 70%;
}
.document-links {
  width: 30%;
  display: flex;
}
.image-container {
  width: 100px;
  height: 100px;
  margin: 0px 8px 8px 0px;
}
.dark-mode .report-form-block-label {
  color: #ffffff;
}
.dark-mode .report-form-block .radiobox-group-label {
  color: rgba(255, 255, 255, 0.5);
}
.dark-mode .report-form-block .numbers-of-letters {
  color: rgba(255, 255, 255, 0.7);
}
.dark-mode .report-form-block .project-info-description {
  color: #ffffff;
}
.dark-mode .report-form-block .table-action .select-item {
  color: #d4fb00;
}
.dark-mode .report-form-block .table-action .menu.more-options.rotated-icon .menu-image-sector-container {
  height: 30px;
}
.dark-mode .report-form-block .report-form-block-note {
  color: #bcbdc1;
}
.dark-mode .table-draggable-subitems .field-project-report-budget-table .parent-item-cell {
  background-color: #454a5c;
}
.dark-mode .table-with-form .input-area:not(textarea):focus {
  border-bottom: 1px solid #bcbdc1;
}
.dark-mode .table-with-form td,
.dark-mode .table-with-form input.form-control,
.dark-mode .table-with-form input.form-control::placeholder {
  color: #ffffff;
}
.dark-mode .table-with-form .invalid-message {
  color: #02c7ff !important;
}
.dark-mode .table-with-form td span {
  color: #ffffff;
}
.dark-mode .report-form-weekly-report-date {
  color: #bcbdc1;
}
.dark-mode .document-links-label {
  color: #d4fb00;
}
