.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.switch-control {
  display: flex;
  align-items: center;
}
.switch-control .switch-control-label {
  margin: 0px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #9ea0aa;
}
.switch-control .switch-control-label.selected {
  color: #454a5c;
}
.switch-control > :not(:first-child) {
  margin-left: 12px;
}
.switch-control.dark-mode .switch-control-label {
  color: #909197;
}
.switch-control.dark-mode .switch-control-label.selected {
  color: #ffffff;
}
