.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.security-cameras-permissions-modal .modal-dialog {
  max-height: 90vh;
  width: 600px;
}
.security-cameras-permissions-modal .modal-body {
  padding: 8px 0px 40px 0px !important;
}
.security-cameras-permissions-modal .modal-body .users-list {
  width: 100%;
  max-height: calc(90vh - 200px);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.security-cameras-permissions-modal .modal-body .users-list .user-permissions-form {
  padding: 0px 40px 0px 40px;
}
.security-cameras-permissions-modal .form-control-bottom-label {
  display: none;
}
