.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.preview-popover {
  width: 512px;
  max-width: 512px;
  height: 390px;
  max-height: 390px;
  background: #ffffff;
  filter: drop-shadow(8px 6px 25px rgba(10, 13, 24, 0.08));
  box-shadow: unset;
  border: none;
  border-radius: unset;
  font-family: Titillium Web, sans-serif !important;
}
.preview-popover .arrow {
  display: none !important;
}
.preview-popover .preview-arrow {
  position: absolute;
  bottom: -9.5px;
  width: 0;
  height: 0;
  border-top: 9.5px solid #ffffff;
}
.preview-popover.rightPosition {
  margin-left: 256px;
}
.preview-popover.rightPosition .preview-arrow {
  left: 0px;
  border-right: 12px solid transparent;
}
.preview-popover.leftPosition {
  margin-left: -256px;
}
.preview-popover.leftPosition .preview-arrow {
  right: 0px;
  border-left: 12px solid transparent;
}
.preview-popover .popover-title {
  background-color: unset;
  border: unset;
  padding: unset;
  padding-left: 32px;
  padding-top: 32px;
  font-weight: bold;
  font-size: 23px;
  line-height: 38px;
  color: #454a5c;
}
.preview-popover .popover-content {
  float: left;
  width: 100%;
  padding: unset;
  padding-left: 32px;
  padding-right: 32px;
}
.preview-popover .popover-content .preview-subtitle {
  float: left;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  color: #454a5c;
  opacity: 0.5;
}
.preview-popover .popover-content .preview-date {
  float: left;
  width: 100%;
  margin-top: 31px;
  font-size: 12px;
  line-height: 18px;
  color: #00b091;
}
.preview-popover .popover-content .preview-content {
  float: left;
  width: 100%;
  word-wrap: break-word;
  height: 192px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
  font-size: 16px;
  line-height: 24px;
  color: #454a5c;
}
.preview-popover.dark-mode {
  background: #303441;
  filter: drop-shadow(8px 6px 25px rgba(10, 13, 24, 0.5));
}
.preview-popover.dark-mode .preview-arrow {
  border-top: 9.5px solid #303441;
}
.preview-popover.dark-mode .popover-title {
  color: #ffffff;
}
.preview-popover.dark-mode .popover-content .preview-subtitle {
  color: #ffffff;
}
.preview-popover.dark-mode .popover-content .preview-date {
  color: #d4fb00;
}
.preview-popover.dark-mode .popover-content .preview-content {
  color: #ffffff;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
}
