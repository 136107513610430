.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.text-area {
  letter-spacing: -0.3px;
  resize: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
}
.text-area.invalid {
  border-color: #fb6120 !important;
}
.text-area.invalid::placeholder {
  color: #fb6120 !important;
  opacity: 1;
}
.text-area.invalid.dark-mode {
  border-color: #02c7ff !important;
}
.text-area.invalid.dark-mode::placeholder {
  color: #02c7ff !important;
}
.text-area-container {
  position: relative;
  border-bottom: 1px solid #7a7e8b;
}
.text-area-container .text-area {
  padding: 0px;
  margin: 7px 8px 8px 8px;
  border: none;
}
.text-area-container.invalid {
  border-color: #fb6120 !important;
}
.text-area-container:focus-within {
  border-color: #00b091;
}
.text-area-container.without-border {
  border: none;
}
.text-area-container.dark-mode {
  border-color: #bcbdc1;
}
.text-area-container.dark-mode.invalid {
  border-color: #02c7ff !important;
}
.text-area-container.dark-mode:focus-within {
  border-color: #d4fb00;
}
.expand-textarea-button {
  position: absolute;
  top: -8px;
  right: -5px;
  cursor: pointer;
  padding: 0px 0px 0px 32px;
}
.expand-textarea-button.expanded img {
  transform: rotate(180deg);
}
