.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.wo-table-container .pagination-container {
  margin-top: 30px;
  text-align: center;
}
.wo-table-container .work-orders-table {
  margin-top: 5px;
  table-layout: fixed;
}
.wo-table-container .work-orders-table colgroup > col:nth-child(1) {
  width: 2%;
}
.wo-table-container .work-orders-table colgroup > col:nth-child(2) {
  width: 6%;
}
.wo-table-container .work-orders-table td .base-status-color.unread {
  background-color: #fb6120;
}
.wo-table-container .work-orders-table .company-name-cell {
  color: #3c4249;
  font-weight: 600;
}
.wo-table-container .work-orders-table .filter-container {
  width: 140px;
}
