.gantt-wrapper {
  margin: 20px;
  min-height: 300px;
}
.gantt-chart-title {
  font-size: large;
  font-weight: 600;
  line-height: 24px;
}
.gantt-view-container {
  list-style: none;
  -ms-box-orient: horizontal;
  display: flex;
  -webkit-justify-content: flex-end;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.gantt-button {
  color: black;
  border: none;
  padding: 7px 16px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}
[class*='taskListTableRow'] > div:nth-child(n) {
  min-width: 300px !important;
  max-width: 300px !important;
}
[class*='taskListTableRow'] > div:nth-child(n + 4) {
  display: none;
}
[class*='taskListContent'] > div:first-child {
  height: auto !important;
}
[class*='ganttTaskContent'] > div:first-child {
  height: auto !important;
}
[class*='ganttTable_Header'] > div:nth-child(n + 6) {
  display: none;
}
[class*='taskName'] {
  color: black;
}
.dark-mode [class*='taskListCellInner'] {
  color: white !important;
}
.dark-mode .gantt-chart-title {
  color: white !important;
}
.dark-mode [class*='taskName'] {
  color: white !important;
}
.dark-mode [class*='calendar'] {
  color: white !important;
}
.dark-mode [class*='separator'] {
  color: white !important;
}
.dark-mode [class*='barLabel'] {
  color: white !important;
}
