.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.document-upload,
.dzu-dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #9ea0aa;
  cursor: pointer;
}
.document-upload .document-drop-zone,
.dzu-dropzone .document-drop-zone {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  width: 100%;
}
.document-upload .document-drop-zone-label,
.dzu-dropzone .document-drop-zone-label {
  margin-left: 10px;
  font-size: 16px;
  line-height: 24px;
  color: #454a5c;
}
.dark-mode .document-upload,
.dark-mode .dzu-dropzone {
  border-color: #909197;
}
.dark-mode .document-upload .document-drop-zone-label,
.dark-mode .dzu-dropzone .document-drop-zone-label {
  color: #ffffff;
}
.file-upload-preview {
  margin-top: 12px;
}
.file-upload-preview .preview-progress-bar {
  height: 6px;
  border-radius: 3px;
  margin: 8px 0;
  background-color: #eaeaec;
}
.file-upload-preview .preview-progress-bar .MuiLinearProgress-bar {
  border-radius: 3px;
  background-color: #00b091;
}
.file-upload-preview .preview-progress-bar.dark-mode {
  background-color: #7a7e8b;
}
.file-upload-preview .preview-progress-bar.dark-mode .MuiLinearProgress-bar {
  background-color: #d4fb00;
}
.file-upload-preview span.percentage {
  text-align: right;
  width: 100%;
  display: inline-block;
}
.file-upload-preview .complete-icon {
  width: 24px;
  height: 24px;
  margin-left: auto;
  display: block;
}
.multiple-preview {
  margin-top: 10px;
}
.document-upload-container .document-upload {
  margin-top: 12px;
}
.dark-mode .file-upload-preview span {
  color: #ffffff;
}
