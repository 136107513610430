.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.project-photos-item {
  width: 100%;
  height: 230px;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
}
.project-photos-item .cover {
  width: 100%;
  height: 100%;
  transition: all ease-in-out 300ms;
}
.project-photos-item .cover:is(img) {
  display: block;
  object-fit: cover;
  object-position: center;
}
.project-photos-item .cover.default-cover {
  background-image: url('../../../../../images/image.svg');
  opacity: 0.5;
  background-size: 60px;
  background-position: center;
  background-repeat: no-repeat;
}
.project-photos-item .item-favorite {
  position: absolute;
  top: 16px;
  left: 18px;
}
.project-photos-item .item-edit {
  display: none;
  position: absolute;
  top: 16px;
  right: 16px;
}
.project-photos-item .item-edit .button {
  height: 30px;
  min-width: 30px;
  width: 30px;
  padding: 0;
  border: 1px solid #eaeaec;
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-photos-item .item-edit .button img {
  position: relative;
  top: 1px;
}
.project-photos-item .item-edit .button span {
  display: none;
}
.project-photos-item .item-video {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
  border-radius: 50%;
  font-size: 16px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ffffff;
  color: #ffffff;
}
.project-photos-item .bottom-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% + 1px);
  padding: 8px 16px;
  z-index: 1;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
}
.project-photos-item .bottom-content.photo {
  height: 0;
  visibility: hidden;
}
.project-photos-item .bottom-content.no-image {
  color: #454a5c;
  width: 100%;
}
.project-photos-item .bottom-content > * {
  position: relative;
  z-index: 1;
}
.project-photos-item .bottom-content::after {
  content: '';
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #eaeaec;
}
.project-photos-item .bottom-content.has-image::after {
  backdrop-filter: blur(4px);
  background: rgba(0, 0, 0, 0.1);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
}
.project-photos-item .bottom-content .album-info {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.project-photos-item .bottom-content .album-info span:first-child {
  max-width: calc(100% - 50px);
}
.project-photos-item .bottom-content .album-info:first-child span:first-child {
  font-weight: 600;
}
.project-photos-item .bottom-content .album-info:nth-child(2) {
  display: none;
}
.project-photos-item .bottom-content .photo-info {
  display: flex;
  flex-direction: column;
}
.project-photos-item .bottom-content .photo-info span:first-child {
  font-weight: 600;
}
.project-photos-item .bottom-content .item-expand {
  display: none;
  position: absolute;
  right: 20px;
}
.project-photos-item:hover,
.project-photos-item.selected {
  cursor: pointer;
}
.project-photos-item:hover .bottom-content,
.project-photos-item.selected .bottom-content {
  transition: height ease-in-out 400;
}
.project-photos-item:hover .bottom-content .album-info:not(.hide-expand):first-child span:last-child,
.project-photos-item.selected .bottom-content .album-info:not(.hide-expand):first-child span:last-child {
  opacity: 0;
}
.project-photos-item:hover .bottom-content .album-info:nth-child(2),
.project-photos-item.selected .bottom-content .album-info:nth-child(2) {
  display: block;
}
.project-photos-item:hover .bottom-content.photo,
.project-photos-item.selected .bottom-content.photo {
  height: auto;
  visibility: visible;
}
.project-photos-item:hover .item-expand,
.project-photos-item.selected .item-expand {
  display: block;
}
.project-photos-item:hover .cover,
.project-photos-item.selected .cover {
  transform: scale(1.05);
}
.project-photos-item:hover .item-edit {
  display: block;
}
.dark-mode .project-photos-item {
  background-color: #303441;
}
.dark-mode .project-photos-item .cover.default-cover {
  opacity: 0.9;
}
.dark-mode .project-photos-item .bottom-content::after {
  background: #3b4050;
}
.dark-mode .project-photos-item .bottom-content.no-image {
  color: #ffffff;
}
.dark-mode .project-photos-item .bottom-content.has-image::after {
  background: rgba(255, 255, 255, 0.1);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1));
}
.dark-mode .project-photos-item .item-edit .button {
  border-color: #191b24;
}
