.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.project-open-items-table table colgroup > col:nth-child(3) {
  width: 20%;
}
.project-open-items-table table colgroup > col:nth-child(4) {
  width: 30%;
}
.project-open-items-table table colgroup > col:nth-child(5) {
  width: 10%;
}
.project-open-items-table table colgroup > col:nth-child(6) {
  width: 12%;
}
.project-open-items-table table colgroup > col:nth-child(7) {
  width: 10%;
}
.project-open-items-table table colgroup > col:nth-child(8) {
  width: 10%;
}
.project-open-items-table table td a {
  text-align: left;
}
.project-open-items-table table .draft a,
.project-open-items-table table .past-due a {
  color: #fb6120;
}
.project-open-items-table table.dark-mode .draft a,
.project-open-items-table table.dark-mode .past-due a {
  color: #02c7ff !important;
}
