.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.project-dashboard-budget {
  display: flex;
  flex-direction: column;
}
.project-dashboard-budget .budget-header {
  display: flex;
  align-items: center;
  margin: 16px 32px 0px 32px;
}
.project-dashboard-budget .budget-header .budget-title {
  font-size: 29px;
  line-height: 46px;
  font-weight: 600;
  color: #454a5c;
}
.project-dashboard-budget .budget-header .budget-legend {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  color: #454a5c;
}
.project-dashboard-budget .budget-header .budget-legend .budget-legend-item {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.project-dashboard-budget .budget-header .budget-legend .budget-legend-item:not(:first-child) {
  margin-left: 32px;
}
.project-dashboard-budget .budget-header .budget-legend .budget-legend-item .budget-legend-item-icon {
  width: 12px;
  height: 12px;
  margin-right: 4px;
  border-radius: 100%;
}
.project-dashboard-budget .budget-header .budget-view-more {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-right: 8px;
  color: #454a5c;
  cursor: pointer;
}
.project-dashboard-budget .budget-header .budget-view-more img {
  margin-left: 12px;
}
.project-dashboard-budget .budget-chart {
  width: 100%;
  height: calc(100% - 62px);
}
.project-dashboard-budget .budget-chart.scrollable {
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 16px;
}
.project-dashboard-budget .budget-tooltip {
  display: flex;
  width: 214px;
  flex-direction: column;
  padding: 16px;
  background-color: #ffffff;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.15));
}
.project-dashboard-budget .budget-tooltip > * {
  margin-bottom: 0px;
}
.project-dashboard-budget .budget-tooltip .budget-tooltip-name {
  font-size: 12px;
  line-height: 18px;
  color: #7a7e8b;
}
.project-dashboard-budget .budget-tooltip .budget-tooltip-main-value {
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #454a5c;
}
.project-dashboard-budget .budget-tooltip .budget-tooltip-value {
  font-size: 11px;
  line-height: 16px;
  color: #454a5c;
}
.project-dashboard-budget .budget-tooltip .budget-tooltip-value:last-child {
  margin-top: 6px;
}
.project-dashboard-budget.dark-mode .budget-header .budget-title {
  color: #ffffff;
}
.project-dashboard-budget.dark-mode .budget-header .budget-legend {
  color: #9ea0aa;
}
.project-dashboard-budget.dark-mode .budget-header .budget-view-more {
  color: #ffffff;
}
.project-dashboard-budget.dark-mode .budget-tooltip {
  background-color: #303441;
}
.project-dashboard-budget.dark-mode .budget-tooltip .budget-tooltip-name {
  color: #bcbdc1;
}
.project-dashboard-budget.dark-mode .budget-tooltip .budget-tooltip-main-value {
  color: #ffffff;
}
.project-dashboard-budget.dark-mode .budget-tooltip .budget-tooltip-value {
  color: #ffffff;
}
@media (max-height: 1000px), (max-width: 1600px) {
  .project-dashboard-budget .budget-header {
    margin: 8px 16px 0px 16px;
  }
  .project-dashboard-budget .budget-header .budget-title {
    font-size: 26px;
    line-height: 38px;
  }
  .project-dashboard-budget .budget-chart {
    height: calc(100% - 46px);
  }
}
@media (max-height: 800px), (max-width: 1300px) {
  .project-dashboard-budget .budget-header {
    margin: 8px 8px 0px 8px;
  }
  .project-dashboard-budget .budget-header .budget-title {
    font-size: 18px;
    line-height: 24px;
  }
  .project-dashboard-budget .budget-chart {
    height: calc(100% - 32px);
  }
  .project-dashboard-budget .budget-tooltip {
    width: auto;
    padding: 8px 16px;
  }
  .project-dashboard-budget .budget-tooltip .budget-tooltip-name {
    font-size: 11px;
    line-height: 14px;
  }
  .project-dashboard-budget .budget-tooltip .budget-tooltip-main-value {
    font-size: 14px;
    line-height: 20px;
  }
}
