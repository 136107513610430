.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.project-status {
  display: inline-block;
  height: 22px;
}
.project-status * {
  vertical-align: middle;
}
.project-status .status-text {
  border-radius: 2px;
  display: inline-block;
  margin-right: 15px;
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: normal;
  text-align: center;
  width: 108px;
}
.project-status-list-item {
  text-transform: capitalize;
}
.project-status-label {
  text-transform: capitalize;
  color: #00b091;
}
.project-status-label.dark-mode {
  color: #00fb92;
}
