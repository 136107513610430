.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.schedule-project-details {
  height: 100%;
}
.schedule-project-details .project-schedule {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.schedule-project-details .project-schedule .navigation-bar {
  margin: 12px 40px;
}
.schedule-project-details .project-schedule .table-container {
  margin: 12px 40px;
  padding: 0;
  flex: 1;
  overflow: auto;
  min-height: 300px;
}
.schedule-project-details .project-schedule .table-container .schedules-table-header-container {
  position: sticky;
  z-index: 2;
  top: 0;
}
