.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.new-company-form {
  padding: 24px 40px 40px 40px;
}
.new-company-form .labeled-checkbox {
  justify-content: flex-end;
  align-items: baseline;
}
.new-company-form .labeled-checkbox .checkbox-label {
  max-width: unset;
}
