.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.security-cameras-configure-modal .modal-dialog {
  width: 441px;
}
.security-cameras-configure-modal .modal-body {
  padding: 8px 40px 40px 40px !important;
}
.security-cameras-configure-modal .form-control-bottom-label {
  display: none;
}
