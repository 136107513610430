.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.drag-and-drop-modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 300ms;
}
.drag-and-drop-modal .drop-zone {
  width: 100%;
  height: 100%;
  padding: 40px;
}
.drag-and-drop-modal .drop-zone .border-drop-zone {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 2px dashed #bcbdc1;
  color: #909197;
  box-sizing: border-box;
  cursor: pointer;
}
.drag-and-drop-modal .drop-zone .border-drop-zone img {
  width: 70px;
  height: 70px;
}
.drag-and-drop-modal .drop-zone .border-drop-zone h2 {
  font-size: 40px;
  font-weight: 600;
}
.drag-and-drop-modal .drop-zone .border-drop-zone span {
  font-size: 20px;
  font-weight: 100;
}
.drag-and-drop-modal .close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 3;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.drag-and-drop-modal .close-icon img {
  width: 20px;
}
.dark-mode .drag-and-drop-modal {
  background-color: rgba(25, 27, 36, 0.9);
}
.dark-mode .drag-and-drop-modal .drop-zone .border-drop-zone {
  border-color: #bcbdc1;
  color: #f0f1f2;
}
