.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.submittal-modal {
  color: #454a5c;
}
.submittal-modal .modal-dialog {
  display: flex;
  justify-content: center;
  width: auto;
  max-width: 90%;
}
.submittal-modal .modal-container {
  width: 90vw !important;
  max-width: 975px;
}
.submittal-modal .sidebar-closed + .modal-container {
  width: calc(90vw - 106px) !important;
}
.submittal-modal .sidebar-open + .modal-container {
  width: calc(90vw - 480px) !important;
}
.submittal-modal .submittal-body {
  padding: 0px 40px;
  overflow: auto;
  overflow: overlay;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
}
.submittal-modal .stamp-details {
  margin-top: 24px;
}
.submittal-modal .lead-time-title {
  margin: 16px 0px;
  padding: 9px 0px;
}
.submittal-modal .lead-time-title .details-title-text {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
}
.submittal-modal .error-message {
  margin-right: 40px;
}
.submittal-modal.dark-mode {
  color: #ffffff;
}
.submittal-modal.create-submittal .modal-dialog {
  max-width: 975px;
}
