.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.new-meeting-modal .modal-dialog {
  width: 900px;
}
.new-meeting-modal .modal-dialog .modal-content {
  background-color: #f7f7f8;
  width: 900px;
}
.new-meeting-modal .modal-dialog .modal-content .modal-container .modal-body {
  overflow: scroll;
  position: static;
  padding: 0 40px;
  padding-bottom: 90px;
}
.new-meeting-modal .modal-dialog .modal-content .modal-container .modal-body .messages-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 32px 40px;
  font-size: 14px;
  line-height: 30px;
  color: #454a5c;
}
.new-meeting-modal .modal-dialog .modal-content .modal-container .modal-body .messages-container .exclamation {
  margin: 16px 0px 24px 0px;
  width: 10px;
}
.new-meeting-modal .modal-dialog .modal-content .modal-container .modal-body .general-info {
  padding: 24px 0;
}
.new-meeting-modal .modal-dialog .modal-content .modal-container .modal-body .general-info .form-control-label {
  font-size: 14px;
  margin-bottom: -2px;
}
.new-meeting-modal .modal-dialog .modal-content .modal-container .modal-body .general-info .details-info-item {
  padding: 0 8px;
}
.new-meeting-modal .modal-dialog .modal-content .modal-container .modal-body .general-info .input-type {
  margin-top: 20px;
}
.new-meeting-modal .modal-dialog .modal-content .modal-container .modal-body .general-info .duplicate-input {
  margin: 24px 0 28px;
}
.new-meeting-modal .modal-dialog .modal-content .modal-container .modal-body .user-permissions-form {
  margin-top: 20px;
}
.new-meeting-modal .modal-dialog .modal-content .modal-container .modal-body .button-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: -1px;
  left: 0;
  height: 90px;
  background-color: #f7f7f8;
}
.new-meeting-modal .modal-dialog .modal-content .modal-container .modal-body .button-actions .button:first-child {
  margin-right: 16px;
}
.new-meeting-modal .modal-dialog .modal-content .modal-container .modal-body .button-actions .button.button-white {
  background-color: transparent;
}
.new-meeting-modal .modal-dialog .modal-content .modal-container .modal-body .button-actions-three {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: -1px;
  left: 0;
  height: 90px;
  background-color: #f7f7f8;
}
.new-meeting-modal .modal-dialog .modal-content .modal-container .modal-body .button-actions-three .button:first-child {
  margin-right: 16px;
}
.new-meeting-modal .modal-dialog .modal-content .modal-container .modal-body .button-actions-three .button:last-child {
  margin-left: 16px;
}
.new-meeting-modal .modal-dialog .modal-content .modal-container .modal-body .button-actions-three .button.button-white {
  background-color: transparent;
}
.new-meeting-modal.shared .modal-dialog .modal-content .modal-container .modal-body {
  height: calc(80vh - 90px);
  max-height: 100%;
  overflow: auto;
  overflow: overlay;
}
.tooltip-content {
  display: flex;
  flex-direction: row;
  padding: 10px;
  background-color: white !important;
}
.tooltip-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  color: #454a5c;
}
.tooltip-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #454a5c;
}
.MuiTooltip-tooltip {
  background-color: white !important;
}
.tooltip-icon {
  color: #00b091 !important;
  margin-bottom: 10px !important;
}
.dark-mode .new-meeting-modal .modal-dialog .modal-content .modal-container .modal-body .button-actions {
  background-color: #303441;
}
.dark-mode .tooltip-icon {
  color: #d4fb00 !important;
}
.dark-mode .tooltip-content {
  background-color: red;
}
.dark-mode .tooltip-text {
  color: white;
}
.dark-mode .tooltip-title {
  color: white;
}
.dark-mode .MuiTooltip-tooltip {
  background-color: #454a5c !important;
}
.dark-mode .button-actions-three {
  background-color: #303441 !important;
}
.form-container {
  padding: 20px;
}
.two-fields-container {
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-direction: row;
}
.three-fields-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 40px;
}
.three-fields-container > div:nth-child(1) {
  flex-basis: 20%;
  /* Example width for the first child */
  max-width: 152px;
}
.three-fields-container > div:nth-child(2) {
  flex-basis: 20%;
  /* Example width for the second child */
  max-width: 152px;
}
.three-fields-container > div:nth-child(3) {
  flex-basis: 50%;
  /* Example width for the third child */
}
.times-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 15px;
}
.times-container > div:nth-child(1) {
  flex-basis: 20%;
  /* Example width for the first child */
  max-width: 152px;
}
.times-container > div:nth-child(2) {
  flex-basis: 21%;
  /* Example width for the second child */
  max-width: 152px;
}
.times-container > div:nth-child(3) {
  flex-basis: 50%;
  /* Example width for the third child */
}
.form-block-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
}
.form-block-container > .form-block {
  margin-right: 60px;
}
.form-block-container > .form-block:last-child {
  margin-right: 0;
}
.form-control-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
}
.exceptions-title {
  font-size: 18px;
  line-height: 30px;
}
.exceptions-text {
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
}
.vertical-block {
  flex-direction: column;
}
.next-meetings-block {
  overflow-y: scroll;
  margin-top: 20px;
  max-height: 200px;
}
.add-guest-modal-bold-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
.errors-container {
  color: #fb6120;
}
.checkboxes-and-tooltip-container {
  display: flex;
  flex-direction: row;
}
.checkboxes-and-tooltip-container .form-control-block:first-child {
  padding-right: 240px;
}
