.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.details-header {
  height: 112px;
  margin-bottom: 16px;
  padding: 8px 0px 0px 0px;
}
.details-header .col-md-12 {
  padding: 0px;
}
.details-header .col-md-6 {
  padding-left: 0px;
  width: unset;
}
.details-header .left-header {
  position: relative;
}
.details-header .left-header .h1 {
  text-transform: uppercase;
}
.details-header .left-header > div {
  display: flex;
  letter-spacing: -0.7px !important;
}
.details-header .left-header > div .left-header-extra {
  align-items: center;
  display: flex;
  padding-left: 40px;
  padding-right: 20px;
  flex-grow: 1;
}
.details-header .left-header > div .left-header-extra .items-button {
  margin-right: 15px;
}
.details-header .left-title {
  color: #454a5c;
  font-size: 14px;
  font-weight: 600;
  height: 20px;
  line-height: normal;
}
.details-header .right-container {
  padding-right: 0px;
  float: right;
}
.details-header .right-container .right-header {
  float: right;
  height: 65px;
}
.details-header .right-container .right-header > div {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
.details-header .right-container .right-header > div > div {
  width: 100%;
}
.details-header .right-container .right-header .logo-preview-container {
  margin: 0;
}
.details-header .right-container .right-header .logo-preview-container .preview-container > div {
  border: none;
  width: 100%;
  margin-left: 32px;
  margin-top: -11px;
}
.details-header .right-container .right-header .logo-preview-container .img-full-screen {
  float: right;
  width: 80px;
  height: 80px;
  border-radius: 40px;
}
