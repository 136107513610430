.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.project-details {
  display: flex;
  flex-direction: column;
}
.project-details.dashboard-project-details,
.project-details.rfas-project-details {
  height: 100%;
}
.project-details .detail-status-container {
  float: left;
}
.project-details .detail-status-container.status-action {
  cursor: pointer;
}
.project-details .detail-status-container .status-title {
  margin-right: 8px;
  font-weight: normal;
}
.project-details .bid-due-date {
  margin-left: 10px;
}
.project-details .bid-due-date-date {
  color: red;
  vertical-align: initial;
}
.project-details .content-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.project-details .content-container .search-bar {
  margin: 0px 40px;
}
.project-details .projects-table tr th:first-child,
.project-details .projects-table tr td:first-child {
  padding-left: 23px;
}
.project-details .projects-table tr th:last-child,
.project-details .projects-table tr td:last-child {
  padding-right: 23px;
}
.project-details .projects-table tr.link-tab td:first-child {
  padding-left: 0;
}
.project-details .projects-table tr.link-tab td:first-child > a {
  padding-left: 23px;
}
.project-details .projects-table tr.link-tab td:last-child {
  padding-right: 0;
}
.project-details .projects-table tr.link-tab td:last-child > a {
  padding-right: 23px;
}
.project-dashboard-switch {
  display: flex;
  justify-content: center;
}
.project-details-option-with-badge {
  display: flex;
  align-items: center;
}
.project-details-label-text {
  margin-right: 32px;
  display: block;
}
.project-details-menu-item-counter {
  padding-left: 16px;
  margin-left: 16px;
  margin-right: 5px;
  display: block;
  font-weight: 600;
}
.project-details-mega-menu-item-counter {
  padding-left: 16px;
  margin-left: 26px;
  margin-right: 5px;
  display: block;
  font-weight: 600;
}
