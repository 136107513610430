.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.input-with-icon-section {
  position: relative;
}
.icon {
  position: absolute;
  right: 7px;
  bottom: 10px;
  cursor: pointer;
}
.numbers-of-letters {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  padding-left: 8px;
  margin-top: 4px;
  color: #7a7e8b;
}
.dark-mode .numbers-of-letters {
  color: rgba(255, 255, 255, 0.7);
}
