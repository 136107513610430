.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.chars-counter-textarea .text-area {
  width: 100%;
  min-height: 50px;
  padding-top: 18px;
  padding-left: 8px;
  font-size: 14px;
  line-height: 24px;
}
.chars-counter-textarea .chars-counter {
  width: 100%;
  margin-top: 4px;
  margin-left: 8px;
  font-size: 12px;
  color: #454a5c;
  opacity: 0.7;
}
.chars-counter-textarea .chars-counter.exceeded {
  color: #fb6120;
}
.chars-counter-textarea.readOnly .text-area {
  border-bottom-color: transparent;
}
.chars-counter-textarea.invalid .text-area {
  border-bottom-color: #fb6120;
}
.chars-counter-textarea.invalid .text-area::-webkit-input-placeholder,
.chars-counter-textarea.invalid .text-area::placeholder,
.chars-counter-textarea.invalid .text-area:-ms-input-placeholder,
.chars-counter-textarea.invalid .text-area::-ms-input-placeholder {
  color: #fb6120 !important;
}
.chars-counter-textarea.dark-mode .chars-counter {
  color: #ffffff;
}
.chars-counter-textarea.dark-mode .chars-counter.exceeded {
  color: #02c7ff;
}
.chars-counter-textarea.dark-mode.invalid .text-area {
  border-bottom-color: #02c7ff;
}
.chars-counter-textarea.dark-mode.invalid .text-area::-webkit-input-placeholder,
.chars-counter-textarea.dark-mode.invalid .text-area::placeholder,
.chars-counter-textarea.dark-mode.invalid .text-area:-ms-input-placeholder,
.chars-counter-textarea.dark-mode.invalid .text-area::-ms-input-placeholder {
  color: #02c7ff !important;
}
