.coi-exp-doc-form-modal .modal-dialog {
  width: 70%;
}
.coi-exp-doc-form {
  display: flex;
  flex-direction: column;
  padding: 24px 40px 40px 40px;
  height: 80vh;
}
.coi-exp-doc-form td {
  border: none !important;
}
.coi-exp-doc-form .buttons-container {
  margin-top: auto !important;
}
