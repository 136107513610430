.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.form-control-block {
  display: flex;
  flex-direction: column;
}
.form-control-block .form-control-label {
  min-height: 18px;
  margin-left: 8px;
  margin-bottom: -4px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #7a7e8b;
}
.form-control-block .form-control-bottom-label {
  min-height: 18px;
  margin-left: 8px;
  margin-top: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #7a7e8b;
}
.form-control-block.highlighted .form-control-label,
.form-control-block:not(.without-focus):focus-within .form-control-label {
  color: #00b091;
}
.form-control-block.dark-mode .form-control-label {
  color: rgba(255, 255, 255, 0.7);
}
.form-control-block.dark-mode .form-control-bottom-label {
  color: rgba(255, 255, 255, 0.7);
}
.form-control-block.dark-mode.highlighted .form-control-label,
.form-control-block.dark-mode:not(.without-focus):focus-within .form-control-label {
  color: #d4fb00;
}
