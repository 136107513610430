.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.dashboard-projects .dashboard-projects-header {
  border-bottom: 1px solid #dfe6f1;
  margin-bottom: 19px;
  padding: 0 22px;
  position: relative;
}
.dashboard-projects .dashboard-projects-header .navigation-item {
  background: #dfe6f1;
  border: 1px solid #dfe6f1;
  margin-bottom: -1px;
  margin-right: 12px;
}
.dashboard-projects .dashboard-projects-header .navigation-item:last-child {
  margin-right: 0;
}
.dashboard-projects .dashboard-projects-header .navigation-item span:first-child {
  border-bottom: none;
  font-size: 16px;
  color: #8e9aae;
  line-height: 45px;
  padding-left: 16px;
  padding-right: 16px;
}
.dashboard-projects .dashboard-projects-header .navigation-item.selected {
  background: white;
  border-bottom: 1px solid transparent;
}
.dashboard-projects .dashboard-projects-header .navigation-item.selected span:first-child {
  color: #3c4249;
}
