.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.warranty-documents-modal .doc-viewer:last-of-type {
  margin-bottom: 30px;
}
.warranty-documents-modal .doc-viewer {
  align-items: center;
  display: flex;
  margin-bottom: 10px;
}
.warranty-documents-modal .doc-viewer:first-child {
  margin-top: 10px;
}
.warranty-documents-modal .doc-viewer .remove-doc-icon {
  cursor: pointer;
  width: 16px;
}
.warranty-documents-modal .doc-viewer .document-link {
  flex: 1;
  margin-left: 6px;
}
.warranty-documents-modal .modal-body {
  padding: 24px 40px 40px 40px !important;
}
