.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.draggable-gallery {
  display: grid;
  display: -ms-grid;
  display: -moz-grid;
}
.draggable-gallery .draggable-item {
  position: relative;
}
.draggable-gallery .draggable-item.selected {
  border-color: #00b091 !important;
}
.draggable-gallery .draggable-item.droppable {
  border-color: #fb6120 !important;
  border-style: dashed !important;
}
.draggable-gallery .draggable-item .selected-count {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 2;
  width: 30px;
  height: 30px;
  border-radius: 16px;
  background-color: #bcbdc1;
  border: 1px solid #ffffff;
}
.draggable-gallery .draggable-item .selected-count.selected {
  background-color: #00b091;
  border-color: #ffffff;
  color: #ffffff;
  font-weight: 600;
}
.draggable-gallery .draggable-item .left-drop {
  opacity: 1;
  position: absolute;
  height: 100%;
  top: 0;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: move;
}
.draggable-gallery .draggable-item .left-drop.droppable::after {
  display: block;
  content: '';
  height: 92%;
  width: 1px;
  border-left: 2px dashed #fb6120;
}
#DRAG_ITEM_CLONE.multiple {
  z-index: 2;
}
#DRAG_ITEM_CLONE.multiple .selected-count {
  display: none;
}
#DRAG_ITEM_CLONE.multiple::after,
#DRAG_ITEM_CLONE.multiple::before {
  content: '' !important;
  position: absolute;
  height: 100%;
  width: 100%;
  background: #eaeaec;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  z-index: -1;
  top: -5px;
  left: -5px;
}
#DRAG_ITEM_CLONE.multiple::before {
  top: -10px;
  left: -10px;
}
#DRAG_ITEM_CLONE.multiple.dark-mode::after,
#DRAG_ITEM_CLONE.multiple.dark-mode::before {
  background: #303441;
}
.dark-mode .draggable-gallery .draggable-item.selected {
  border-color: #d4fb00 !important;
}
.dark-mode .draggable-gallery .draggable-item.droppable {
  border-color: #02c7ff !important;
}
.dark-mode .draggable-gallery .draggable-item .selected-count.selected {
  background-color: #d4fb00;
  border-color: #191b24;
  color: #191b24;
}
.dark-mode .draggable-gallery .draggable-item .left-drop.droppable::after {
  border-left-color: #02c7ff;
}
