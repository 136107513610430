.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.project-dashboard-indicator {
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
}
.project-dashboard-indicator .indicator-name {
  font-size: 16px;
  line-height: 24px;
  color: #7a7e8b;
}
.project-dashboard-indicator .indicator-value {
  display: flex;
  align-items: center;
  margin-top: -6px;
  font-size: 36px;
  line-height: 54px;
  font-weight: 600;
  color: #454a5c;
}
.project-dashboard-indicator .indicator-value .indicator-tooltip-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-left: auto;
  cursor: pointer;
}
.project-dashboard-indicator.dark-mode .indicator-name {
  color: #bcbdc1;
}
.project-dashboard-indicator.dark-mode .indicator-value {
  color: #ffffff;
}
@media (max-height: 1000px), (max-width: 1600px) {
  .project-dashboard-indicator {
    padding: 8px 16px;
  }
  .project-dashboard-indicator .indicator-value {
    font-size: 32px;
    line-height: 48px;
  }
}
@media (max-height: 800px), (max-width: 1300px) {
  .project-dashboard-indicator {
    padding: 4px 16px;
  }
  .project-dashboard-indicator .indicator-name {
    font-size: 12px;
    line-height: 16px;
  }
  .project-dashboard-indicator .indicator-value {
    margin-top: 0px;
    font-size: 20px;
    line-height: 24px;
  }
  .project-dashboard-indicator .indicator-value .indicator-tooltip-icon {
    width: 16px;
    height: 16px;
  }
  .project-dashboard-indicator .indicator-value .indicator-tooltip-icon img {
    max-width: 16px;
  }
}
.tooltip.tooltip-control.project-dashboard-indicator-tooltip.left {
  margin-left: 107px;
}
.tooltip.tooltip-control.project-dashboard-indicator-tooltip.right .tooltip-inner {
  margin-left: -214px;
}
.tooltip.tooltip-control.project-dashboard-indicator-tooltip .tooltip-inner {
  min-width: 214px;
  max-width: 214px;
  padding: 16px;
}
.tooltip.tooltip-control.project-dashboard-indicator-tooltip .tooltip-inner > * {
  margin: 0px;
}
.tooltip.tooltip-control.project-dashboard-indicator-tooltip .tooltip-inner .indicator-tooltip-name {
  font-size: 12px;
  line-height: 18px;
  color: #7a7e8b;
}
.tooltip.tooltip-control.project-dashboard-indicator-tooltip .tooltip-inner .indicator-tooltip-current-value {
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.tooltip.tooltip-control.project-dashboard-indicator-tooltip .tooltip-inner .indicator-tooltip-field {
  display: flex;
  font-size: 11px;
  line-height: 16px;
}
.tooltip.tooltip-control.project-dashboard-indicator-tooltip .tooltip-inner .indicator-tooltip-field:last-child {
  margin-top: 6px;
}
.tooltip.tooltip-control.project-dashboard-indicator-tooltip .tooltip-inner .indicator-tooltip-field .indicator-tooltip-field-label {
  width: 45px;
  margin-right: 10px;
}
.tooltip.tooltip-control.project-dashboard-indicator-tooltip.dark-mode .indicator-tooltip-name {
  color: #bcbdc1;
}
