.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.multiple-select-list {
  padding-bottom: 8px;
  border: 1px solid #7a7e8b;
  overflow: auto;
  overflow: overlay;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
}
.multiple-select-list.show-height-3 {
  height: 100px;
}
.multiple-select-list.show-height-4 {
  height: 130px;
}
.multiple-select-list.show-height-5 {
  height: 160px;
}
.multiple-select-list.show-height-6 {
  height: 190px;
}
.multiple-select-list > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.multiple-select-list > ul > li {
  cursor: pointer;
  padding: 8px 16px;
  margin: 0px 16px;
  position: relative;
  border-bottom: 1px solid #7a7e8b;
  color: #454a5c;
}
.multiple-select-list > ul > li.selected {
  color: #00b091;
  border-color: #00b091;
}
.multiple-select-list > ul > li .msl-remove-button {
  align-items: center;
  display: flex;
  height: 100%;
  position: absolute;
  right: 10px;
  top: 0;
}
.multiple-select-list > ul > li .msl-remove-button img {
  cursor: pointer;
  height: 18px;
}
.multiple-select-list .msl-no-options {
  text-align: center;
  padding: 10px;
}
.multiple-select-list.dark-mode {
  border: 1px solid #bcbdc1;
}
.multiple-select-list.dark-mode > ul > li {
  color: #ffffff;
  border-color: #bcbdc1;
}
.multiple-select-list.dark-mode > ul > li.selected {
  color: #d4fb00;
  border-color: #d4fb00;
}
