.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.project-cancelled-lost-modal .project-cancelled-lost-container table {
  width: 100%;
  table-layout: auto;
}
.project-cancelled-lost-modal .project-cancelled-lost-container table tr.with-border td {
  border-top: 1px solid #edf1f7;
  padding-bottom: 15px;
  padding-top: 15px;
}
.project-cancelled-lost-modal .project-cancelled-lost-container table tr td:first-child {
  padding-left: 10px;
}
.project-cancelled-lost-modal .project-cancelled-lost-container table tr td.message {
  vertical-align: top;
}
.project-cancelled-lost-modal .project-cancelled-lost-container table tr td textarea {
  height: 200px;
}
.project-cancelled-lost-modal .project-cancelled-lost-container table tr td input {
  margin-bottom: 2%;
}
.project-cancelled-lost-modal .button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 28px;
  width: 100%;
}
.project-cancelled-lost-modal .button-container input {
  height: 45px;
  width: 140px;
}
.project-cancelled-lost-modal .dropdown-lost {
  padding-bottom: 10px;
}
