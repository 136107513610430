.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.punch-table:not(.empty-table) .punch-list-table-container {
  width: calc(100%);
  padding: 0px 16px;
  height: calc(100vh - 420px);
  overflow: auto;
  overflow: overlay;
}
.punch-table:not(.empty-table) .punch-list-table-container .punch-list-table {
  white-space: nowrap;
  max-height: 100%;
}
.punch-table:not(.empty-table) .punch-list-table-container .punch-list-table .actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.punch-table:not(.empty-table) .punch-list-table-container .punch-list-table .actions button {
  margin-right: 12px;
}
.punch-table:not(.empty-table) .punch-list-table-container .punch-list-table .actions .menu-image-sector-container {
  margin-bottom: 4px;
}
.punch-table:not(.empty-table) .punch-list-table-container .punch-list-table .due-date.text-orange {
  color: #fb6120;
}
.punch-table:not(.empty-table) .punch-list-table-container .punch-list-table .due-date .warning-icon {
  width: 22px;
  margin-left: 8px;
}
