.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.header {
  display: flex;
  height: 80px;
  justify-content: flex-start;
  background: #191b24;
}
.header.white-bg {
  background-color: #ffffff;
}
.header .logo {
  width: 170px;
  height: 40px;
  object-fit: contain;
  margin-top: 20px;
  margin-left: 40px;
}
.header .header-left-container {
  align-items: center;
  display: flex;
  padding-left: 140px;
}
.header .header-left-container .active {
  border-bottom: 2px solid #d4fb00;
  font-weight: 600;
}
.header .header-right-container {
  align-items: center;
  display: flex;
  position: absolute;
  right: 40px;
  top: 25px;
}
.header .header-right-container .switch-icon-container {
  background-color: #191b24;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  margin: 2px;
}
.header .header-right-container .switch-icon {
  background-color: #191b24;
  width: 7px;
  height: 10px;
  float: left;
  margin: 3px 4.5px;
}
