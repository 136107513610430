.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.field-reports-resolved-modal .modal-dialog {
  width: 80% !important;
}
.field-reports-resolved-modal .modal-content {
  height: 700px !important;
}
.field-reports-resolved-modal .modal-body {
  height: 100%;
  overflow: hidden !important;
  display: flex;
  flex-direction: column;
}
.field-reports-resolved-modal .container-header-title {
  display: flex;
  justify-content: space-between;
  margin: 16px 50px 24px 40px;
}
.field-reports-resolved-modal .container-header-title .modal-name {
  font-size: 28px;
  font-weight: 600;
  line-height: 46px;
}
.field-reports-resolved-modal .search-input-background {
  background-color: #f7f7f8;
}
.field-reports-resolved-modal .container-body-remark {
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 0px 40px 16px;
}
.field-reports-resolved-modal .container-body-remark .modal-no-items {
  font-size: 18px;
  text-align: center;
  color: #9ea0aa;
}
.field-reports-resolved-modal .pagination-container {
  align-self: flex-end;
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 16px 0;
}
.dark-mode .field-reports-resolved-modal .search-input-background {
  background-color: #21242f;
}
