.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.basic-modal.form-modal .modal-dialog .modal-content {
  display: flex;
}
.basic-modal.form-modal .modal-dialog .modal-content .modal-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.basic-modal.form-modal .modal-dialog .modal-content .modal-header {
  display: flex;
  flex-direction: column;
  padding: 0px;
  color: #454a5c;
}
.basic-modal.form-modal .modal-dialog .modal-content .modal-header .modal-header-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 70px;
  padding: 0px 40px;
}
.basic-modal.form-modal .modal-dialog .modal-content .modal-header .modal-header-bar .modal-header-bar-label {
  font-size: 23px;
  line-height: 38px;
}
.basic-modal.form-modal .modal-dialog .modal-content .modal-header .modal-header-bar .back-icon {
  cursor: pointer;
  margin-right: 24px;
}
.basic-modal.form-modal .modal-dialog .modal-content .modal-header .modal-header-bar .close-icon {
  margin-left: auto;
  cursor: pointer;
}
.basic-modal.form-modal .modal-dialog .modal-content .modal-header .modal-header-bar .modal-header-bar-actions {
  display: flex;
  margin-left: auto;
}
.basic-modal.form-modal .modal-dialog .modal-content .modal-header .modal-header-bar .modal-header-bar-actions > * {
  margin-left: 16px;
}
.basic-modal.form-modal .modal-dialog .modal-content .modal-header .modal-header-bar .modal-header-bar-actions + .close-icon {
  margin-left: 24px;
}
.basic-modal.form-modal .modal-dialog .modal-content .modal-header .modal-header-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 62px;
  padding: 0px 40px;
}
.basic-modal.form-modal .modal-dialog .modal-content .modal-header .modal-header-title .modal-header-title-label {
  font-weight: 600;
  font-size: 29px;
  line-height: 46px;
  letter-spacing: 0.7px;
}
.basic-modal.form-modal .modal-dialog .modal-content .modal-header .modal-header-title .modal-header-title-actions {
  margin-left: auto;
}
.basic-modal.form-modal .modal-dialog .modal-content .modal-header .modal-header-title .modal-header-title-actions > * {
  margin-left: 16px;
}
.basic-modal.form-modal .modal-dialog .modal-content .modal-body {
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  text-align: left;
  overflow: auto;
  overflow: overlay;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
}
.basic-modal.form-modal .modal-dialog .modal-content .modal-body .buttons-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 24px;
  padding-bottom: 0px;
}
.basic-modal.form-modal .modal-dialog .modal-content .modal-body .buttons-container .button {
  margin: 16px 8px 0px 8px;
}
.basic-modal.form-modal .modal-dialog .modal-content .modal-body .buttons-container.right-buttons {
  justify-content: flex-end;
}
.basic-modal.form-modal.dark-mode .modal-dialog .modal-content {
  background-color: #303441;
}
.basic-modal.form-modal.dark-mode .modal-dialog .modal-content .modal-header {
  color: #ffffff;
}
.form-modal-sidebar {
  display: flex;
  flex-direction: column;
  margin: 0.5px;
  background-color: #f7f7f8;
}
.form-modal-sidebar .sidebar-header {
  display: flex;
  align-items: center;
  padding: 0px 40px;
  height: 70px;
  cursor: pointer;
}
.form-modal-sidebar .sidebar-header .sidebar-title {
  margin-right: 12px;
  font-size: 23px;
  line-height: 34px;
  color: #454a5c;
}
.form-modal-sidebar .sidebar-header .sidebar-toggler {
  margin-left: auto;
  width: 12px;
}
.form-modal-sidebar .sidebar-content {
  display: flex;
  flex-direction: column;
  padding: 0px 40px 40px 40px;
}
.form-modal-sidebar.sidebar-closed .sidebar-header {
  padding: 0px 24px;
}
.form-modal-sidebar.sidebar-closed .sidebar-header .sidebar-toggler {
  transform: rotate(180deg);
}
.form-modal-sidebar.sidebar-closed .sidebar-content {
  display: none;
}
.form-modal-sidebar.dark-mode {
  background-color: #3b4050;
}
.form-modal-sidebar.dark-mode .sidebar-header .sidebar-title {
  color: #d4fb00;
}
