.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.client.recover-password .message > span {
  font-size: 14px !important;
  color: #ffffff;
}
.client.recover-password .button-next {
  margin-top: 60%;
}
.client.recover-password .button-next:hover {
  background-color: #d4fb00;
}
.client.recover-password .go-to-login {
  color: #d4fb00;
  font-size: 16px;
}
.client.recover-password .bottom-title-wrapper {
  margin-top: 49px;
}
@media (max-width: 767px) {
  .client.recover-password .button-next {
    margin: 30px auto 0;
    width: 100px;
  }
}
