.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.sidebar-form {
  display: flex;
}
.sidebar-form .sidebar-form-sidebar {
  display: flex;
  flex-direction: column;
  width: 443px;
  max-width: 33%;
  height: 100%;
  padding: 21px 40px 40px 40px;
  background-color: #eaeaec;
  overflow: auto;
  overflow: overlay;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
}
.sidebar-form .sidebar-form-sidebar .sidebar-form-title {
  font-weight: 600;
  font-size: 29px;
  line-height: 46px;
  color: #454a5c;
}
.sidebar-form .sidebar-form-sidebar .sidebar-form-options {
  margin-top: auto;
  margin-bottom: auto;
}
.sidebar-form .sidebar-form-sidebar .sidebar-form-options .sidebar-form-option {
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 30px;
  cursor: pointer;
  color: #7a7e8b;
}
.sidebar-form .sidebar-form-sidebar .sidebar-form-options .sidebar-form-option.selected {
  font-weight: 600;
  color: #00b091;
}
.sidebar-form .sidebar-form-sidebar .sidebar-form-options .sidebar-form-option.error {
  color: #fb6120;
}
.sidebar-form .sidebar-form-sidebar .sidebar-form-actions {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-left: auto;
}
.sidebar-form .sidebar-form-sidebar .sidebar-form-actions > *:not(:first-child) {
  margin-top: 12px;
}
.sidebar-form .sidebar-form-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 23px 40px 40px 40px;
  overflow: auto;
  overflow: overlay;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
  background-color: #f7f7f8;
}
.sidebar-form .sidebar-form-body .sidebar-form-body-title {
  font-size: 23px;
  line-height: 38px;
  color: #454a5c;
}
.sidebar-form .sidebar-form-body .sidebar-form-body-subtitle {
  display: flex;
  align-items: center;
  margin: 38px 0px 6px 0px;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #454a5c;
}
.sidebar-form .sidebar-form-body .sidebar-form-body-subtitle .tooltip-button {
  margin-left: 4px;
}
.sidebar-form .sidebar-form-body .sidebar-form-body-subtitle .radio-button {
  margin-left: 16px;
  font-weight: 400;
}
.sidebar-form .sidebar-form-body .sidebar-form-description {
  margin: 24px 0px;
  font-size: 16px;
  line-height: 24px;
  color: #454a5c;
}
.sidebar-form .sidebar-form-body .form-block {
  margin-bottom: 6px;
}
.sidebar-form .sidebar-form-body .sidebar-form-titles-block .form-control-block .form-control-label,
.sidebar-form .sidebar-form-body .sidebar-form-titles-block .form-control-block .form-control-bottom-label {
  display: none;
}
.sidebar-form .sidebar-form-body .sidebar-form-titles-block .form-block-text {
  padding-top: 0px;
  min-height: unset;
}
.sidebar-form.dark-mode .sidebar-form-sidebar {
  background-color: #3a3e4a;
}
.sidebar-form.dark-mode .sidebar-form-sidebar .sidebar-form-title {
  color: #ffffff;
}
.sidebar-form.dark-mode .sidebar-form-sidebar .sidebar-form-options .sidebar-form-option {
  color: rgba(255, 255, 255, 0.7);
}
.sidebar-form.dark-mode .sidebar-form-sidebar .sidebar-form-options .sidebar-form-option.selected {
  color: #d4fb00;
}
.sidebar-form.dark-mode .sidebar-form-sidebar .sidebar-form-options .sidebar-form-option.error {
  color: #02c7ff;
}
.sidebar-form.dark-mode .sidebar-form-body {
  background-color: #21242f;
}
.sidebar-form.dark-mode .sidebar-form-body .sidebar-form-body-title {
  color: #ffffff;
}
.sidebar-form.dark-mode .sidebar-form-body .sidebar-form-body-subtitle {
  color: #ffffff;
}
.sidebar-form.dark-mode .sidebar-form-body .sidebar-form-description {
  color: #ffffff;
}
.title-and-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.title-buttons-container {
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin-right: 40px;
}
