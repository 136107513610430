.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.rfi-question:not(:first-child) {
  margin-top: 40px;
}
.rfi-question .rfi-question-title,
.rfi-question .rfi-response-title {
  margin-bottom: 16px;
  padding: 9px 0px;
}
.rfi-question .rfi-question-title .details-title-text,
.rfi-question .rfi-response-title .details-title-text {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
}
.rfi-question .rfi-question-title.official-response .details-title-text,
.rfi-question .rfi-response-title.official-response .details-title-text {
  color: #fb6120;
}
.rfi-question.dark-mode .rfi-response-title.official-response .details-title-text {
  color: #02c7ff;
}
