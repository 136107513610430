.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.simplified-daily-report-form-modal .modal-dialog {
  width: 80%;
  max-width: 1200px;
}
@media (max-width: 1400px) {
  .simplified-daily-report-form-modal .modal-dialog {
    width: 90%;
  }
}
.simplified-daily-report-form-modal .menu-buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 12px;
  justify-content: center;
  gap: 20px;
}
.simplified-daily-report-form-modal .menu-buttons-container .menu-button {
  width: 153px;
  margin-bottom: 12px;
}
.simplified-daily-report-form-modal .menu-buttons-container .buttons-separate {
  height: 12px;
}
.simplified-daily-report-form-modal .menu-buttons-container .white-button-background-project-reports {
  background-color: inherit !important;
}
.simplified-daily-report-form-modal .simplified-daily-report-form-body {
  padding: 0px 40px;
  overflow: auto;
  overflow: overlay;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
}
.simplified-daily-report-form-modal.initial-form .modal-dialog {
  max-width: 1200px;
}
.simplified-daily-report-form-modal.initial-form .simplified-daily-report-form-body {
  padding-bottom: 210px;
}
.simplified-daily-report-form-modal.submitted-message .modal-dialog {
  max-width: 700px;
}
.simplified-daily-report-form-modal .modal-header-bar-label {
  display: flex;
  align-items: center;
}
.simplified-daily-report-form-modal .modal-header-bar-label .date-picker {
  margin-right: 24px;
  max-width: 125px;
}
.simplified-daily-report-form-modal .modal-form-subtitle {
  margin-top: 40px;
  margin-bottom: 24px;
}
.simplified-daily-report-form-modal .error-message {
  margin: 0px 40px;
}
.simplified-daily-report-form-modal .reports-modal-actions {
  margin-bottom: 24px;
}
.simplified-daily-report-form-modal .trade-item-floor-items {
  padding-left: 0px;
}
.simplified-daily-report-form-modal .trade-item-floor-items .trade-item-floor-item {
  cursor: pointer;
}
.simplified-daily-report-form-modal .location-section-container {
  display: flex;
  flex-direction: column;
}
.simplified-daily-report-form-modal .location-section-container .simplified-daily-report-map-block {
  margin-bottom: 56px !important;
}
.simplified-daily-report-form-modal .location-section-container .simplified-daily-report-map-block .map-container {
  height: 290px !important;
}
.simplified-daily-report-form-modal .form-block.timezone-bock {
  flex-direction: column;
}
.simplified-daily-report-form-modal .form-block.weather-block .input-condition,
.simplified-daily-report-form-modal .form-block.weather-block .input-precipitation,
.simplified-daily-report-form-modal .form-block.weather-block .input-wind,
.simplified-daily-report-form-modal .form-block.weather-block .input-humidity,
.simplified-daily-report-form-modal .form-block.weather-block .input-temperature,
.simplified-daily-report-form-modal .form-block.weather-block .input-wind-direction {
  flex-basis: 20%;
}
.simplified-daily-report-form-modal .form-block.weather-block .input-weather-remarks {
  flex-basis: 53%;
}
.simplified-daily-report-form-modal .form-block.weather-block .input-condition,
.simplified-daily-report-form-modal .form-block.weather-block .input-precipitation {
  min-width: 160px;
}
.simplified-daily-report-form-modal .form-block.weather-block .input-wind-direction {
  min-width: 140px;
}
.simplified-daily-report-form-modal .form-block.weather-block .weather-label {
  display: flex;
  align-items: center;
}
.simplified-daily-report-form-modal .form-block.weather-block .weather-label .link-button {
  margin-top: 0;
  margin-bottom: 10px;
}
.simplified-daily-report-form-modal .form-block.weather-block.weather {
  margin-right: 0;
  margin-bottom: 20px;
}
.simplified-daily-report-form-modal .form-block.weather-block.observation {
  margin-top: 0px;
  margin-bottom: 16px;
  margin-right: 0 !important;
}
.simplified-daily-report-form-modal .form-control-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
}
.simplified-daily-report-form-modal .form-control-container.weather {
  margin-right: 0;
  margin-bottom: 20px;
}
.simplified-daily-report-form-modal .form-control-container.observation {
  margin-top: 0px;
  margin-bottom: 16px;
  margin-right: 0 !important;
}
.simplified-daily-report-form-modal .form-block.multicontrols-block .daily-report-sub-technician-user {
  width: 240px;
}
.simplified-daily-report-form-modal .daily-report-add-delivery {
  width: 240px;
}
.simplified-daily-report-form-block-label {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 20px;
  margin-right: 30px;
  color: #454a5c;
}
.simplified-daily-report-radiobox-group-label {
  font-weight: 400;
  color: #7a7e8b;
  margin-bottom: 8px;
}
.red-safety-issues {
  color: red;
}
