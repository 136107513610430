.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.tooltip.tooltip-control {
  opacity: 1;
  font-family: Titillium Web, sans-serif !important;
}
.tooltip.tooltip-control .tooltip-arrow {
  border-style: none;
  border-bottom: 8px solid #ffffff;
  border-left: 12px solid transparent;
  margin-top: -3px;
  z-index: 1;
}
.tooltip.tooltip-control .tooltip-inner {
  padding: 32px;
  background-color: #ffffff;
  border-radius: unset;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.15));
  text-align: left;
  color: #454a5c;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
.tooltip.tooltip-control.left {
  margin-left: 135px;
}
.tooltip.tooltip-control.left .tooltip-arrow {
  top: -5px;
  left: 10px !important;
  transform: scaleX(-1);
}
.tooltip.tooltip-control.right .tooltip-arrow {
  left: unset !important;
  right: 0px;
}
.tooltip.tooltip-control.right .tooltip-inner {
  margin-left: -270px;
}
.tooltip.tooltip-control.top .tooltip-arrow {
  top: unset;
  bottom: -5px;
  border-bottom: unset;
  border-top: 8px solid #ffffff;
}
.tooltip.tooltip-control.top.right .tooltip-arrow {
  right: 5px;
}
.tooltip.tooltip-control.small .tooltip-inner {
  min-width: 180px;
  max-width: 180px;
}
.tooltip.tooltip-control.small.left {
  margin-left: 90px;
}
.tooltip.tooltip-control.small.right .tooltip-inner {
  margin-left: -180px;
}
.tooltip.tooltip-control.medium .tooltip-inner {
  min-width: 270px;
  max-width: 270px;
}
.tooltip.tooltip-control.medium.left {
  margin-left: 135px;
}
.tooltip.tooltip-control.medium.right .tooltip-inner {
  margin-left: -270px;
}
.tooltip.tooltip-control.big .tooltip-inner {
  min-width: 466px;
  max-width: 466px;
}
.tooltip.tooltip-control.big.left {
  margin-left: 233px;
}
.tooltip.tooltip-control.big.right .tooltip-inner {
  margin-left: -466px;
}
.tooltip.tooltip-control.dark-mode.top .tooltip-arrow {
  border-top: 8px solid #3b4050;
}
.tooltip.tooltip-control.dark-mode.bottom {
  border-bottom: 8px solid #3b4050;
}
.tooltip.tooltip-control.dark-mode .tooltip-inner {
  background-color: #3b4050;
  color: #ffffff;
}
