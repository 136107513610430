.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.submittals-table-container {
  position: relative;
}
.submittals-table-container .submittals-table colgroup > col:nth-child(1) {
  width: 10%;
}
.submittals-table-container .submittals-table colgroup > col:nth-child(2) {
  width: 20%;
}
.submittals-table-container .submittals-table colgroup > col:nth-child(3) {
  width: 10%;
}
.submittals-table-container .submittals-table colgroup > col:nth-child(4) {
  width: 50%;
}
.submittals-table-container .submittals-table colgroup > col:nth-child(5) {
  width: 10%;
}
.submittals-table-container .submittals-table .csi-code-row td {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
  text-transform: uppercase;
  font-weight: 600;
  background-color: #303441;
  color: rgba(255, 255, 255, 0.5);
  border: none;
}
