.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.security-camera {
  position: relative;
  display: flex;
  flex-direction: column;
  aspect-ratio: 1.25;
  background-color: #ffffff;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
.security-camera .loading-camera {
  position: absolute;
  width: 50%;
  height: 50%;
  top: calc(25% - 30px);
  left: 25%;
  color: #9ea0aa;
}
.security-camera .security-camera-metadata {
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% + 1px);
  padding: 8px 16px;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  z-index: 1;
}
.security-camera .security-camera-metadata::after {
  content: '';
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  background: rgba(0, 0, 0, 0.1);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
}
.security-camera .security-camera-metadata.without-camera {
  color: #454a5c;
}
.security-camera .security-camera-metadata.without-camera::after {
  backdrop-filter: unset;
  background: #eaeaec;
}
.security-camera .security-camera-metadata > * {
  position: relative;
  z-index: 1;
}
.security-camera .security-camera-name {
  font-weight: 600;
}
.security-camera .security-camera-description {
  display: none;
}
.security-camera:hover .security-camera-description {
  display: block;
}
.security-camera.dark-mode {
  background-color: #303441;
}
.security-camera.dark-mode .security-camera-metadata.without-camera {
  color: #ffffff;
}
.security-camera.dark-mode .security-camera-metadata.without-camera::after {
  background: #3b4050;
}
