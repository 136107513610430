.reports {
  padding-top: 37px;
  position: relative;
}
.reports .content-container .pagination-container {
  margin-top: 30px;
  text-align: center;
}
.reports .content-container .report-table .no-top-border {
  border-top: none;
}
