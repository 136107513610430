.date-picker {
  position: relative;
  max-width: 180px;
}
.date-picker .icon-image {
  width: 14px;
  height: 16px;
  position: relative;
  top: -1px;
}
.date-picker .date-picker-remove {
  position: absolute;
  right: 32px;
  bottom: 16px;
  cursor: pointer;
}
.date-picker .date-picker-remove.hidden-adornment {
  right: 10px;
}
.date-picker .date-picker-remove.hidden-underline {
  right: 21px;
}
.date-picker .date-picker-remove.hidden-underline.hidden-adornment {
  right: 0px;
}
.date-picker .MuiFormControl-root {
  width: 100%;
}
