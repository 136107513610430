.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.project-form {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.project-form .main-form-title {
  margin-bottom: 32px;
}
.project-form .search-bar {
  margin: 0px 40px;
}
.project-form .content-container {
  display: flex;
  flex-direction: column;
  height: calc(100% - 86px);
  padding: 24px 0px !important;
}
.project-form .project-success {
  display: table;
  height: 380px;
  text-align: center;
  width: 100%;
  color: #454a5c;
}
.project-form .project-success .project-success-content {
  display: table-cell;
  vertical-align: middle;
}
.project-form .project-success .project-success-content .success-message {
  font-size: 16px;
  margin-bottom: 34px;
}
.project-form .details-form {
  margin: 24px 16px 32px 40px;
  max-height: 100%;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
}
.project-form .details-form.company-selection {
  overflow: unset;
  margin-bottom: 0px;
}
.project-form .selection-table-container {
  margin: 0px 16px;
  height: 100%;
  overflow: auto;
  overflow: overlay;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
}
.project-form .selection-table-container table colgroup > col:nth-child(1) {
  width: 65px;
}
.project-form .selection-table-container table colgroup > col:nth-child(2) {
  width: 20%;
}
.project-form .selection-table-container.owner-selection {
  margin-top: 16px;
}
.project-form .Select-menu .substatus-option {
  margin-left: 25px;
}
.project-form .buttons-container {
  display: flex;
  align-self: center;
  margin-top: auto;
  padding-top: 8px;
}
.project-form .buttons-container > *:not(:first-child) {
  margin-left: 24px;
}
.project-form.dark-mode .project-success {
  color: #ffffff;
}
