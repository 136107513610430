.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.field-work-orders-table table colgroup > col:nth-child(1) {
  width: 10%;
}
.field-work-orders-table table colgroup > col:nth-child(2) {
  width: 15%;
}
.field-work-orders-table table colgroup > col:nth-child(3) {
  width: 10%;
}
.field-work-orders-table table colgroup > col:nth-child(4) {
  width: 5%;
}
.field-work-orders-table table colgroup > col:nth-child(5) {
  width: 40%;
}
.field-work-orders-table table colgroup > col:nth-child(6) {
  width: 10%;
}
.field-work-orders-table table colgroup > col:nth-child(7) {
  width: 10%;
}
