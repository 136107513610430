.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.email-history-modal {
  padding: 24px 40px 40px 40px;
}
.email-history-modal .flex-row {
  display: flex;
  flex-direction: row;
}
.email-history-modal .history-label {
  width: 60px;
}
