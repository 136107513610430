.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.direct-upload-files {
  align-items: flex-end !important;
  justify-content: flex-end !important;
}
.direct-upload-files .content {
  width: 350px;
  margin: 0 32px;
  padding: 0 !important;
  background-color: #ffffff;
}
.direct-upload-files .content ul {
  overflow: auto;
  max-height: 300px;
  padding-top: 0;
}
.direct-upload-files .content .buttons button {
  width: 100%;
}
.dark-mode .direct-upload-files .content {
  background-color: #21252e;
}
