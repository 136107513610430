.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.parents-table {
  margin-bottom: 0;
}
.parents-table.sticky-header {
  position: relative;
}
.parents-table.sticky-header thead th {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  top: 0;
  background-color: #f7f7f8;
  z-index: 1;
}
.parents-table thead tr th {
  padding-top: 13px;
  padding-bottom: 13px;
  border-bottom: 1px solid #eaeaec;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #9ea0aa;
}
.parents-table thead tr th:first-child {
  padding-left: 24px;
}
.parents-table tbody tr.selectable {
  cursor: pointer;
}
.parents-table tbody tr:first-child td {
  border-top: none;
}
.parents-table tbody tr:not(:first-child) td {
  border-top: 1px solid #d2d5d9;
}
.parents-table tbody tr.last-row:last-child td {
  border-bottom: 1px solid #d2d5d9;
}
.parents-table tbody tr.link-tab td > a {
  color: inherit;
  display: block;
  text-decoration: none;
}
.parents-table tbody tr td {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eaeaec;
  vertical-align: middle;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #454a5c;
}
.parents-table tbody tr td:first-child {
  padding-left: 24px;
}
.parents-table tbody tr td:nth-child(2) {
  padding-top: 25px;
}
.parents-table tbody tr td.read {
  color: #9ea0aa;
}
.parents-table tbody tr td .clickable {
  color: #00b091;
}
.parents-table tbody tr td .total-row {
  font-weight: bold;
  color: #00b091;
}
.parents-table tbody tr td .base-status-color.unread {
  background-color: #fb6120;
  margin-left: -16px;
  margin-right: 7px;
}
.parents-table tbody tr td .action-buttons {
  display: flex;
  align-items: center;
}
.parents-table tbody tr td .action-buttons > *:not(:last-child) {
  margin-right: 12px;
}
.parents-table tbody tr td .more-options .menu-image-sector-container .menu-image-sector {
  vertical-align: unset;
}
.parents-table .td-empty-table {
  text-align: center;
  height: 200px;
  vertical-align: middle;
  font-size: 24px;
  letter-spacing: -0.6px;
  color: rgba(69, 74, 92, 0.5);
  width: 100%;
}
.parents-table.stretch-table tbody tr td {
  padding-top: 10px;
  padding-bottom: 10px;
}
.parents-table.dark-mode thead > tr > th {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.05);
}
.parents-table.dark-mode tbody tr td {
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.05);
}
.parents-table.dark-mode tbody tr td.read {
  color: rgba(255, 255, 255, 0.5);
}
.parents-table.dark-mode tbody tr td .clickable {
  color: #d4fb00;
}
.parents-table.dark-mode tbody tr td .total-row {
  color: #d4fb00;
}
.parents-table.dark-mode tbody tr td .base-status-color.unread {
  background-color: #02c7ff;
}
.parents-table.dark-mode tbody tr.link-tab td > a {
  color: #ffffff;
}
.parents-table.dark-mode tbody tr.link-tab td.read > a {
  color: rgba(255, 255, 255, 0.5);
}
.parents-table.dark-mode .td-empty-table {
  color: rgba(255, 255, 255, 0.5);
}
.parents-table.dark-mode.sticky-header thead,
.parents-table.dark-mode.sticky-header th {
  background-color: #21242f;
}
.basic-table thead tr th {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
  border-bottom: 1px solid rgba(69, 74, 92, 0.08) !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: rgba(69, 74, 92, 0.5) !important;
}
.basic-table thead tr th:first-child {
  padding-left: 24px !important;
}
.basic-table tbody tr td {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  border-bottom: 1px solid rgba(69, 74, 92, 0.08) !important;
  vertical-align: middle !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #454a5c !important;
}
.basic-table tbody tr td:first-child {
  padding-left: 24px !important;
}
.basic-table tbody tr td .clickable {
  color: #00b091;
}
.basic-table tbody tr td .total-row {
  color: #00b091;
}
.basic-table tbody tr td .base-status-color.unread {
  background-color: #fb6120;
  margin-left: -16px;
  margin-right: 7px;
}
.basic-table .empty-table-message {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  font-size: 24px;
  letter-spacing: -0.6px;
  color: rgba(69, 74, 92, 0.5);
}
.basic-table.dark-mode thead > tr > th {
  color: rgba(255, 255, 255, 0.5) !important;
  border-color: rgba(255, 255, 255, 0.05) !important;
  background-color: #21242f;
}
.basic-table.dark-mode tbody tr td {
  color: #ffffff !important;
  border-color: rgba(255, 255, 255, 0.05) !important;
}
.basic-table.dark-mode tbody tr td .clickable {
  color: #d4fb00;
}
.basic-table.dark-mode tbody tr td .total-row {
  color: #d4fb00;
}
.basic-table.dark-mode tbody tr td .base-status-color.unread {
  background-color: #02c7ff;
}
.basic-table.dark-mode .empty-table-message {
  color: rgba(255, 255, 255, 0.5);
}
.parent-row {
  padding-left: 50px;
}
.parent-row-cell {
  background-color: #eaeaec;
  padding-right: 0px !important;
  padding-left: 20px !important;
}
.parent-row-cell-radio {
  background-color: #eaeaec;
  padding-right: auto !important;
  padding-left: auto !important;
  width: 100px !important;
  max-height: 20px !important;
  justify-content: center;
  border: 0px solid !important;
}
.icon-cell {
  width: fit-content;
}
.small-row-parent {
  padding-left: 10px !important;
  padding-right: 20px !important;
  background-color: #eaeaec;
}
.smaller-row-parent {
  padding-left: 10px !important;
  padding-right: 10px !important;
  background-color: #eaeaec;
}
.small-row-parent-attendance {
  display: flex;
  max-width: 30px !important;
  background-color: #eaeaec;
  width: fit-content;
}
.smaller-row-parent-attendance {
  display: flex;
  background-color: #eaeaec;
}
.small-row-child-attendance {
  display: flex;
  max-width: 30px !important;
  background-color: #f7f7f8;
  width: fit-content;
  color: #f7f7f8;
  border: 0px solid #f7f7f8 !important;
}
.parent-row-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
  left: 0px;
}
.child-row {
  padding-left: 50px;
}
.child-row-cell {
  background-color: #f7f7f8;
  padding-right: 0px !important;
  padding-left: 20px !important;
  width: 350px;
  border: 0px solid !important;
}
.child-row-cell-radio {
  background-color: #f7f7f8;
  padding-right: auto !important;
  padding-left: auto !important;
  width: 100px !important;
  max-height: 20px !important;
  justify-content: center;
  border: 0px solid !important;
}
.child-row-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
  left: 0px;
}
.child-header-row {
  width: max-content;
}
.child-header-row-cell {
  background-color: #f7f7f8;
  padding-right: 0px !important;
  padding-left: 20px !important;
  width: 350px;
  border: 0px solid !important;
}
.child-header-small-row-cell {
  display: flex;
  max-width: 30px !important;
  background-color: #f7f7f8;
  width: fit-content;
  color: #f7f7f8;
  border: 0px solid #f7f7f8 !important;
}
.child-header-row-cell-big {
  background-color: #f7f7f8;
  padding-right: 0px !important;
  padding-left: 20px !important;
  width: 700px;
  border: 0px solid !important;
}
.child-row-cell-big {
  background-color: #f7f7f8;
  padding-right: 0px !important;
  padding-left: 20px !important;
  width: 1200px;
  border: 0px solid !important;
}
.category-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: black;
  margin-bottom: 20px;
  margin-top: 20px;
}
.action-item-row {
  display: flex;
  flex: 1;
  flex-direction: row;
  max-width: fit-content;
}
.action-item-row-cell {
  display: flex;
  flex: 1;
  flex-direction: row;
  width: fit-content;
}
.action-item-header-cell {
  border: 0px solid !important;
}
.dark-mode .category-title {
  color: white;
}
.dark-mode .parent-row-cell {
  background-color: rgba(255, 255, 255, 0.1);
  border: 0px solid rgba(255, 255, 255, 0.1) !important;
}
.dark-mode .small-row-parent {
  background-color: rgba(255, 255, 255, 0.1);
  border: 0px solid rgba(255, 255, 255, 0.1) !important;
}
.dark-mode .smaller-row-parent {
  background-color: rgba(255, 255, 255, 0.1);
  border: 0px solid rgba(255, 255, 255, 0.1) !important;
}
.dark-mode .small-row-parent-attendance {
  background-color: rgba(255, 255, 255, 0.1);
  border: 0px solid rgba(255, 255, 255, 0.1) !important;
}
.dark-mode .smaller-row-parent-attendance {
  background-color: rgba(255, 255, 255, 0.1);
  border: 0px solid rgba(255, 255, 255, 0.1) !important;
}
.dark-mode .child-header-row-cell-big {
  background-color: #21242f;
}
.dark-mode .child-row-cell-big {
  background-color: #21242f;
}
.dark-mode .child-header-row-cell {
  background-color: #21242f;
}
.dark-mode .child-header-small-row-cell {
  background-color: #21242f;
}
.dark-mode .child-row-cell {
  background-color: #21242f !important;
}
.dark-mode .child-row-cell-radio {
  background-color: #21242f !important;
}
.dark-mode .small-row-child-attendance {
  background-color: #21242f !important;
}
.header-row {
  padding-left: 40px !important;
  padding-right: 20px !important;
  margin-left: 40px !important;
  margin-right: 40px !important;
  background-color: #454a5c;
}
.header-row-attendance {
  background-color: #f7f7f8 !important;
  color: #9ea0aa;
}
.filters-container {
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  flex-direction: row;
}
.header-row-cell {
  background-color: #454a5c;
  padding: 10px 20px;
}
.header-row-cell-attendance {
  background-color: #f7f7f8;
  padding: 10px 20px;
  width: 60px !important;
  text-align: start !important;
}
.header-row-cell-attendance-radio {
  background-color: #f7f7f8;
  padding: 10px 20px;
  max-width: 60px !important;
  text-align: center !important;
}
.header-row-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: white;
}
.header-row-text-attendance {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #9ea0aa;
  text-align: center !important;
}
.small-row-header {
  width: 30px;
  background-color: #454a5c;
  padding: 0px 10px;
}
.small-row-header-attendance {
  width: 30px;
  background-color: #f7f7f8;
  padding: 0px 10px;
  max-width: 80px !important;
}
.dropdown-alert-icon {
  color: #fb6120;
  margin-top: 3px;
  height: 27px !important;
  width: 27px !important;
}
.dropdown-alert-icon-green {
  color: #00b091;
  margin-top: 3px;
  height: 27px !important;
  width: 27px !important;
}
.dropdown-alert-menu {
  margin-left: 10px !important;
}
.dark-mode .dropdown-alert-icon-green {
  color: #d4fb00;
}
.dark-mode .header-row-cell {
  background-color: #454a5c;
  color: #21242f;
}
.dark-mode .small-row-header {
  background-color: #454a5c;
}
.dark-mode .small-row-header-attendance {
  background-color: #21242f;
}
.dark-mode .header-row-cell-attendance {
  background-color: #21242f;
}
.dark-mode .small-row-header-attendance {
  background-color: #21242f;
}
.dark-mode .header-row-cell-attendance-radio {
  background-color: #21242f;
}
.dark-mode .parent-row-cell-radio {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-mode .parent-row-cell {
  border: 0px solid rgba(255, 255, 255, 0.1);
}
.filter-container .simple-header {
  padding: 10px 20px !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 600 !important;
  color: white !important;
}
.rotated-vector-icon {
  transform: rotate(180deg);
}
.action-icon-button {
  bottom: 4px !important;
}
.action-icon-lock {
  bottom: 0px !important;
}
.action-icon {
  color: #00b091 !important;
  height: 20px;
}
.action-icon.disabled {
  opacity: 0.5;
}
.alert-icon-button {
  color: #fb6120 !important;
  bottom: 4px !important;
  padding-right: 5px !important;
}
.alert-icon {
  height: 20px;
}
.person-icon {
  padding-right: 20px !important;
  bottom: 4px !important;
  color: #00b091 !important;
  width: 10px;
  height: 30px;
  align-self: center !important;
}
.type-cell {
  display: flex;
  flex-direction: row;
}
.tooltip-content {
  display: flex;
  flex-direction: row;
  padding: 10px;
  background-color: white !important;
}
.tooltip-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  color: #454a5c;
}
.tooltip-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #454a5c;
}
.MuiTooltip-tooltip {
  background-color: white !important;
}
.tooltip-icon {
  color: #00b091 !important;
  margin-bottom: 10px !important;
}
.dark-mode .person-icon {
  color: #d4fb00 !important;
}
.dark-mode .action-icon {
  color: #d4fb00 !important;
}
.dark-mode .header-row-text-attendance {
  background-color: #21242f;
}
.dark-mode .tooltip-icon {
  color: #d4fb00 !important;
}
.dark-mode .tooltip-content {
  background-color: red;
}
.dark-mode .tooltip-text {
  color: white;
}
.dark-mode .tooltip-title {
  color: white;
}
.dark-mode .MuiTooltip-tooltip {
  background-color: #454a5c !important;
}
.menu-container-parent {
  width: 30px !important;
  padding-right: 20px !important;
  padding-top: 8px;
}
.menu-container {
  width: 30px !important;
  padding-right: 20px !important;
  margin-top: 2px;
}
.menu-container-small {
  width: 30px !important;
  padding-right: 20px !important;
  padding-top: 2px;
}
.menu-container-corrections {
  margin-left: -10px !important;
}
.actions-container {
  display: flex;
  flex-direction: row;
  color: #00b091;
  padding-left: 0;
}
.actions-container-archived {
  display: flex;
  flex-direction: row;
  color: #00b091;
  padding-left: 0;
  align-items: flex-end;
}
.button-container {
  margin-top: 4px !important;
}
.button-container-archived {
  margin-top: 4px !important;
}
.action-items-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
}
.action-item-header {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}
.action-item-header-cell {
  padding-left: 0px !important;
}
.filter-header-value.selected-value {
  color: white !important;
}
