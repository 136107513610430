.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.dashboard {
  color: #3c4249;
  display: flex;
}
.dashboard .dashboard-content {
  flex: auto;
}
.dashboard .dashboard-content .dashboard-activity {
  width: 100%;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}
.dashboard .dashboard-content .dashboard-activity .recent-activity,
.dashboard .dashboard-content .dashboard-activity .chat-activity {
  width: calc(50% - 12px);
}
.dashboard .dashboard-sidebar {
  max-width: 236px;
  min-width: 236px;
}
