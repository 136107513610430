.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.punch-list-form {
  padding: 16px 40px 20px;
  overflow: auto;
  overflow: overlay;
}
.punch-list-form .select-dropdown.read-only .MuiSelect-select {
  padding-left: 8px;
}
.punch-list-form .select-dropdown.read-only .selector-arrow {
  display: none;
}
.punch-list-form .due-date {
  max-width: 100%;
}
.punch-list-form .due-date.readonly {
  max-width: 120px;
}
.punch-list-form .due-date .rdtPicker {
  right: 0;
}
.punch-list-form .chars-counter-textarea.title .text-area {
  font-size: 16px;
  line-height: 16px;
  height: 40px !important;
  min-height: 40px !important;
  padding-top: 9px;
}
.punch-list-form .chars-counter-textarea.title .chars-counter {
  text-align: left;
}
.punch-list-actions .error-message {
  margin-right: 40px;
  margin-top: 20px;
  margin-bottom: 6px;
}
.punch-list-actions .punch-list-form-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}
.punch-list-actions .punch-list-form-actions .button {
  margin: 16px 8px;
}
.punch-list-actions .punch-list-form-actions .button:first-child {
  margin-left: 0;
}
.punch-list-actions .punch-list-form-actions .button:last-child {
  margin-right: 0;
}
