.popover-children.filter-header.date-filter,
.popover-children.filter-header.exteded-filter {
  min-width: 140px;
}
.schedule-table-container {
  height: 100%;
  overflow: auto;
  overflow: overlay;
}
.schedule-table-container .schedule-table {
  white-space: nowrap;
  max-height: 100%;
}
.schedule-table-container .schedule-table thead > tr > th,
.schedule-table-container .schedule-table tbody > tr > th,
.schedule-table-container .schedule-table thead > tr > td,
.schedule-table-container .schedule-table tbody > tr > td {
  padding-left: 16px;
  padding-right: 16px;
}
.schedule-table-container .schedule-table thead > tr > th:last-child button,
.schedule-table-container .schedule-table tbody > tr > th:last-child button,
.schedule-table-container .schedule-table thead > tr > td:last-child button,
.schedule-table-container .schedule-table tbody > tr > td:last-child button {
  float: left;
  margin-right: 16px;
}
.range-selector {
  padding: 0 !important;
}
.range-selector .range-submenu {
  width: 100%;
}
.range-selector .range-submenu .menu-label-sector {
  padding: 8px 16px;
}
.popover-children.range-submenu .menu-item {
  padding: 2px 16px 2px 8px;
}
.popover-children.range-submenu .menu-item .date-picker input {
  border-bottom: none;
}
.popover-children.date-filter .date-menu-item {
  padding: 0 !important;
}
.popover-children.date-filter .date-menu-item .date-picker {
  padding-right: 16px;
  padding-left: 16px;
}
.popover-children.date-filter .date-menu-item .date-picker .date-picker-remove {
  right: 40px;
}
.MuiMenu-paper {
  transition-duration: 0s !important;
}
