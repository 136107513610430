.order-materials__page-title {
  font-weight: 700;
  font-size: 36px;
}
.order-materials__wrapper {
  padding: 0 40px;
  overflow: auto;
  height: 100%;
  width: 100%;
  padding-bottom: 40px;
}
.order-materials__column-title {
  font-weight: 600;
  font-size: 16px;
}
.order-materials__column-text {
  font-size: 16px;
}
.mt-12px {
  margin-top: 12px;
}
.mt-33px {
  margin-top: 33px;
}
.mt-40px {
  margin-top: 40px;
}
.mt-96px {
  margin-top: 96px;
}
.order-materials__text {
  font-size: 16px;
  font-weight: 300;
}
.order-materials__total {
  font-size: 20px;
  font-weight: 600;
}
.order-materials__info-text {
  font-size: 14px;
  font-weight: 300;
  color: #a4a5a8;
}
