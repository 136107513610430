.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.sort-header {
  display: flex;
  cursor: pointer;
  user-select: none;
}
.sort-header.selected-header {
  color: #454a5c;
}
.sort-header .header-label-icons {
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.sort-header .header-label-icons .header-label-icon:not(:first-child) {
  margin-top: 4px;
}
.sort-header .header-label-icons .header-label-icon.up-icon,
.sort-header .header-label-icons .header-label-icon.down-icon {
  width: 11px;
  height: 6.25px;
}
.sort-header .header-label-icons .header-label-icon.up-icon {
  transform: rotate(180deg);
}
.sort-header.dark-mode.selected-header {
  color: #ffffff;
}
