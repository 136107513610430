.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.show-invitation {
  border-bottom: 1px solid #dfe6f1;
  padding-bottom: 40px;
}
.show-invitation .button-remove {
  color: #97a2b5;
  font-size: 15px;
  cursor: pointer;
}
.show-invitation .name {
  color: #3c4249;
}
.show-invitation .permissions-table .permissions-table-row .permissions-table-cell .label.not-selected {
  color: #8e9aae;
}
.show-invitation .selected-icon {
  color: #fb6120;
  font-size: 10px;
}
