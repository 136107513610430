.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.invitation-container {
  padding: 0 !important;
  margin: 0 !important;
}
.invitation-container .label {
  color: white !important;
  margin-top: 10px;
  padding: 0;
}
.invitation-container .permissions-table {
  display: table;
}
.invitation-container .permissions-table .permissions-table-row {
  display: table-row;
}
.invitation-container .permissions-table .permissions-table-row .permissions-table-cell {
  display: table-cell;
  vertical-align: middle;
  height: 30px;
}
.invitation-container .permissions-table .permissions-table-row .permissions-table-cell .label {
  color: #3c4249;
  margin-left: 9px;
}
.invitation-container .permissions-table .permissions-table-row .permissions-table-cell-checkbox {
  text-align: center;
  width: 20px;
}
