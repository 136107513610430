.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.closeout-table {
  margin-top: 25px;
  overflow-x: scroll;
  max-height: 620px;
  overflow-y: scroll;
}
.closeout-table table {
  table-layout: fixed;
}
.closeout-table table colgroup > col {
  width: 175px;
}
.closeout-table table colgroup > col:first-child {
  width: 230px;
}
.closeout-table table colgroup > col:nth-child(2) {
  width: 200px;
}
.closeout-table table thead th {
  vertical-align: top;
}
.closeout-table table thead th:first-child {
  left: 0;
  z-index: 1;
}
.closeout-table table thead th:nth-child(2) {
  left: 230px;
  z-index: 1;
}
.closeout-table table tbody th {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  left: 0;
  background: #f7f7f8;
}
.closeout-table table tr td {
  text-align: left;
}
.closeout-table table tr td:first-child,
.closeout-table table tr td:nth-child(2) {
  white-space: nowrap;
  position: sticky;
  background: #f7f7f8;
}
.closeout-table table tr td:first-child {
  left: 0;
}
.closeout-table table tr td:nth-child(2) {
  left: 230px;
}
.closeout-table table.dark-mode thead th {
  background: #21242f;
}
.closeout-table table.dark-mode tr td {
  background: #21242f;
}
