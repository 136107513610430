.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.admin {
  height: 100%;
}
.admin .admin-col {
  height: 100%;
}
.admin .admin-col.item-container {
  padding: 24px 40px 40px 40px;
  overflow: auto;
  overflow: overlay;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
  /* To show content with Col 8 without paddings */
}
.admin .admin-col.item-container::-webkit-scrollbar-thumb {
  background: rgba(69, 74, 92, 0.12) !important;
  border: 5px solid rgba(0, 0, 0, 0) !important;
  background-clip: padding-box !important;
}
.admin .admin-col.item-container .column {
  padding-left: 0;
  padding-right: 0;
}
.admin .admin-col.item-container .content-container {
  background-color: #f7f7f8;
  padding: 24px 24px;
}
.admin .admin-col.item-container .content-container.content-table-container {
  padding: 24px 16px;
}
.admin .admin-col.item-container .content-container.content-embedded-container {
  padding: 0px;
}
.admin .admin-col.item-container .full-title {
  color: #454a5c;
  font-weight: 600;
  font-size: 29px;
  line-height: 46px;
  letter-spacing: 0.7px;
  margin-top: 1px;
  margin-bottom: 1px;
}
.admin .admin-col.item-container .route-title {
  margin-left: 25px;
  margin-top: 45px;
}
.admin .admin-col.sidebar-container {
  background-color: #3c4249;
  float: left;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.08) rgba(0, 0, 0, 0);
  width: 236px;
}
.admin.with-footer .admin-col {
  height: calc(100% - 48px);
  padding-bottom: 0px;
}
.admin.dark-mode {
  background-color: #191b24;
}
.admin.dark-mode .admin-col.item-container {
  scrollbar-color: rgba(86, 93, 114, 0.3) rgba(0, 0, 0, 0);
}
.admin.dark-mode .admin-col.item-container .content-container {
  background-color: #21242f;
}
.admin.dark-mode .admin-col.item-container .full-title {
  color: #ffffff;
}
.admin.dark-mode .admin-col.sidebar-container {
  scrollbar-color: rgba(86, 93, 114, 0.3) rgba(0, 0, 0, 0);
}
