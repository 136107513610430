.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.search-bar {
  display: flex;
  margin-bottom: 24px;
}
.search-bar .search-bar-form {
  margin: 0;
  margin-left: auto;
  padding: 0;
}
.search-bar .search-bar-form .input-group {
  display: flex;
}
.search-bar .search-bar-form .search-input {
  width: 260px;
  height: 40px;
  padding: 8px 48px 8px 16px;
  border: none;
  border-radius: 100px;
  box-shadow: none;
  font-size: 16px;
  line-height: 24px;
  color: #454a5c;
}
.search-bar .search-bar-form .search-input::placeholder {
  color: #454a5c;
}
.search-bar .search-bar-form .search-button {
  cursor: pointer;
  background-color: unset;
  border: unset;
  padding: 11px 0px;
  margin-left: -32px;
  z-index: 10;
}
.search-bar .search-bar-form .search-button img {
  width: 16px;
}
.search-bar .search-bar-form + .search-bar-right-content {
  margin-left: 40px;
}
.search-bar .search-bar-right-content {
  margin-left: auto;
  margin-right: 16px;
  display: flex;
  align-items: center;
}
.search-bar .search-bar-right-content > *:not(:first-child) {
  margin-left: 24px;
}
.search-bar .search-bar-title {
  margin: 0px;
  font-size: 23px;
  line-height: 38px;
  color: #454a5c;
}
.search-bar.dark-mode .search-bar-form .search-input {
  background-color: #191b24;
  color: #ffffff;
}
.search-bar.dark-mode .search-bar-form .search-input::placeholder {
  color: #ffffff;
}
.search-bar.dark-mode .search-bar-title {
  color: #ffffff;
}
