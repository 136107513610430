.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.superadmin-reports-form {
  height: calc(100% - 132px);
}
.superadmin-reports-form .multiple-dropdown .substatus-option {
  padding-left: 36px;
}
