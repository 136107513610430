.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.form-check-box-group ul {
  -webkit-columns: 2;
  -moz-columns: 2;
  columns: 2;
  padding-left: 0px;
}
