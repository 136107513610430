.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.union-trades > div:first-child {
  color: #3c4249;
  font-size: 26px;
  letter-spacing: -0.3px;
  line-height: normal;
}
.union-trades > div:nth-child(2) {
  color: #0a1f43;
  font-size: 16px;
  line-height: normal;
  margin-top: 7px;
}
.union-trades .union-trades-list {
  margin-top: 20px;
}
.union-trades .union-trades-list .trade-detail {
  color: #8e9aae;
  margin-left: 10px;
}
.union-trades .union-trades-list .trade-value {
  color: #1c232a;
}
