.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.coi-exp-docs {
  display: flex;
  flex-direction: column;
}
.coi-exp-docs .coi-exp-docs-table .doc-viewer {
  display: flex;
  align-items: center;
}
.coi-exp-docs .coi-exp-docs-table .doc-viewer .remove-doc-icon {
  margin-left: 8px;
}
.coi-exp-docs .coi-exp-docs-table .td-empty-table {
  height: unset;
}
.coi-exp-docs .document-upload {
  width: 520px;
  margin: 20px 0px;
  align-self: center;
}
.coi-exp-docs.with-cert-holder colgroup > col:nth-child(1) {
  width: 15%;
}
.coi-exp-docs.with-cert-holder colgroup > col:nth-child(2) {
  width: 45%;
}
.coi-exp-docs.with-cert-holder colgroup > col:nth-child(3) {
  width: 10%;
}
.coi-exp-docs.with-cert-holder colgroup > col:nth-child(4) {
  width: 10%;
}
.coi-exp-docs.with-cert-holder colgroup > col:nth-child(5) {
  width: 10%;
}
.coi-exp-docs.with-cert-holder colgroup > col:nth-child(6) {
  width: 10%;
}
