.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.punch-list-search-filter {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.punch-list-search-filter input {
  border-bottom: none;
  min-width: 200px;
}
.punch-list-search-filter .button.button-white {
  min-width: 0;
  padding: 0 10px 0 6px;
  border: none;
}
.punch-list-search-filter .button.button-white span {
  display: none;
}
.dark-mode .punch-list-search-filter {
  background-color: #303441;
}
