.field-report-table .actions {
  display: flex;
  align-items: center;
}
.field-report-table .actions button {
  margin-left: 12px;
}
.field-report-table .actions button:first-child {
  margin-left: 0;
}
