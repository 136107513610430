.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
body {
  font-family: Titillium Web, sans-serif !important;
  overflow: hidden;
}
div[role='dialog']:last-child > div {
  z-index: 1080;
}
.body-bold {
  color: #3c4249;
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
.body-bold-orange {
  color: #fb6120;
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
.body-regular {
  color: #3c4249;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
.bottom-title {
  text-align: center;
  margin-top: 25px;
}
.bottom-title a {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3c4249;
}
.btn-select {
  width: 100%;
  min-width: 80px;
  height: 28px;
  padding: 0px 12px;
  border: 1px solid transparent;
  border-radius: 100px;
  text-align: center;
  font-style: normal;
  font-stretch: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: normal;
  outline: unset !important;
}
.btn-select .button-counter {
  font-weight: normal;
  font-size: 14px;
  opacity: 0.5;
}
.btn-select.rectangle-button {
  border-radius: unset;
  font-weight: 600;
  padding: 0px 32px;
  height: 40px;
}
.btn-select.confirmation-button {
  min-width: 150px;
  height: 40px;
  border-radius: unset;
  font-weight: normal;
  font-size: 18px;
  line-height: 29px;
}
.btn-select.collapsable-button {
  min-width: 60px;
  height: 40px;
  font-weight: normal;
  border-radius: 100px;
  border-width: 0px !important;
  border-color: transparent !important;
  font-size: 22px;
  line-height: 34px;
  background-color: transparent !important;
  box-shadow: none !important;
}
.btn-select.button-transparent {
  color: #00b091;
  border: solid 1px #00b091;
}
.btn-select.button-transparent.dark-mode {
  color: #d4fb00;
  border: solid 1px #d4fb00;
}
.btn-select.button-transparent.dark-mode .button-counter {
  opacity: 0.5;
}
.btn-select.button-blue {
  color: #ffffff;
  background-color: #1279be;
}
.btn-select.button-blue-grey {
  color: #454a5c;
  background-color: #f7f7f8;
}
.btn-select.button-blue-grey.dark-mode {
  color: #ffffff;
  background-color: #21242f;
}
.btn-select.button-gray {
  color: #ffffff;
  background-color: #8e9aae;
}
.btn-select.button-green {
  color: #ffffff;
  background-color: #00b091;
}
.btn-select.button-green .button-counter {
  opacity: 0.7;
}
.btn-select.button-green.dark-mode {
  color: #191b24;
  background-color: #d4fb00;
}
.btn-select.button-green.dark-mode .button-counter {
  opacity: 0.5;
}
.btn-select.button-orange {
  color: #ffffff;
  background-color: #fb6120;
}
.btn-select.button-white {
  color: #00b091;
  background-color: #ffffff;
  border: solid 1px #00b091;
}
.btn-select.button-white.dark-mode {
  color: #d4fb00;
  background-color: #303441;
  border: solid 1px #d4fb00;
}
.btn-select.button-white.dark-mode .button-counter {
  opacity: 0.5;
}
.btn-select.button-white-orange {
  color: #fb6120;
  background-color: #ffffff;
  border: solid 1px #fb6120;
}
.btn-select.btn-gray {
  background-color: #edf1f7;
  color: #3c4249;
}
.btn-select.btn-orange {
  color: #ffffff;
  background-color: #fb6120;
}
.btn-select.btn-none {
  color: #ffffff;
  opacity: 0.7;
}
.button {
  min-width: 80px;
  height: 28px;
  padding: 0px 12px;
  border: 1px solid transparent;
  border-radius: 100px;
  text-align: center;
  font-style: normal;
  font-stretch: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: normal;
  outline: unset !important;
}
.button .button-counter {
  font-weight: normal;
  font-size: 14px;
  opacity: 0.5;
}
.button.rectangle-button {
  border-radius: unset;
  font-weight: 600;
  padding: 0px 32px;
  height: 40px;
}
.button.confirmation-button {
  min-width: 150px;
  height: 40px;
  border-radius: unset;
  font-weight: normal;
  font-size: 18px;
  line-height: 29px;
}
.button.collapsable-button {
  min-width: 60px;
  height: 40px;
  font-weight: normal;
  border-radius: 100px;
  border-width: 0px !important;
  border-color: transparent !important;
  font-size: 22px;
  line-height: 34px;
  background-color: transparent !important;
  box-shadow: none !important;
}
.button.button-transparent {
  color: #00b091;
  border: solid 1px #00b091;
}
.button.button-transparent.dark-mode {
  color: #d4fb00;
  border: solid 1px #d4fb00;
}
.button.button-transparent.dark-mode .button-counter {
  opacity: 0.5;
}
.button.button-blue {
  color: #ffffff;
  background-color: #1279be;
}
.button.button-blue-grey {
  color: #454a5c;
  background-color: #f7f7f8;
}
.button.button-blue-grey.dark-mode {
  color: #ffffff;
  background-color: #21242f;
}
.button.button-gray {
  color: #ffffff;
  background-color: #8e9aae;
}
.button.button-green {
  color: #ffffff;
  background-color: #00b091;
}
.button.button-green .button-counter {
  opacity: 0.7;
}
.button.button-green.dark-mode {
  color: #191b24;
  background-color: #d4fb00;
}
.button.button-green.dark-mode .button-counter {
  opacity: 0.5;
}
.button.button-orange {
  color: #ffffff;
  background-color: #fb6120;
}
.button.button-white {
  color: #00b091;
  background-color: #ffffff;
  border: solid 1px #00b091;
}
.button.button-white.dark-mode {
  color: #d4fb00;
  background-color: #303441;
  border: solid 1px #d4fb00;
}
.button.button-white.dark-mode .button-counter {
  opacity: 0.5;
}
.button.button-white-orange {
  color: #fb6120;
  background-color: #ffffff;
  border: solid 1px #fb6120;
}
.form-control {
  box-shadow: none;
  outline: none;
}
.form-control[readonly],
.form-control:disabled {
  cursor: default;
  background-color: transparent;
}
.form-control:focus {
  box-shadow: none;
  outline: none;
}
.input-area {
  height: 40px;
  padding: 7px 8px 8px 8px;
  border: none;
  border-radius: unset;
  background: none;
  font-size: 16px;
  line-height: 24px;
  color: #454a5c;
  border-bottom: 1px solid #7a7e8b;
}
.input-area::placeholder {
  color: #454a5c;
  opacity: 0.7;
}
.input-area:focus {
  border-color: #00b091;
}
.input-area:focus::placeholder {
  color: #00b091;
  opacity: 1;
}
.input-area.dark-mode {
  color: #ffffff;
  border-color: #bcbdc1;
}
.input-area.dark-mode::placeholder {
  color: #ffffff;
}
.input-area.dark-mode:focus {
  border-color: #d4fb00;
}
.input-area.dark-mode:focus::placeholder {
  color: #d4fb00;
}
.input-area.without-border {
  border: none;
}
.input {
  height: 40px;
  padding: 7px 8px 8px 8px;
  border: none;
  border-radius: unset;
  background: none;
  font-size: 16px;
  line-height: 24px;
  color: #454a5c;
  border-bottom: 1px solid #7a7e8b;
}
.input::placeholder {
  color: #454a5c;
  opacity: 0.7;
}
.input:focus {
  border-color: #00b091;
}
.input:focus::placeholder {
  color: #00b091;
  opacity: 1;
}
.input.dark-mode {
  color: #ffffff;
  border-color: #bcbdc1;
}
.input.dark-mode::placeholder {
  color: #ffffff;
}
.input.dark-mode:focus {
  border-color: #d4fb00;
}
.input.dark-mode:focus::placeholder {
  color: #d4fb00;
}
.input.without-border {
  border: none;
}
.input.short-input {
  max-width: 170px;
}
.input::placeholder {
  color: #454a5c !important;
  opacity: 0.7 !important;
}
.input.dark-mode::placeholder {
  color: #ffffff !important;
}
.invalid-message {
  color: #fb6120;
  font-size: 15px;
  line-height: normal;
  margin-top: 10px;
}
.h1 {
  color: #454a5c;
  display: inline-block;
  font-size: 29px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 600;
  letter-spacing: 0.7px;
  line-height: 46px;
  margin: 0;
}
.h2 {
  color: #3c4249;
  display: inline-block;
  font-size: 24px;
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  margin: 0;
}
.h3 {
  color: #3c4249;
  display: inline-block;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 0;
}
.h4 {
  color: #3c4249;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 0;
}
h6 {
  color: #3c4249;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: normal;
}
.label {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
.small-bold {
  color: #454a5c;
  opacity: 0.7;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
.small-regular {
  color: #454a5c;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.subtitle {
  text-align: center;
  margin-top: 7px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8e9aae;
}
.title {
  text-align: center;
}
.title span {
  font-size: 34px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3c4249;
}
.action-btns-wrapper {
  display: flex;
  justify-content: space-between;
}
.linkable {
  text-decoration: underline;
  white-space: nowrap;
}
.linkable:hover {
  cursor: pointer;
}
.form-block {
  display: flex;
  margin-bottom: 16px;
}
.form-block .form-block-label {
  color: #454a5c;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 0px;
  margin-right: 24px;
  max-width: 120px;
  min-width: 120px;
}
.form-block .form-block-text {
  color: #454a5c;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  min-height: 54px;
  padding-top: 23px;
}
.form-block .form-block-title {
  color: #454a5c;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  min-height: 54px;
  margin-right: 24px;
  padding-top: 21px;
  font-weight: 600;
  font-size: 18px;
}
.form-block .dropdown,
.form-block .multiple-dropdown,
.form-block .multiple-select-list {
  width: 100%;
}
.form-block .labeled-checkbox {
  width: 100%;
  min-height: 54px;
  padding-top: 16px;
}
.form-block .labeled-checkbox:not(:last-child) {
  margin-right: 24px;
}
.form-block .form-control-block {
  width: 100%;
}
.form-block .form-control-block:not(:last-child) {
  margin-right: 24px;
}
.form-block .form-control-block.small-control {
  width: 170px;
}
.form-block .form-control-block.medium-control {
  width: 200px;
}
.form-block .form-control-block.big-control {
  width: 364px;
}
.form-block .form-control-block.double-big-control {
  width: 744px;
}
.form-block .form-control-block.unset-size-control {
  width: unset;
}
.form-block .form-control-block .labeled-checkbox {
  min-height: 40px;
  padding-top: 1px;
}
.form-block .form-control-block .expand-textarea-button {
  top: -16px;
}
.form-block .button,
.form-block .link-button {
  margin-left: auto;
  margin-top: 16px;
}
.form-block .button:not(:first-child),
.form-block .link-button:not(:first-child) {
  margin-left: 16px;
}
.form-block .button.form-control-button,
.form-block .link-button.form-control-button {
  margin-left: 0px;
  margin-top: 24px;
  height: 18px;
}
.form-block .form-items-list {
  margin: 0px;
  padding: 8px 8px 0px 8px;
}
.form-block .form-items-list .form-items-list-item {
  display: flex;
  align-items: center;
}
.form-block .form-items-list .form-items-list-item .link-button {
  margin-top: 0px;
}
.form-block > *:not(:first-child):not(:last-child) {
  margin-right: 24px;
}
.form-block.multicontrols-block > *:not(.form-block-label) {
  width: auto;
}
.form-block.right-block {
  justify-content: flex-end;
}
.form-block.right-block .form-block-title:first-child {
  margin-right: auto;
}
.form-block .form-control-logo-uploader .logo-upload-button-container {
  margin-top: 20px;
  border: none;
  cursor: pointer;
}
.form-block .form-control-logo-uploader .logo-upload-button-container .files-dropzone {
  display: flex;
  align-items: center;
}
.form-block .form-control-logo-uploader .logo-upload-button-container .files-dropzone img {
  width: 24px;
  height: 24px;
}
.form-block .form-control-logo-uploader .logo-upload-button-container .files-dropzone .add-message {
  margin: 0px 0px 0px 8px;
}
.form-resources-block {
  display: flex;
  width: 100%;
}
.form-resources-block > * {
  width: 50%;
}
.form-resources-block > * .form-resources-body {
  padding: 16px 16px 0px 8px;
}
.form-resources-block .form-documents .document-link {
  margin-bottom: 8px;
}
.form-resources-block .form-documents .document-link .document-link-remove {
  margin-left: auto;
}
.form-resources-block .form-photos {
  align-self: flex-end;
}
.email-link {
  color: #00b091 !important;
}
.external-link {
  color: #00b091 !important;
}
hr {
  border-color: #9ea0aa;
}
.error-message {
  color: #fb6120;
  font-size: 16px;
  margin-bottom: 20px;
  text-align: right;
}
::-webkit-scrollbar {
  width: 16px;
}
::-webkit-scrollbar-thumb {
  background: rgba(69, 74, 92, 0.08) !important;
  border: 5px solid rgba(0, 0, 0, 0) !important;
  background-clip: padding-box !important;
}
::-webkit-scrollbar-corner {
  background: transparent;
  width: 0;
  height: 0;
}
.dark-mode .h1,
.dark-mode .small-bold,
.dark-mode .small-regular,
.dark-mode .small-disclaimer,
.dark-mode .form-block-label,
.dark-mode .form-block-text,
.dark-mode .form-block-title {
  color: #ffffff;
}
.dark-mode .invalid-message,
.dark-mode .error-message {
  color: #02c7ff;
}
.dark-mode .email-link,
.dark-mode .external-link {
  color: #d4fb00 !important;
}
.dark-mode hr {
  border-color: rgba(255, 255, 255, 0.05);
}
.dark-mode ::-webkit-scrollbar-thumb {
  background: rgba(86, 93, 114, 0.3) !important;
  border: 5px solid rgba(0, 0, 0, 0) !important;
  background-clip: padding-box !important;
}
.MuiTypography-caption {
  line-height: 1.4 !important;
}
