.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.schedule-modal .modal-dialog {
  width: 600px;
}
.schedule-modal .modal-dialog .modal-content {
  background-color: #f7f7f8;
  width: 600px;
}
.schedule-modal .modal-dialog .modal-content .modal-container .modal-body {
  overflow: visible;
  position: static;
  padding: 0 40px;
  padding-bottom: 90px;
}
.schedule-modal .modal-dialog .modal-content .modal-container .modal-body .general-info {
  padding: 24px 0;
}
.schedule-modal .modal-dialog .modal-content .modal-container .modal-body .general-info .form-control-label {
  font-size: 14px;
  margin-bottom: -2px;
}
.schedule-modal .modal-dialog .modal-content .modal-container .modal-body .general-info .details-info-item {
  padding: 0 8px;
}
.schedule-modal .modal-dialog .modal-content .modal-container .modal-body .general-info .input-type {
  margin-top: 20px;
}
.schedule-modal .modal-dialog .modal-content .modal-container .modal-body .general-info .duplicate-input {
  margin: 24px 0 28px;
}
.schedule-modal .modal-dialog .modal-content .modal-container .modal-body .user-permissions-form {
  margin-top: 20px;
}
.schedule-modal .modal-dialog .modal-content .modal-container .modal-body .button-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: -1px;
  left: 0;
  height: 90px;
  background-color: #f7f7f8;
}
.schedule-modal .modal-dialog .modal-content .modal-container .modal-body .button-actions .button:first-child {
  margin-right: 16px;
}
.schedule-modal .modal-dialog .modal-content .modal-container .modal-body .button-actions .button.button-white {
  background-color: transparent;
}
.schedule-modal.shared .modal-dialog .modal-content .modal-container .modal-body {
  height: calc(80vh - 90px);
  max-height: 100%;
  overflow: auto;
  overflow: overlay;
}
.dark-mode .schedule-modal .modal-dialog .modal-content .modal-container .modal-body .button-actions {
  background-color: #303441;
}
