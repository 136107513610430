.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.project-email-modal .modal-dialog {
  width: 786px;
}
.project-email-modal .modal-dialog .project-email-container {
  padding: 24px 40px 40px 40px;
}
.project-email-modal .modal-dialog .project-email-container .form-block {
  flex-direction: column;
}
.project-email-modal .modal-dialog .project-email-container .form-block .form-block-label {
  font-size: 12px;
  color: #7a7e8b;
}
.project-email-modal .modal-dialog .project-email-container .form-block .multiple-dropdown .dropdown-control,
.project-email-modal .modal-dialog .project-email-container .form-block .input,
.project-email-modal .modal-dialog .project-email-container .form-block .input-area {
  padding-left: 0;
  padding-right: 0;
}
.project-email-modal .modal-dialog .project-email-container .form-block:nth-child(2) {
  margin-bottom: 0;
}
.project-email-modal .modal-dialog .project-email-container .form-block:nth-child(2) > button {
  margin-top: 0;
}
.project-email-modal .modal-dialog .project-email-container .form-block:nth-child(3) {
  margin-bottom: 32px;
}
.dark-mode .project-email-modal .modal-dialog .project-email-container .form-block .form-block-label {
  font-size: 12px;
  color: #bcbdc1;
}
