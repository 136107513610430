.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.move-folder-modal .panel {
  margin: 24px 40px;
  border: none;
  box-shadow: none;
  background: unset;
}
.move-folder-modal .panel-body {
  padding: 0px;
}
.move-folder-modal .small-bold {
  margin-right: 8px;
}
.move-folder-modal td {
  cursor: pointer;
}
.move-folder-modal tr:hover {
  background-color: #f7f7f8;
}
.move-folder-modal tr.selected a {
  color: #00b091 !important;
}
.move-folder-modal .buttons-container {
  margin-bottom: 50px;
}
.move-folder-modal.dark-mode tr:hover {
  background-color: #363a46;
}
.move-folder-modal.dark-mode tr.selected a {
  color: #d4fb00 !important;
}
