.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.project-dashboard-construction-complete {
  display: flex;
  flex-direction: column;
}
.project-dashboard-construction-complete .construction-complete-title {
  margin-top: 12px;
  margin-left: 32px;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #454a5c;
}
.project-dashboard-construction-complete .contruction-complete-chart {
  width: 100%;
  height: calc(100% - 42px);
}
.project-dashboard-construction-complete.dark-mode .construction-complete-title {
  color: #ffffff;
}
@media (max-height: 1000px), (max-width: 1600px) {
  .project-dashboard-construction-complete .construction-complete-title {
    margin-top: 8px;
    margin-left: 16px;
  }
}
@media (max-height: 800px), (max-width: 1300px) {
  .project-dashboard-construction-complete .construction-complete-title {
    font-size: 14px;
    line-height: 24px;
  }
  .project-dashboard-construction-complete .contruction-complete-chart {
    height: calc(100% - 26px);
  }
}
