.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.create-folder-modal .input {
  width: 400px;
  margin: 20px 40px 0px 40px;
}
.create-folder-modal .buttons-container {
  margin-bottom: 50px;
}
.checkbox-divide {
  width: 36px;
}
.container-permissions-checkbox {
  display: flex;
  flex-direction: row;
  margin: 15px 40px 0px 40px;
}
.title-permissions {
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
  color: #454a5c;
  padding: 36px 40px 0px 40px;
}
.dark-mode .title-permissions {
  color: #ffffff;
}
.container-permissions-checkbox .labeled-checkbox .checkbox-label {
  margin-bottom: 0;
}
