.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.details-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 40px;
}
.details-title .details-title-text {
  margin: 0px;
  font-size: 23px;
  line-height: 38px;
  color: #454a5c;
}
.details-title .details-title-text .details-title-total {
  margin-left: 10px;
  font-weight: bold;
  font-size: 14px;
}
.details-title .details-title-right-buttons {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.details-title .details-title-right-buttons > *:not(:last-child) {
  margin-right: 24px;
}
.details-title.invalid .details-title-text {
  color: #fb6120;
}
.details-title.main-form-title {
  padding: 0px;
  margin: 8px 0px 58px 0px;
}
.details-title.main-form-title .details-title-text {
  font-weight: 600;
  font-size: 29px;
  line-height: 46px;
}
.details-title.modal-form-title {
  padding: 0px;
  margin-bottom: 16px;
}
.details-title.modal-form-title:not(:first-child) {
  margin-top: 40px;
}
.details-title.modal-form-title .details-title-text {
  font-weight: 600;
  font-size: 29px;
  line-height: 46px;
}
.details-title.modal-form-title .details-title-text p {
  margin: 0px;
}
.details-title.modal-form-title .details-title-text .modal-form-title-subtitle {
  font-size: 24px;
}
.details-title.modal-form-subtitle {
  margin-top: 24px;
  margin-bottom: 16px;
  padding: 0px;
}
.details-title.modal-form-subtitle .details-title-text {
  font-weight: 600;
  line-height: normal;
  font-size: 18px;
}
.details-title.dark-mode .details-title-text {
  color: #ffffff;
}
.details-title.dark-mode.invalid .details-title-text {
  color: #02c7ff;
}
