.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.client.user-invitations {
  padding: 0 !important;
}
.client.user-invitations .title-1 {
  font-size: 48px;
  font-weight: 600;
  color: #ffffff;
}
.client.user-invitations .subtitle {
  color: #ffffff;
  text-align: left;
  font-size: 15px;
}
.client.user-invitations .buttons {
  margin-top: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.client.user-invitations .link-later {
  color: #d4fb00 !important;
}
.client.user-invitations .logo-invite {
  margin-bottom: 5px;
}
.client.user-invitations .btn-invite {
  font-size: 20px;
  background-color: #d4fb00;
  color: #333333;
  opacity: 0.8;
}
.client.user-invitations .btn-invite:hover {
  opacity: 1;
  background-color: #d4fb00;
}
.client.user-invitations .btn .btn-select:hover {
  background-color: #d4fb00;
}
@media (max-width: 991px) {
  .client.user-invitations .buttons {
    margin: 30px auto 40px;
  }
  .client.user-invitations .right-panel {
    display: none;
  }
  .client.user-invitations .left-panel {
    border: none;
  }
  .client.user-invitations .mobile-container-button-invite {
    display: flex;
    justify-content: center;
  }
}
