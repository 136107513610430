.small-disclaimer {
  color: #454a5c;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
}
.button > span {
  margin-left: 8px;
  vertical-align: middle;
}
.button > span.icon-last {
  margin-left: 0px;
}
.button > img.icon-last {
  margin-left: 8px;
}
.button .button-counter {
  margin-left: 4px;
  top: -1px;
}
.link-button {
  display: flex;
  align-items: center;
  padding: 0px;
  background: none;
  border: none;
  outline: unset;
  font-size: 16px;
  line-height: 24px;
  color: #454a5c;
}
.link-button.button-green {
  color: #00b091;
}
.link-button img {
  width: 24px;
  height: 24px;
}
.link-button img.small-button-icon {
  width: 16px;
  height: 16px;
}
.link-button span {
  margin-left: 8px;
  vertical-align: middle;
}
.link-button:disabled {
  opacity: 0.7;
}
.link-button.dark-mode {
  color: #ffffff;
}
.link-button.dark-mode.button-green {
  color: #d4fb00;
}
a.button:hover,
a.link-button:hover,
a.button:focus,
a.link-button:focus {
  text-decoration: none;
}
